import { inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { MessageService } from '@uis-services/message/message.service';
import { AuthService } from '@uis-services/auth/auth.service';
import { ExternalValidationError } from '@uis-models/contract/external-validation-error';
import { UisRegExpRegistry } from '@uis-core/constants/uis-reg-exp';

export const errorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
) => {
  const message = inject(MessageService);
  const auth = inject(AuthService);
  const unknownErrorMessage =
    'Йолі-квасолі! Щось із сервером... Не хвилюйся, очікуй. Ми вже ремонтуємо то залізяччя';

  function Handle400Response(
    err: HttpErrorResponse,
  ): Observable<HttpEvent<any>> {
    if (
      err.error &&
      err.error['error_description'] === 'invalid_username_or_password'
    ) {
      message.error('Неправильний email або пароль');
    } else {
      return HandleUnknownResponse(err);
    }
    return throwError(() => err);
  }

  function Handle401Response(
    req: HttpRequest<any>,
  ): Observable<HttpEvent<any>> {
    return auth.refreshAccessToken.pipe(
      catchError((err) =>
        auth.logout().pipe(switchMap(() => throwError(() => err))),
      ),
      switchMap(() => HandleError(req)),
    );
  }

  function Handle403Response(
    err: HttpErrorResponse,
  ): Observable<HttpEvent<any>> {
    message.error('Помилка доступу', 'У вас немає прав доступу для цієї дії');
    return throwError(() => err);
  }

  function Handle422Response(
    err: HttpErrorResponse,
  ): Observable<HttpEvent<any>> {
    const externalValidationError = new ExternalValidationError(err.error);
    const cyrillicFieldsMessages = Object.values(externalValidationError.fields)
      .flat()
      .filter((message) => UisRegExpRegistry.ContainsCyrillic.test(message));
    if (externalValidationError.general.length) {
      externalValidationError.general.forEach((externalMessage) => {
        message.error(externalMessage);
      });
    } else if (cyrillicFieldsMessages.length) {
      cyrillicFieldsMessages.forEach((fieldMessage) =>
        message.error(`Помилка валідації`, fieldMessage),
      );
    } else {
      message.error(`Помилка валідації`);
    }
    return throwError(() => err);
  }

  function HandleUnknownResponse(
    err: HttpErrorResponse,
  ): Observable<HttpEvent<any>> {
    const possibleErrorText =
      err?.error?.general?.[0] ?? err.error ?? err.message;
    message.error(
      `Помилка ${err.status}`,
      typeof possibleErrorText === 'string'
        ? possibleErrorText
        : unknownErrorMessage,
    );
    return throwError(() => err);
  }

  function HandleError(req: HttpRequest<any>) {
    return next(req).pipe(
      catchError((err) => {
        switch (err.status) {
          case 400:
            return Handle400Response(err);
          case 401:
            return Handle401Response(req);
          case 403:
            return Handle403Response(err);
          case 422:
            return Handle422Response(err);
          default:
            return HandleUnknownResponse(err);
        }
      }),
    );
  }

  return HandleError(req);
};
