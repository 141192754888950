import { CanMatchFn, Router } from '@angular/router';
import { RoleDataService } from '@uis-services/role-data/role-data.service';
import { inject } from '@angular/core';
import { Permission } from '@uis-services/role-data/role-data.types';

export const permissionsCanMatchGuard: (
  permissions: Permission | Permission[] | readonly Permission[],
  logic?: 'or' | 'and',
  failRedirect?: string | (() => string | null | undefined),
) => CanMatchFn =
  (
    permissions: Permission | Permission[] | readonly Permission[],
    logic: 'or' | 'and' = 'and',
    failRedirect?: string | (() => string | null | undefined),
  ) =>
  () => {
    const permissionsService = inject(RoleDataService);
    const router = inject(Router);
    const failRedirectValue =
      typeof failRedirect === 'function' ? failRedirect() : failRedirect;
    let res = false;
    if (logic === 'and') {
      res = permissionsService.hasPermissions(permissions);
    } else {
      const normalizedPermissions = Array.isArray(permissions)
        ? permissions
        : [permissions];
      res = normalizedPermissions.some((permission) =>
        permissionsService.hasPermissions(permission),
      );
    }

    if (!failRedirectValue) {
      return res;
    }

    return res ? res : router.createUrlTree([failRedirectValue]);
  };
