import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { KendoDataQuery } from '@uis-core/classes/kendo-data-query';
import { MovementEp } from '@uis-enums/endpoints';
import {
  AttachRequest,
  DetachRequest,
  MovedStudent,
  TransferRequest,
} from '@uis-private/uis-classes/dialogs/movements.models';
import { SearchResult } from '@uis-models/contract/search';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MovementService {
  private readonly http = inject(HttpClient);

  getTransfers(
    kendoQuery: KendoDataQuery = new KendoDataQuery(0, 0),
  ): Observable<SearchResult<MovedStudent>> {
    return this.http.get<SearchResult<MovedStudent>>(MovementEp.All(), {
      params: new HttpParams({ fromObject: kendoQuery.query }),
    });
  }

  createTransfers(payload: TransferRequest) {
    return this.http.post(MovementEp.transfer(), payload).pipe();
  }

  detachStudents(payload: DetachRequest) {
    return this.http.post(MovementEp.detach(), payload).pipe();
  }

  attachStudents(payload: AttachRequest) {
    return this.http.post(MovementEp.attach(), payload).pipe();
  }
}
