import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
  Provider,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  HttpInterceptorFn,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { errorInterceptor } from '@uis-core/interceptors/error.interceptor';
import { mutationInterceptor } from '@uis-core/interceptors/mutation-interceptor/mutation.interceptor';
import { globalLoadingInterceptor } from '@uis-core/interceptors/global-loader-interceptor.service';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { OnlineStatusModule } from 'ngx-online-status';
import { provideUisAppInitializer } from './app.init';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import {
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatNativeDateModule,
} from '@angular/material/core';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { environment } from '../environments/environment';

const INTERCEPTORS: HttpInterceptorFn[] = [
  globalLoadingInterceptor,
  errorInterceptor,
  mutationInterceptor,
];

const MATERIAL_CONFIG: Provider[] = [
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'outline',
      floatLabel: 'auto',
      subscriptSizing: 'dynamic',
    } as MatFormFieldDefaultOptions,
  },
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: {
      position: 'above',
      disableTooltipInteractivity: true,
    } as MatTooltipDefaultOptions,
  },
  { provide: MAT_CARD_CONFIG, useValue: { appearance: 'raised' } },
  { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
  { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
  { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
  { provide: MAT_DATE_LOCALE, useValue: 'uk' },
  {
    provide: MatPaginatorIntl,
    useFactory: () => {
      const intl = new MatPaginatorIntl();
      intl.getRangeLabel = (page, pageSize, length) => {
        if (length === 0 || pageSize === 0) {
          return `0 з ` + length;
        }

        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
          startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ` з ` + length;
      };
      intl.itemsPerPageLabel = 'Результатів на сторінці:';
      return intl;
    },
  },
];
export const APP_LOCALE = 'uk-UA';
export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: APP_LOCALE },
    provideHttpClient(withInterceptors(INTERCEPTORS), withInterceptorsFromDi()),
    provideOAuthClient({
      resourceServer: {
        sendAccessToken: true,
        allowedUrls: [environment.apiUrl],
      },
    }),
    MATERIAL_CONFIG,
    provideUisAppInitializer(),
    provideRouter(
      APP_ROUTES,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),
    provideAnimations(),
    provideEnvironmentNgxMask(),
    importProvidersFrom(
      MatDialogModule,
      MatSnackBarModule,
      MatNativeDateModule,
      OnlineStatusModule,
    ),
  ],
};
