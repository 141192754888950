import {
  PermissionTarget,
  PermissionTargetSpecifier,
  PermissionType,
  UisTrait,
} from '@uis-core/enums/permissions';
import { Signal } from '@angular/core';

export class RoleData {
  permissions: Set<Permission>;
  traits: Set<UisTrait>;

  constructor(roleData?: RoleData) {
    this.permissions = roleData?.permissions ?? new Set<Permission>();
    this.traits = roleData?.traits ?? new Set<UisTrait>();
  }
}

export type UisTraitChecker = {
  [key in keyof typeof UisTrait]: Signal<boolean>;
};

export type Permission =
  | `${PermissionType}:${PermissionTarget}`
  | `${PermissionType}:${PermissionTarget}:${PermissionTargetSpecifier}`;

export type PermissionChecker = {
  [key in keyof typeof PermissionType]: {
    [key in keyof typeof PermissionTarget]: {
      [key in keyof typeof PermissionTargetSpecifier]: Signal<boolean>;
    };
  };
};

export type PermissionsStructure = {
  [key in keyof typeof PermissionType]: {
    [key in keyof typeof PermissionTarget]: {
      [key in keyof typeof PermissionTargetSpecifier]: Permission;
    };
  };
};

export type PermissionObject = {
  type: PermissionType;
  target: PermissionTarget;
  specifier?: PermissionTargetSpecifier;
};
