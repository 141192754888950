import { visitorOnlyCanActivateGuard } from '@uis-core/guards/visitor-only-can-activate.guard';
import { DefinedRoutes } from '@uis-types/defined-routes';
import { requiredQueryParamsCanActivateGuard } from '@uis-core/guards/required-query-params-can-activate.guard';
import {
  ChangePasswordQueryParams,
  ConfirmQueryParams,
  SignUpSuccessQueryParams,
} from '@uis-enums/query-params';
import { NOT_FOUND_ROUTE } from '../not-found/not-found.routes';

export enum PublicRoute {
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  ForgotPassword = 'forgot-password',
  ChangePassword = 'change-password',
  SetPassword = 'set-password',
  SignUpSuccess = 'sign-up-success',
  PrivacyPolicy = 'privacy',
  TermsAndConditions = 'terms',
  Confirm = 'confirmation',
}

export const PUBLIC_ROUTES: DefinedRoutes = [
  {
    path: '',
    loadComponent: () =>
      import('@uis-public/public-root/public-root.component').then(
        (c) => c.PublicRootComponent,
      ),
    children: [
      {
        path: PublicRoute.Confirm,
        title: 'Підтведження',
        loadComponent: () =>
          import('@uis-public/confirm/confirmation-redirect.component').then(
            (c) => c.ConfirmationRedirectComponent,
          ),
        canActivate: [
          requiredQueryParamsCanActivateGuard([
            ConfirmQueryParams.ConfirmationType,
            ConfirmQueryParams.Token,
            ConfirmQueryParams.Email,
          ]),
        ],
      },
      {
        path: PublicRoute.SignIn,
        title: 'Вхід',
        loadComponent: () =>
          import('@uis-public/auth/sign-in/sign-in.component').then(
            (c) => c.SignInComponent,
          ),
        canActivate: [visitorOnlyCanActivateGuard],
      },
      {
        path: PublicRoute.SignUp,
        title: 'Реєстрація',
        loadComponent: () =>
          import('@uis-public/auth/sign-up/sign-up.component').then(
            (c) => c.SignUpComponent,
          ),
        canActivate: [visitorOnlyCanActivateGuard],
      },
      {
        path: PublicRoute.ForgotPassword,
        title: 'Забув пароль',
        loadComponent: () =>
          import(
            '@uis-public/auth/forgot-password/forgot-password.component'
          ).then((c) => c.ForgotPasswordComponent),
        canActivate: [visitorOnlyCanActivateGuard],
      },
      {
        path: PublicRoute.ChangePassword,
        title: 'Зміна паролю',
        loadComponent: () =>
          import(
            '@uis-public/auth/change-password/change-password.component'
          ).then((c) => c.ChangePasswordComponent),
        canActivate: [
          visitorOnlyCanActivateGuard,
          requiredQueryParamsCanActivateGuard([
            ChangePasswordQueryParams.Email,
            ChangePasswordQueryParams.Token,
          ]),
        ],
      },
      {
        path: PublicRoute.SetPassword,
        title: 'Зміна паролю',
        loadComponent: () =>
          import('@uis-public/auth/set-password/set-password.component').then(
            (c) => c.SetPasswordComponent,
          ),
        canActivate: [
          visitorOnlyCanActivateGuard,
          requiredQueryParamsCanActivateGuard([
            ChangePasswordQueryParams.Email,
            ChangePasswordQueryParams.Token,
          ]),
        ],
      },
      {
        path: PublicRoute.SignUpSuccess,
        title: 'Реєстрація успішна!',
        loadComponent: () =>
          import(
            '@uis-public/auth/sign-up-sucess/sign-up-success.component'
          ).then((c) => c.SignUpSuccessComponent),
        canActivate: [
          visitorOnlyCanActivateGuard,
          requiredQueryParamsCanActivateGuard([SignUpSuccessQueryParams.Email]),
        ],
      },
      {
        path: PublicRoute.PrivacyPolicy,
        title: 'Політика конфіденційності',
        loadComponent: () =>
          import('@uis-public/privacy/privacy.component').then(
            (c) => c.PrivacyComponent,
          ),
      },
      {
        path: PublicRoute.TermsAndConditions,
        title: 'Умови користування платформою',
        loadComponent: () =>
          import('@uis-public/terms/terms.component').then(
            (c) => c.TermsComponent,
          ),
      },
      NOT_FOUND_ROUTE,
    ],
  },
];
