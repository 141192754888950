@if (link) {
  <a
    [routerLink]="link"
    target="{{ newTab ? '_blank' : '_self' }}"
    [class.disabled-link]="!!disabled"
  >
    <ng-container *ngTemplateOutlet="simpleButton"></ng-container>
  </a>
} @else {
  <ng-container *ngTemplateOutlet="simpleButton"></ng-container>
}

<ng-template #simpleButton>
  <button
    class="size_{{ buttonStyle?.size ?? size }}
    fill_{{ buttonStyle?.fill ?? buttonFill }}
    color_{{ buttonStyle?.color ?? buttonColor }}"
    [disabled]="!!disabled"
    type="{{ type }}"
    [matMenuTriggerFor]="menu && !disabled ? menuComp : null"
  >
    <span class="content">
      <ng-content></ng-content>
    </span>
  </button>
  <mat-menu #menuComp="matMenu">
    @for (menuItem of menu; track menuItem) {
      @if (menuItem.value) {
        <button
          mat-menu-item
          type="button"
          (click)="onMenuItemClick(menuItem)"
          [disabled]="!!menuItem.disabled"
        >
          @if (menuItem.matIcon) {
            <mat-icon>{{ menuItem.matIcon }}</mat-icon>
          }
          {{ menuItem.label }}
        </button>
      }
      @if (menuItem.link) {
        <a
          [routerLink]="menuItem.link"
          mat-menu-item
          (click)="onMenuItemClick(menuItem)"
          [disabled]="!!menuItem.disabled"
          [class.disabled-link]="!!menuItem.disabled"
        >
          @if (menuItem.matIcon) {
            <mat-icon>{{ menuItem.matIcon }}</mat-icon>
          }
          {{ menuItem.label }}</a
        >
      }
    }
  </mat-menu>
</ng-template>
