<mat-dialog-content class="dialog__content">
  @if (data.title) {
    <h3 class="dialog__title">{{ data.title }}</h3>
  }
  @if (data.text) {
    <p class="dialog__text">{{ data.text }}</p>
  }
  @if (data.component) {
    <div class="dialog__component-outlet-wrapper">
      <ng-container *ngComponentOutlet="data.component"></ng-container>
    </div>
  }
</mat-dialog-content>
@if (!data.hideDefaultActions) {
  <mat-dialog-actions
    class="dialog__actions"
    [class.dialog__actions_reverse]="data.primaryButton === 'reject'"
  >
    @if (data.rejectText) {
      <uis-button
        [matDialogClose]="false"
        [id]="
          data.primaryButton === 'reject' ? 'primaryButton' : 'secondaryButton'
        "
        [buttonStyle]="
          data.rejectButtonStyle ??
          (data.primaryButton === 'reject'
            ? primaryButtonDefaultStyle
            : secondaryButtonDefaultStyle)
        "
        type="button"
        #rejectButton
        >{{ data.rejectText }}
      </uis-button>
    }
    <uis-button
      [matDialogClose]="true"
      [id]="
        data.primaryButton === 'confirm' ? 'primaryButton' : 'secondaryButton'
      "
      [buttonStyle]="
        data.confirmButtonStyle ??
        (data.primaryButton === 'confirm'
          ? primaryButtonDefaultStyle
          : secondaryButtonDefaultStyle)
      "
      type="button"
      #confirmButton
      >{{ data.confirmText ?? 'Зрозуміло' }}
    </uis-button>
  </mat-dialog-actions>
}
