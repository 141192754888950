const ALL_NON_SPECIAL_CHARACTERS = '\\wА-ЯЇІЄҐа-яїієґ';
const ALL_SPECIAL_CHARACTERS = '[^\\wА-ЯЇІЄҐа-яїієґ]';

export enum UisRegExp {
  Email = 'Email',
  URL = 'URL',
  ContainsLatinUppercase = 'ContainsLatinUppercase',
  ContainsLatinLowercase = 'ContainsLatinLowercase',
  ContainsSpecial = 'ContainsSpecial',
  ContainsNumber = 'ContainsNumber',
  NotContainsCyrillic = 'NotContainsCyrillic',
  NotContainsLatin = 'NotContainsLatin',
  NotContainsWhitespace = 'NotContainsWhitespace',
  PersonNamePart = 'PersonNamePart',
  ContainsCyrillic = 'ContainsCyrillic',
  CanContainSpecialCharactersForPersonName = 'CanContainSpecialCharactersForPersonName',
  SpecialSymbolsShouldNotRepeat = 'SpecialSymbolsShouldNotRepeat',
  NotContainsDigits = 'NotContainsDigits',
  ContainsOnlyDigits = 'ContainsOnlyDigits',
  isTrimmed = 'isTrimmed',
  SkypeUsername = 'SkypeUsername',
}

export const UisRegExpRegistry: { [key in UisRegExp]: RegExp } = {
  [UisRegExp.Email]:
    /^([a-zA-Z0-9]{0})([a-zA-Z0-9._-]+)@([a-zA-Z0-9]+((\.|-)[^\..])?[a-zA-Z0-9]*)(\.[^\..]([a-zA-Z]{1,10}))+$/,
  // [UisRegExp.URL]:
  //   /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,24}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g,
  [UisRegExp.URL]:
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
  [UisRegExp.ContainsLatinUppercase]: /(?=\S*?[A-Z])/,
  [UisRegExp.ContainsLatinLowercase]: /(?=\S*?[a-z])/,
  [UisRegExp.ContainsCyrillic]: /(?=\S*?[А-ЯЇІЄҐа-яїієґ])/,
  [UisRegExp.ContainsNumber]: /(?=\S*?[0-9])/,
  [UisRegExp.ContainsSpecial]: /(?=\S*?[!@#=?$%^&*--_])/,
  [UisRegExp.NotContainsCyrillic]: /^[^А-ЯЇІЄҐа-яїієґ]*?$/,
  [UisRegExp.NotContainsLatin]: /^[^A-Za-z]*?$/,
  [UisRegExp.NotContainsWhitespace]: /^\S*?$/,
  [UisRegExp.isTrimmed]: /^\S+(\s+\S+)*$/,
  [UisRegExp.NotContainsDigits]: /^\D*?$/,
  [UisRegExp.ContainsOnlyDigits]: /^\d*?$/,
  [UisRegExp.PersonNamePart]: /^\S*?$/,
  [UisRegExp.CanContainSpecialCharactersForPersonName]: new RegExp(
    `^[${ALL_NON_SPECIAL_CHARACTERS}\\s\\-\'"’”ʼ]+$`,
  ),
  [UisRegExp.SpecialSymbolsShouldNotRepeat]: new RegExp(
    `^(?!.*(${ALL_SPECIAL_CHARACTERS})\\1+).+$`,
  ),
  [UisRegExp.SkypeUsername]: /^[a-z][a-z0-9\.,\-_:]{5,31}$/i,
} as const;

export const UisRegExpErrorTexts: { [key in UisRegExp]: string } = {
  [UisRegExp.Email]: 'Email введено неправильно',
  [UisRegExp.URL]:
    'Має бути справжнє посилання. Перевірте формат: http(s)://example.com/page',
  [UisRegExp.ContainsLatinUppercase]:
    'Maє містити хоча б одну велику латинську літеру',
  [UisRegExp.ContainsLatinLowercase]:
    'Maє містити хоча б одну маленьку латинську літеру',
  [UisRegExp.ContainsNumber]: 'Maє містити хоча б одну цифру',
  [UisRegExp.ContainsSpecial]: 'Maє містити хоча б один спеціальний символ',
  [UisRegExp.ContainsCyrillic]: 'Mає містити кирилицю',
  [UisRegExp.NotContainsCyrillic]: 'Не має містити кирилицю',
  [UisRegExp.NotContainsLatin]: 'Не має містити латиницю',
  [UisRegExp.NotContainsDigits]: 'Не має містити цифри',
  [UisRegExp.ContainsOnlyDigits]: 'Має містити лише цифри',
  [UisRegExp.NotContainsWhitespace]: 'Не має містити пробіли',
  [UisRegExp.isTrimmed]: 'Не має містити пробіли на початку і на кінці',
  [UisRegExp.PersonNamePart]: 'Може містити лише кирилицю,',
  [UisRegExp.SkypeUsername]: `Має бути дійсним ім'ям користувача Skype`,
  [UisRegExp.CanContainSpecialCharactersForPersonName]:
    'Може містити лише апостроф, дефіс або пробіл',
  [UisRegExp.SpecialSymbolsShouldNotRepeat]:
    'Спеціальні символи і пробіли не мають повторюватись',
};
