import { inject, Injectable } from '@angular/core';
import {
  AuthConfig,
  OAuthEvent,
  OAuthService,
  UserInfo,
} from 'angular-oauth2-oidc';
import {
  defer,
  delay,
  distinctUntilChanged,
  from,
  map,
  Observable,
  share,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginRequest } from '@uis-models/contract/login-request';
import { toSignal } from '@angular/core/rxjs-interop';
import { isEqual } from 'lodash-es';
import { MessageService } from '@uis-services/message/message.service';

export const authPasswordFlowConfig: AuthConfig = {
  issuer: environment.apiUrl,
  tokenEndpoint: `${environment.apiUrl}/connect/token`,
  userinfoEndpoint: `${environment.apiUrl}/connect/userinfo`,
  revocationEndpoint: `${environment.apiUrl}/connect/revocation`,
  requireHttps: false,
  logoutUrl: `${window.location.origin}/sign-in`,
  clientId: 'uis',
  scope: 'openid profile uis.api email offline_access',
  dummyClientSecret: 'secret',
  showDebugInformation: false,
  oidc: false,
} as const;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /*The implementation is not strictly signal based since during app initialization,
   * until app is not bootstrapped, computed signals are not updated and just contains
   * undefined value, but we need it at initialization time therefore we need to use observables
   * since they behave as expected*/

  private readonly oauthService = inject(OAuthService);
  private readonly message = inject(MessageService);

  private readonly oauthEvents = this.oauthService.events.pipe(
    startWith(null), // Since there in no event on init
    delay(0), // same as setTimeout, needed to this.configureOAuth() to happen
  );

  // Observables are for usage where signals cannot be used like during app initialization
  public readonly claims$ = this.authEventsBasedSubject(() =>
    this.oauthService.getIdentityClaims(),
  ).pipe();
  public readonly userId$: Observable<string | undefined> = this.claims$.pipe(
    map((claims) => claims?.['sub']),
  );
  readonly accessToken$ = this.authEventsBasedSubject(() => {
    return this.oauthService.getAccessToken();
  });
  public readonly refreshToken$ = this.authEventsBasedSubject(() => {
    return this.oauthService.getRefreshToken();
  });

  //Signals for general usage
  public readonly claims = toSignal(this.claims$);
  public readonly userId = toSignal(this.userId$);
  public readonly accessToken = toSignal(this.accessToken$);
  public readonly refreshToken = toSignal(this.refreshToken$);

  constructor() {
    this.configureOAuth();
  }

  reloadUserInfo() {
    return defer(() => this.oauthService.loadUserProfile());
  }

  configureOAuth(): void {
    this.oauthService.configure(authPasswordFlowConfig);
    this.oauthService.setStorage(localStorage);
  }

  private authEventsBasedSubject<T>(mapFn: (event: OAuthEvent | null) => T) {
    return this.oauthEvents.pipe(
      map(mapFn),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      shareReplay(1),
    );
  }

  refreshAccessToken = defer(() => this.oauthService.refreshToken()).pipe(
    switchMap(() => from(this.oauthService.loadUserProfile())),
    share(),
  );

  login(request: LoginRequest): Observable<UserInfo> {
    return defer(() =>
      this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(
        request.email,
        request.password,
      ),
    ) as Observable<UserInfo>;
  }

  logout(config?: {
    noRedirectToLogoutUrl: boolean;
    showMessage: boolean;
  }): Observable<void> {
    const email: string | undefined = this.claims()?.['email'];
    return defer(() =>
      this.oauthService.revokeTokenAndLogout(config?.noRedirectToLogoutUrl),
    ).pipe(
      tap(() => {
        if (email && config?.showMessage) {
          this.message.success(`Ви вийшли з акаунту ${email}`);
        }
      }),
    );
  }
}
