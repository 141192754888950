import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@uis-services/user/user.service';
import { ProfileRoutesConfig } from '@uis-private/profile/profile.routes';

export const isRegistrationCompleteCanActivateGuard: CanActivateFn = (
  _,
  state,
) => {
  const userService = inject(UserService);
  const router = inject(Router);

  const canUse = userService.me()?.isRegistrationComplete;
  const profileEditRoute = ProfileRoutesConfig.absolutePath.edit();
  const isProfileEditRoute = state.url.startsWith(profileEditRoute);

  return (
    canUse || isProfileEditRoute || router.createUrlTree([profileEditRoute])
  );
};
