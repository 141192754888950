import { inject } from '@angular/core';
import { HttpClient, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UisUtils } from '@uis-core/helpers/utils';
import {
  Mutation,
  MutationDescription,
  MutationFactory,
  MutationTarget,
  WasApplied,
} from '@uis-core/interceptors/mutation-interceptor/mutation';
import { BehaviorSubject } from 'rxjs';

const log = new BehaviorSubject<MutationDescription[]>([]);
const mutationsFactory = new MutationFactory(log);

let httpClient: HttpClient;

const requestMutations: Mutation<HttpRequest<unknown>>[] = [];
const responseMutations: Mutation<HttpResponse<unknown>>[] = [];

function registerMutation(
  applyTo: MutationTarget,
  executor: (object: object) => WasApplied,
  description: string,
) {
  const mutation = mutationsFactory.create(
    (req: HttpRequest<unknown> | HttpResponse<unknown>) => {
      if (req.body && typeof req.body === 'object') {
        return executor(req.body);
      } else {
        return false;
      }
    },
    description,
  );

  switch (applyTo) {
    case MutationTarget.Request: {
      requestMutations.push(mutation);
      break;
    }
    case MutationTarget.Response: {
      responseMutations.push(mutation);
      break;
    }
  }

  return mutation;
}

if (environment.logNetworkMutations) {
  log.subscribe((messages) => {
    const message = messages.at(-1);
    const requestStyle = 'color: lightblue';
    const responseStyle = 'color: lightgreen';
    const isResponse = message?.includes(MutationTarget.Response);
    console.info(`%c${message}`, isResponse ? responseStyle : requestStyle);
  });
}

function mutateRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
  requestMutations.forEach((mutation) => mutation.execute(request));
  return request;
}

function mutateResponse(
  response: HttpResponse<unknown>,
): HttpResponse<unknown> {
  responseMutations.forEach((mutation) => mutation.execute(response));
  return response;
}

if (!environment.production) {
  console.warn(
    'Mutation interceptor is currently mutating HTTP response and request data',
  );
}

// TODO: Refactor to register in one place with explicit order
// Mutations -----------------------
// Request

registerMutation(
  MutationTarget.Request,
  UisUtils.uisFilesToContract,
  'Converted UisFile objects to contract model',
);

registerMutation(
  MutationTarget.Request,
  UisUtils.internalCloneDeep, //Should stay after UisUtils.uisFilesToContract mutation for performance reasons
  'Replaced request body fields with deep clones',
);

registerMutation(
  MutationTarget.Request,
  UisUtils.stringifyDates,
  'Converted Date objects to ISO8601 strings',
);

// Response

registerMutation(
  MutationTarget.Response,
  UisUtils.initializeDates,
  'Initialised Date objects for date-like strings (ISO8601)',
);

registerMutation(
  MutationTarget.Response,
  UisUtils.initUisFiles(() => httpClient),
  'Initialised UisFile objects for UisFile-like object',
);

// ----------------------------------

export const mutationInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
) => {
  // effects can be created only inside the injection context
  httpClient ??= inject(HttpClient);

  return next(mutateRequest(req)).pipe(
    filter((res) => res instanceof HttpResponse),
    map((event) => {
      const response = event as HttpResponse<any>;
      return mutateResponse(response);
    }),
  );
};
