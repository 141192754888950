@if (generalInfoForm) {
  <ng-container [formGroup]="generalInfoForm">
    <div class="general-info__profile-picture-container">
      @if (!generalInfoForm.controls.profilePicture.disabled) {
        <uis-form-field
          label="Фото"
          [tooltip]="profilePictureInputComponent.fileLimitationsString()"
        >
          <uis-profile-picture-input
            #profilePictureInputComponent
            formControlName="profilePicture"
          ></uis-profile-picture-input>
        </uis-form-field>
      }
    </div>
    <div class="uis-form-common__input-grid">
      <uis-form-field label="Прізвище">
        <mat-form-field>
          <input
            matInput
            uisTrim
            type="text"
            formControlName="lastName"
            placeholder="Введіть прізвище"
          />
        </mat-form-field>
      </uis-form-field>
      <uis-form-field label="Ім'я">
        <mat-form-field>
          <input
            matInput
            uisTrim
            type="text"
            formControlName="firstName"
            placeholder="Введіть ім'я"
          />
        </mat-form-field>
      </uis-form-field>
      <uis-form-field label="По батькові">
        <mat-form-field>
          <input
            matInput
            uisTrim
            type="text"
            formControlName="middleName"
            placeholder="Введіть по батькові"
          />
        </mat-form-field>
      </uis-form-field>
      <uis-form-field
        hint="Має бути у міжнародному форматі"
        label="Номер телефону"
      >
        <mat-form-field>
          <mat-icon
            matIconPrefix
            inline
            class="general-info__phone-input-prefix"
            >add
          </mat-icon>
          <input
            matInput
            uisTrim
            uisDigitsOnly
            type="tel"
            placeholder="Введіть номер телефону"
            formControlName="phoneNumber"
          />
        </mat-form-field>
      </uis-form-field>
      <uis-form-field label="Email">
        <mat-form-field>
          <input
            matInput
            uisTrim
            type="email"
            formControlName="email"
            placeholder="Введіть email"
          />
        </mat-form-field>
      </uis-form-field>
      @if (!generalInfoForm.controls['skypeUsername'].disabled) {
        <uis-form-field label="Skype">
          <mat-form-field>
            <input
              matInput
              uisTrim
              formControlName="skypeUsername"
              placeholder="Введіть ім'я користувача Skype"
            />
          </mat-form-field>
        </uis-form-field>
      }
      @if (!generalInfoForm.controls['birthDay'].disabled) {
        <uis-form-field label="Дата народження">
          <uis-datepicker
            [max]="birthdayCalendarMaxDate"
            formControlName="birthDay"
            [startAt]="birthdayCalendarInitialDate"
            startView="multi-year"
          />
        </uis-form-field>
      }
      <uis-form-field label="Стать">
        <mat-radio-group formControlName="sex">
          <div class="user-form__radio-buttons-container">
            <mat-radio-button [value]="UserSex.Male">Чоловік</mat-radio-button>
            <mat-radio-button [value]="UserSex.Female">Жінка</mat-radio-button>
          </div>
        </mat-radio-group>
      </uis-form-field>
    </div>
  </ng-container>
}
