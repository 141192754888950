<h2 mat-dialog-title class="dialog__title">{{ title() }}</h2>
<mat-dialog-content>
  @switch (dialogData().action) {
    @case (JoinApplicationViewFromDialogAction.Reject) {
      <ng-container [formGroup]="rejectionForm">
        <uis-form-field label="Причина відхилення заявки">
          <mat-form-field>
            <textarea matInput formControlName="reason"></textarea>
          </mat-form-field>
        </uis-form-field>
      </ng-container>
    }
    @case (JoinApplicationViewFromDialogAction.Correction) {
      <ng-container [formGroup]="correctionForm">
        <uis-form-field label="Причина доопрацювання заявки">
          <mat-form-field>
            <textarea matInput formControlName="reason"></textarea>
          </mat-form-field>
        </uis-form-field>
      </ng-container>
    }
    @case (JoinApplicationViewFromDialogAction.Approve) {
      <ng-container [formGroup]="approveForm">
        <uis-form-field
          label="Клас"
          tooltip="В випадаючому меню маєте обрати клас до якого учень має бути зарахований"
        >
          <mat-form-field>
            <mat-select
              placeholder="Оберіть клас в який зарахувати учня"
              formControlName="classLevel"
            >
              @for (
                classLevelOption of classLevelOptions;
                track classLevelOption
              ) {
                <mat-option [value]="classLevelOption">
                  {{ classLevelOption }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </uis-form-field>
        @if (
          dialogData().joinApplication.classLevel !==
          approveForm.value.classLevel
        ) {
          <uis-info-box
            >Клас який ви обрали відрізнається від того який було вказано в
            заявці
          </uis-info-box>
        }
      </ng-container>
    }
  }
</mat-dialog-content>
<mat-dialog-actions class="dialog__actions">
  <uis-button [matDialogClose]="false" buttonFill="content" buttonColor="gray"
    >Повернутися назад
  </uis-button>
  <uis-button (click)="onConfirm()">Підтвердити</uis-button>
</mat-dialog-actions>
