import { UisFormArray } from './uis-form-array';
import {
  FormGroup,
  AbstractControl,
  FormControl,
  FormArray,
  ɵFormGroupValue,
} from '@angular/forms';
import { untracked } from '@angular/core';

export class UisFormGroup<
  TControl extends { [K in keyof TControl]: AbstractControl<any, any> },
> extends FormGroup<TControl> {
  constructor(
    controls: TControl,
    validatorOrOpts?: ConstructorParameters<typeof FormGroup<TControl>>[1],
    defaultValue?: any,
  ) {
    super(controls, validatorOrOpts);
    if (defaultValue) {
      this.patchValue(defaultValue);
    }
  }

  public override patchValue(
    value: ɵFormGroupValue<TControl>,
    options?: { onlySelf?: boolean; emitEvent?: boolean; initArrays?: boolean },
  ): void {
    // untracked() is needed since there are signals inside some form control components
    // and updating those values triggers unwanted signal write/read side effects if
    // value is patched from inside effect() or computed()
    if (options?.initArrays !== false) {
      untracked(() => {
        this._initFormArrays(value);
      });
    }
    untracked(() => {
      super.patchValue(value, options);
    });
  }

  public override setValue(
    value: { [p: string]: any },
    options?: { onlySelf?: boolean; emitEvent?: boolean; initArrays?: boolean },
  ): void {
    // untracked() is needed since there are signals inside some form control components
    // and updating those values triggers unwanted signal write/read side effects if
    // value is patched from inside effect() or computed()
    if (options?.initArrays !== false) {
      untracked(() => this._initFormArrays(value));
    }
    untracked(() => super.setValue(value as any, options));
  }

  private _initFormArrays(value: any, atFormGroup = this.controls): void {
    for (const key in value) {
      if (Array.isArray(value[key]) && Object.keys(atFormGroup).includes(key)) {
        if ((atFormGroup as any)[key] instanceof UisFormArray) {
          while (this.getFormArray(key).length !== 0) {
            this.getFormArray(key).removeAt(0);
          }
          (value[key] as any[]).forEach(() =>
            this.getFormArray(key).addControl(),
          );
        }
      } else if (
        typeof value[key] === 'object' &&
        (atFormGroup as any)[key] instanceof FormGroup
      ) {
        this._initFormArrays(value[key], (atFormGroup as any)[key] as any);
      }
    }
  }

  public getFormGroup(path: string) {
    return this.get(path);
  }

  public getFormArray(path: string): UisFormArray {
    return this.get(path) as UisFormArray;
  }

  validate(isEmitEvent: boolean = true): boolean {
    this.markAllAsTouched();
    Object.keys(this.controls).forEach((key) => {
      const control = (this.controls as any)[key];

      if (control instanceof UisFormArray) {
        (control as UisFormArray).validate();
      } else if (control instanceof UisFormGroup) {
        (control as UisFormGroup<TControl>).validate();
      } else {
        control.updateValueAndValidity({ emitEvent: isEmitEvent });
      }
    });
    return this.valid;
  }

  private addValueToFormData(
    control: any,
    formData: FormData,
    parentKey: string = '',
    parentRoute: string = '',
  ): void {
    parentRoute = parentRoute ? `${parentRoute}.${parentKey}` : parentKey;
    if (control instanceof FormControl) {
      const value = this.transformData(control.value);

      if (value instanceof Array) {
        value.forEach((res, index) => {
          if (res instanceof File) {
            formData.append(parentRoute, res, res.name);
          } else {
            formData.append(`${parentRoute}[${index}]`, res);
          }
        });
      } else {
        formData.append(parentRoute, value);
      }
    } else {
      Object.keys(control.controls).forEach((key) => {
        this.addValueToFormData(
          control.controls[key],
          formData,
          key,
          parentRoute,
        );
      });
    }
  }

  clone(): UisFormGroup<TControl> {
    const form = new UisFormGroup({});
    Object.keys(this.controls).forEach((controlKey) => {
      const element = this.copyFormControl((this.controls as any)[controlKey]);
      form.addControl(controlKey, element);
    });
    return form as unknown as UisFormGroup<TControl>;
  }

  copyFormControl(control: AbstractControl): AbstractControl {
    if (control instanceof FormArray) {
      const copy = new FormArray<any>([]);
      control.controls.forEach((x) => {
        copy.push(this.copyFormControl(x));
      });
      return copy;
    }

    if (control instanceof FormGroup) {
      const copy = new FormGroup({});
      Object.keys(control.getRawValue()).forEach((key) => {
        copy.addControl(key, this.copyFormControl(control.controls[key]));
      });
      return copy;
    }
    return new FormControl(control.value, control.validator);
  }

  asFormData(): FormData {
    const formData = new FormData();
    this.addValueToFormData(this, formData);
    return formData;
  }

  private transformData(data: any): any {
    if (data instanceof Date) {
      return data?.toJSON();
    } else if (data === null) {
      return '';
    }
    return data;
  }
}
