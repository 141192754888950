import {
  IndividualStudyPlan,
  IndividualStudyPlanItem,
  IndividualStudyPlanItemTaskType,
  IspTableMonthInfo,
  IspTableSubjectData,
} from '@uis-models/contract/individual-study-plan';
import { BadgeConfig } from '@uis-common/badge/badge.component';
import { UisButtonStyleConfig } from '@uis-common/button/button.component';

export interface IspItemTaskViewGeneralVM {
  title: string;
  badges: { text: string; color: BadgeConfig['color'] }[];
  actions: IspItemTaskDialogActionConfig[];
}

export enum IspTaskDialogAction {
  Ok = 'ok',
  EditAssignment = 'edit-assignment',
  SubmitAnswer = 'submit-answer',
  RequestTask = 'request-assignment',
  Reject = 'reject-submission',
  RequestCorrection = 'request-correction',
  Evaluate = 'evaluate-submission',
  EditMark = 'edit-mark',
  UpdateInterviewDate = 'update-interview-date',
  FinishInterview = 'finish-interview',
}

export interface IspItemTaskDialogActionConfig {
  type: IspTaskDialogAction;
  buttonStyle: UisButtonStyleConfig;
  text: string;
  onClick?: () => any;
  dialogResult?: IspTaskDialogAction | null;
}

export interface IspDialogContext {
  individualStudyPlan: IndividualStudyPlan;
  tableSubjectsData: IspTableSubjectData[];
  item?: IndividualStudyPlanItem | null;
  taskType?: IndividualStudyPlanItemTaskType | null;
  createItem?: {
    proposedMonthInfo: IspTableMonthInfo;
    tableSubjectData: IspTableSubjectData;
  };
  action?: IspTaskDialogAction;
  isEditMode?: boolean;
  tasksCanBeEdited?: IndividualStudyPlanItemTaskType[];
}
