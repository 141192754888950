import {
  Directive,
  effect,
  EventEmitter,
  HostListener,
  Input,
  Output,
  signal,
} from '@angular/core';

@Directive({
  selector: '[uisDragDrop]',
  standalone: true,
})
export class DragDropDirective {
  @Input() uisDragDropType: 'files' | 'any' = 'files';

  @HostListener('window:dragover', ['$event'])
  onWindowDrag(event: DragEvent) {
    event.preventDefault();
    if (this.uisDragDropType === 'files') {
      if (event.dataTransfer?.types.includes('Files')) {
        this.pushIsDraggingState(true);
      }
    } else {
      this.pushIsDraggingState(true);
    }
  }

  @HostListener('window:dragleave', ['$event'])
  onWindowDragEnd(event: DragEvent) {
    if (event.relatedTarget === null) {
      this.pushIsDraggingState(false);
    }
  }

  @HostListener('window:drop', ['$event'])
  onWindowDrop(dropEvent: DragEvent) {
    dropEvent.preventDefault();
    this.pushIsDraggingState(false);
  }

  @HostListener('drop', ['$event'])
  onDrop(dropEvent: DragEvent) {
    if (this.uisDragDropType === 'files') {
      if (dropEvent.dataTransfer?.types.includes('Files')) {
        this.uisDrop.emit(Array.from(dropEvent.dataTransfer.files));
        return;
      }
    } else {
      this.uisDrop.emit(dropEvent);
    }
  }

  @Output() uisIsDragging = new EventEmitter();
  @Output() uisDrop = new EventEmitter();

  private readonly isDragging = signal(false);
  private readonly isDraggingEffect = effect(
    () => {
      const isDragging = this.isDragging();
      this.uisIsDragging.emit(isDragging);
    },
    { allowSignalWrites: true },
  );

  pushIsDraggingState(isDragging: boolean) {
    this.isDragging.set(isDragging);
  }
}
