import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@uis-services/user/user.service';
import { PublicRoute } from 'src/app/content/pages/public/public.routes';
import { SignInQueryParams } from '@uis-core/enums/query-params';

export const userOnlyCanActivateGuard: CanActivateFn = () => {
  const userService = inject(UserService);
  const router = inject(Router);

  return userService.me()
    ? true
    : router.createUrlTree([PublicRoute.SignIn], {
        queryParams:
          location.pathname.length > 1 // To prevent next navigation from being '/'
            ? { [SignInQueryParams.Next]: location.pathname }
            : null,
        queryParamsHandling: 'merge',
      });
};
