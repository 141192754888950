import { HttpRequest, HttpResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

export type MutationFn<T> = (obj: T) => WasApplied;

// All mutation handlers should return a boolean value which represents weather that handler has actually mutated the object
export type WasApplied = boolean;
export type MutationDescription = `[MUTATION][${MutationTarget}] ${string}`;

export class MutationFactory {
  constructor(private log: BehaviorSubject<MutationDescription[]>) {}

  create<T>(handler: MutationFn<T>, description: string) {
    return new Mutation(handler, description, this.log);
  }
}

export class Mutation<T> {
  constructor(
    private handler: MutationFn<T>,
    public description: string,
    private log: BehaviorSubject<MutationDescription[]>,
  ) {}

  execute(request: T) {
    const wasApplied = this.handler(request);
    if (wasApplied) {
      if (request instanceof HttpRequest) {
        this.log.next([
          ...this.log.value,
          this.createDescription(
            MutationTarget.Request,
            this.description,
            request,
          ),
        ]);
      }
      if (request instanceof HttpResponse) {
        this.log.next([
          ...this.log.value,
          this.createDescription(
            MutationTarget.Response,
            this.description,
            request,
          ),
        ]);
      }
    }
  }

  createDescription(
    target: MutationTarget,
    description: string,
    requestOrResponse: HttpRequest<any> | HttpResponse<any>,
  ): MutationDescription {
    return `[MUTATION][${target}] ${description} in ${
      target === MutationTarget.Request ? 'request' : 'response'
    } body for ${requestOrResponse.url}`;
  }
}

export enum MutationTarget {
  Request = 'REQUEST',
  Response = 'RESPONSE',
}
