import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoadingService {
  loadingsCount = new BehaviorSubject(0);
  isLoading = this.loadingsCount.pipe(map((count) => count > 0));

  watchLoading<T>(obs: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => {
        this.addLoading();
      }),
      switchMap(() =>
        obs.pipe(
          tap({
            finalize: () => this.removeLoading(),
          }),
        ),
      ),
    );
  }

  addLoading() {
    this.loadingsCount.next(this.loadingsCount.value + 1);
  }

  removeLoading() {
    this.loadingsCount.next(
      this.loadingsCount.value > 0 ? this.loadingsCount.value - 1 : 0,
    );
  }
}
