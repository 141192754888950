import { APP_INJECTOR } from '../../../../main';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { UisUtils } from '@uis-core/helpers/utils';
import { FileEP } from '@uis-enums/endpoints';
import { UisFileType } from '@uis-enums/file-types';
import { AllowedFileMimeType } from '@uis-common/inputs/file-inputs/file-type-settings';
import { FormControl } from '@angular/forms';
import { UisValidators } from '@uis-core/validators/validators';

export interface IUisFile<FileType = UisFileType> {
  id?: string;
  fileName: string;
  url: string;
  contentType: string;
  fileSize: number;
  type?: FileType;
  createdAt: Date;
}

export class UisFile<FileType = UisFileType> implements IUisFile<FileType> {
  private _id?: string;
  public get id() {
    return this._id;
  }

  private _fileName!: string;
  public get fileName() {
    return this._fileName;
  }

  private _url!: string;
  public get url() {
    return this._url;
  }

  private _contentType!: AllowedFileMimeType;
  get contentType() {
    return this._contentType;
  }

  private _type?: FileType;
  get type() {
    return this._type;
  }

  private _fileSize!: number;
  get fileSize() {
    return this._fileSize;
  }

  private _createdAt!: Date;
  get createdAt() {
    return this._createdAt;
  }

  private _pendingContent?: File;
  get pendingContent() {
    return this._pendingContent;
  }

  private _isUploaded: boolean = false;
  get isUploaded() {
    return this._isUploaded;
  }

  private _isUploading: boolean = false;
  get isUploading() {
    return this._isUploading;
  }

  private _uploadingError: boolean = false;
  get uploadingError() {
    return this._uploadingError;
  }

  private readonly _changes$ = new BehaviorSubject<UisFile<FileType>>(this);
  private readonly stopUploading$ = new Subject<void>();

  get changes$() {
    return this._changes$.asObservable();
  }

  private readonly http: HttpClient;

  constructor(
    file: IUisFile<FileType> | File,
    fileType?: FileType,
    httpClient: HttpClient = APP_INJECTOR.get(HttpClient),
  ) {
    this.http = httpClient;
    this._init(file, fileType);
    this._changes$.next(this);
  }

  private _init(
    file: IUisFile<FileType> | File,
    fileType?: FileType,
    uploadingError = false,
  ) {
    const isJsFile = file instanceof File;
    const isUploaded = !!(file as any)?.id;
    const fileAsUisFile = file as UisFile<FileType>;
    const fileAsJsFile = file as File;

    this._id = isUploaded ? fileAsUisFile.id : undefined;
    this._fileName = isJsFile ? fileAsJsFile.name : fileAsUisFile.fileName;
    this._url = isJsFile
      ? URL.createObjectURL(fileAsJsFile)
      : fileAsUisFile.url;
    this._contentType = isJsFile
      ? (file.type as AllowedFileMimeType)
      : fileAsUisFile.contentType;
    this._type = fileType ?? (isJsFile ? undefined : fileAsUisFile.type);
    this._fileSize = isJsFile ? fileAsJsFile.size : fileAsUisFile.fileSize ?? 0;
    this._isUploaded = isUploaded;
    this._isUploading = false;
    this._pendingContent = isUploaded ? undefined : fileAsJsFile;
    this._uploadingError = uploadingError;
    this._createdAt = isJsFile ? new Date() : fileAsUisFile.createdAt;

    this._changes$.next(this);
    return this;
  }

  isOfType<TestedFileType>(
    type: TestedFileType,
  ): this is UisFile<TestedFileType> {
    return this._type === type;
  }

  private markAsLoading() {
    this._isUploading = true;
    this._changes$.next(this);
  }

  public upload(): Observable<UisFile<FileType>> {
    return this._isUploaded
      ? of(this)
      : of(this).pipe(
          tap(() => this.markAsLoading()),
          map((file) => {
            const formData = new FormData();
            formData.set('file', file.pendingContent!);
            if (file.type) {
              formData.set('type', (file.type as string) ?? '');
            }
            return formData;
          }),
          switchMap((formData) =>
            this.http.post<UisFile<FileType>>(FileEP.POST(), formData),
          ),
          takeUntil(this.stopUploading$),
          map((file) => this._init(file)),
          catchError(() => of(this._init(this, this.type, true))),
        );
  }

  public stopUploading() {
    this.stopUploading$.next();
  }

  get asContract(): IUisFile<FileType> {
    return {
      id: this.id,
      fileName: this.fileName,
      url: this.url,
      fileSize: this.fileSize,
      contentType: this.contentType,
      type: this.type,
      createdAt: this.createdAt,
    };
  }
}

export function fileIsOfType<FileType>(type: FileType) {
  return (file: UisFile<any>): file is UisFile<FileType> => file.type === type;
}

export function isUisFile(obj: any): obj is IUisFile {
  return (
    !!obj &&
    typeof obj === 'object' &&
    obj.url &&
    obj.fileName &&
    obj.contentType &&
    typeof obj.fileSize === 'number' &&
    obj.id
  );
}

const SPREAD_FILES_SEPARATOR = '__';
export type SpreadFileTypeControlKey<
  Root extends string | number,
  T extends string,
> = `${Root}${typeof SPREAD_FILES_SEPARATOR}${T}`;

export function getSpreadFileTypeName(
  from: string | number,
  fileType: UisFileType,
): SpreadFileTypeControlKey<typeof from, typeof fileType> {
  return `${from}${SPREAD_FILES_SEPARATOR}${fileType}`;
}

// Used when you receive a model with file array to spread it to separate form control for each file type
// {attachments: [UisFile,UisFile,UisFile]} =>
// {
//  attachments: [UisFile,UisFile,UisFile],
//  attachments__file-type-1: [UisFile]
//  attachments__file-type-2: [UisFile, UisFile]
//  }
export function spreadUisFilesByType(obj: any) {
  UisUtils.mutateObjectFields(
    obj,
    (value: UisFile | UisFile[], key) => {
      const normalizedArray = Array.isArray(value) ? value : [value];
      normalizedArray.forEach((file) => {
        if (file.type) {
          if (!obj[getSpreadFileTypeName(key, file.type)]) {
            obj[getSpreadFileTypeName(key, file.type)] = [];
          }
          obj[`${key}${SPREAD_FILES_SEPARATOR}${file.type}`].push(file);
        }
      });
      return value;
    },
    (value: any) => {
      return (
        value instanceof UisFile ||
        (Array.isArray(value) &&
          !!value.length &&
          value.every((entry) => entry instanceof UisFile))
      );
    },
  );

  return obj;
}

// Used to flatten the file array value when you have a form value with spread file type inputs
// {
//  attachments: [],
//  attachments__file-type-1: [UisFile]
//  attachments__file-type-2: [UisFile, UisFile]
//  } => {attachments: [UisFile,UisFile,UisFile]}
export function mergeUisFiles(obj: any) {
  UisUtils.mutateObjectFields(
    obj,
    (value, key, currObject) => {
      const result: UisFile[] = [];
      Object.entries(currObject).forEach(
        ([currObjectKey, currObjectKeyValue]) => {
          if (currObjectKey.startsWith(`${key}${SPREAD_FILES_SEPARATOR}`)) {
            if (currObjectKeyValue) {
              let normalizedValueArray = Array.isArray(currObjectKeyValue)
                ? currObjectKeyValue
                : [currObjectKeyValue];
              normalizedValueArray = normalizedValueArray
                .filter(isUisFile)
                .map((uisFile) =>
                  uisFile instanceof UisFile ? uisFile : new UisFile(uisFile),
                );
              result.push(...normalizedValueArray);
            }
            delete (currObject as any)[currObjectKey];
          }
        },
      );

      return result;
    },
    (_, key, currObject) => {
      return Object.keys(currObject ?? obj).some((currObjKey) => {
        return currObjKey.startsWith(`${key}${SPREAD_FILES_SEPARATOR}`);
      });
    },
  );

  return obj;
}

// Used to create spread form controls for file types form parent files array
export function getFileTypeControls<
  Root extends string | number,
  T extends UisFileType,
>(
  parentArrayKey: Root,
  controls: Record<T, FormControl>,
): { [key: ReturnType<typeof getSpreadFileTypeName>]: FormControl } {
  const mappedControls: {
    [key: ReturnType<typeof getSpreadFileTypeName>]: FormControl;
  } = {};
  for (let fileType in controls) {
    controls[fileType].addValidators(UisValidators.validateFileType(fileType));
    mappedControls[getSpreadFileTypeName(parentArrayKey, fileType)] =
      controls[fileType];
  }

  return mappedControls;
}
