import { UisEntity } from '@uis-models/contract/uis-entity';
import { Employee, Student } from '@uis-models/contract/user';
import { UisSubject } from '@uis-models/contract/subject';
import { UisFile } from '@uis-models/contract/uis-file';
import { AcademicYear } from '@uis-models/contract/academic-year';
import { UisIcon } from '@uis-services/uis-mat-icon-registry/uis-mat-icon-registry.service';

export interface UisClass extends UisEntity {
  classLevel: number;
  curator?: Employee;
  subjects: UisSubject[];
  studentsCount?: number;
}

export interface IndividualStudyPlan extends UisEntity {
  isActive: boolean;
  student: Student;
  academicYear: AcademicYear;
  class: UisClass;
  status: IndividualStudyPlanStatus;
  subjectsData: IndividualStudyPlanSubjectData[];
  studentAccessGranted: boolean;
}

export interface IndividualStudyPlanSubjectData {
  scheduleType: IndividualStudyPlanSubjectDataScheduleType;
  summaryMark?: UisMark;
  subject?: UisSubject;
  teacher?: Employee;
  isActive: boolean;
}

export interface IspTableSubjectData extends IndividualStudyPlanSubjectData {
  isFull: boolean;
  items: IndividualStudyPlanItem[];
}

export enum IndividualStudyPlanItemStatus {
  Created = 'created',
  Available = 'available',
  InProgress = 'in-progress',
  Rejected = 'rejected',
  Completed = 'completed',
}

export enum IndividualStudyPlanItemTaskStatus {
  Created = 'created',
  Available = 'available',
  PreviousNotDone = 'previous-not-done',
  InProgress = 'in-progress',
  InReview = 'in-review',
  CorrectionNeeded = 'correction-needed',
  Rejected = 'rejected',
  Completed = 'completed',
}

export const IndividualStudyPlanItemTaskStatusText: {
  [key in IndividualStudyPlanItemTaskStatus]: string;
} = {
  [IndividualStudyPlanItemTaskStatus.Available]: 'Завдання доступне',
  [IndividualStudyPlanItemTaskStatus.Created]: 'Завдання ще не доступне',
  [IndividualStudyPlanItemTaskStatus.PreviousNotDone]:
    'Потрібно виконати попереднє завдання',
  [IndividualStudyPlanItemTaskStatus.InReview]: 'Завдання перевіряється',
  [IndividualStudyPlanItemTaskStatus.InProgress]: 'Завдання виконується',
  [IndividualStudyPlanItemTaskStatus.Completed]: 'Завдання оцінено',
  [IndividualStudyPlanItemTaskStatus.Rejected]: 'Завдання відхилено',
  [IndividualStudyPlanItemTaskStatus.CorrectionNeeded]:
    'Завдання потребує доопрацювання',
} as const;

export const IndividualStudyPlanItemTaskStatusIcon: {
  [key in IndividualStudyPlanItemTaskStatus]: UisIcon;
} = {
  [IndividualStudyPlanItemTaskStatus.Available]: UisIcon.ISPTaskStatusAvailable,
  [IndividualStudyPlanItemTaskStatus.Created]:
    UisIcon.ISPTaskStatusNotAvailable,
  [IndividualStudyPlanItemTaskStatus.PreviousNotDone]:
    UisIcon.ISPTaskStatusPreviousNotDone,
  [IndividualStudyPlanItemTaskStatus.InReview]: UisIcon.ISPTaskStatusInReview,
  [IndividualStudyPlanItemTaskStatus.InProgress]:
    UisIcon.ISPTaskStatusInProgress,
  [IndividualStudyPlanItemTaskStatus.Completed]: UisIcon.ISPTaskStatusEvaluated,
  [IndividualStudyPlanItemTaskStatus.Rejected]: UisIcon.ISPTaskStatusRejected,
  [IndividualStudyPlanItemTaskStatus.CorrectionNeeded]:
    UisIcon.ISPTaskStatusRevision,
} as const;

export interface IndividualStudyPlanItem extends UisEntity {
  classLevel: number;
  studentId: string;
  type: IndividualStudyPlanItemType;
  individualStudyPlanId: string;
  individualStudyPlanStatus: IndividualStudyPlanStatus;
  subjectData: IndividualStudyPlanSubjectData;
  status: IndividualStudyPlanItemStatus;
  tasks: IndividualStudyPlanItemTask[];
  summaryMark?: UisMark;
  currentAssignmentVariantId?: string;
  rejectReason?: string;
  rejectedAssignmentVariantsIds?: string[];
}

export interface IndividualStudyPlanItemTask {
  fullName: string;
  shortName: string;
  status: IndividualStudyPlanItemTaskStatus;
  date: Date;
  isOverdue: boolean;
  type: IndividualStudyPlanItemTaskType;
  description: string;
  attachments: UisFile[];
  assignment?: IndividualStudyPlanItemTaskEntry;
  answer?: IndividualStudyPlanItemTaskEntry;
  correctionHistory: IndividualStudyPlanItemTaskCorrectionHistoryEntry[];
  assessmentResult?: IndividualStudyPlanItemAssessTaskRequest;
}

export interface IndividualStudyPlanItemTaskRequest {
  date: Date;
  type: IndividualStudyPlanItemTaskType;
  assignment: IndividualStudyPlanItemTaskEntry;
}

export interface IndividualStudyPlanItemTaskEntry {
  attachments: UisFile[];
  providedAt?: Date;
  description?: string;
}

export interface IndividualStudyPlanItemTaskCorrectionHistoryEntry {
  answer: IndividualStudyPlanItemTaskEntry;
  correctionReason: string;
  correctionRequestedAt: Date;
}

export interface IndividualStudyPlanItemRejectRequest {
  rejectReason: string;
  currentAssignmentVariantId?: string;
  tasks: IndividualStudyPlanItem['tasks'];
}

export interface IndividualStudyPlanItemAssessTaskActionRequest {
  taskType: IndividualStudyPlanItemTaskType;
}

export interface IndividualStudyPlanItemAssessTaskRequest
  extends IndividualStudyPlanItemAssessTaskActionRequest {
  mark: UisMark;
  reviewedAnswer?: IndividualStudyPlanItemTaskEntry;
}

export interface IndividualStudyPlanItemTaskSubmitAnswerRequest
  extends IndividualStudyPlanItemAssessTaskActionRequest {
  answer: IndividualStudyPlanItemTaskEntry;
}

export interface IndividualStudyPlanItemTaskCorrectionRequest
  extends IndividualStudyPlanItemAssessTaskActionRequest {
  correctionReason: string;
}

export interface UpdateInterviewTimeForIndividualStudyPlanItem {
  date: Date;
}

export enum IndividualStudyPlanItemType {
  FirstSemesterEvaluation = 'first-semester-evaluation',
  SecondSemesterEvaluation = 'second-semester-evaluation',
  EndOfYearEvaluation = 'end-of-year-evaluation',
}

export const IndividualStudyPlanItemTypeText: {
  [key in IndividualStudyPlanItemType]: string;
} = {
  [IndividualStudyPlanItemType.FirstSemesterEvaluation]:
    'Оцінювання за перший семестр',
  [IndividualStudyPlanItemType.SecondSemesterEvaluation]:
    'Оцінювання за другий семестр',
  [IndividualStudyPlanItemType.EndOfYearEvaluation]: 'Оцінювання за рік',
} as const;

export enum IndividualStudyPlanItemTaskType {
  WrittenAssignment = 'written-assignment',
  Interview = 'interview',
}

export const IndividualStudyPlanItemTaskTypeText: {
  [key in IndividualStudyPlanItemTaskType]: string;
} = {
  [IndividualStudyPlanItemTaskType.WrittenAssignment]: 'Письмове завдання',
  [IndividualStudyPlanItemTaskType.Interview]: 'Співбесіда',
} as const;

export const IndividualStudyPlanItemTaskTypeTitle: {
  [key in IndividualStudyPlanItemType]: string;
} = {
  [IndividualStudyPlanItemType.FirstSemesterEvaluation]: 'СКР 1',
  [IndividualStudyPlanItemType.SecondSemesterEvaluation]: 'СКР 2',
  [IndividualStudyPlanItemType.EndOfYearEvaluation]: 'РКР',
} as const;

export enum IndividualStudyPlanStatus {
  Draft = 'draft',
  InProgress = 'in-progress',
  Overdue = 'overdue',
  Completed = 'completed',
}

export const IndividualStudyPlanStatusText: {
  [key in IndividualStudyPlanStatus]: string;
} = {
  [IndividualStudyPlanStatus.Draft]: 'Створюється',
  [IndividualStudyPlanStatus.InProgress]: 'Виконується',
  [IndividualStudyPlanStatus.Overdue]: 'Прострочений',
  [IndividualStudyPlanStatus.Completed]: 'Виконаний',
} as const;

export enum IndividualStudyPlanSubjectDataScheduleType {
  BySemester = 'by-semester',
  ByYear = 'by-year',
}

export const IndividualStudyPlanSubjectDataScheduleTypeText: {
  [key in IndividualStudyPlanSubjectDataScheduleType]: string;
} = {
  [IndividualStudyPlanSubjectDataScheduleType.BySemester]: 'СКР',
  [IndividualStudyPlanSubjectDataScheduleType.ByYear]: 'РКР',
} as const;

export const IndividualStudyPlanSubjectDataScheduleTypeContent: {
  [key in IndividualStudyPlanSubjectDataScheduleType]: readonly IndividualStudyPlanItemType[];
} = {
  [IndividualStudyPlanSubjectDataScheduleType.BySemester]: [
    IndividualStudyPlanItemType.FirstSemesterEvaluation,
    IndividualStudyPlanItemType.SecondSemesterEvaluation,
  ],
  [IndividualStudyPlanSubjectDataScheduleType.ByYear]: [
    IndividualStudyPlanItemType.EndOfYearEvaluation,
  ],
} as const;

export enum MarkType {
  Numeric = 'numeric',
  Verbal = 'verbal',
  Leveled = 'leveled',
}

export const MarkTypeText: {
  [key in MarkType]: string;
} = {
  [MarkType.Numeric]: 'Числова',
  [MarkType.Verbal]: 'Вербальна',
  [MarkType.Leveled]: 'Рівнева',
} as const;

export enum VerbalMarkValue {
  Passed = 'passed',
  Failed = 'failed',
}

export const VerbalMarkValueText: {
  [key in VerbalMarkValue]: string;
} = {
  [VerbalMarkValue.Passed]: 'Зараховано',
  [VerbalMarkValue.Failed]: 'Не зараховано',
} as const;

export enum LeveledMarkValue {
  Elementary = 'elementary',
  Medium = 'medium',
  Sufficient = 'sufficient',
  High = 'high',
}

export const LeveledMarkValueText: {
  [key in LeveledMarkValue]: string;
} = {
  [LeveledMarkValue.Elementary]: 'Початковий',
  [LeveledMarkValue.Medium]: 'Середній',
  [LeveledMarkValue.Sufficient]: 'Достатній',
  [LeveledMarkValue.High]: 'Високий',
} as const;

export const NumericMarkValuePostfix: { [key in NumericMarkValue]: string } = {
  1: 'бал',
  2: 'бали',
  3: 'бали',
  4: 'бали',
  5: 'балів',
  6: 'балів',
  7: 'балів',
  8: 'балів',
  9: 'балів',
  10: 'балів',
  11: 'балів',
  12: 'балів',
} as const;

export enum NumericMarkValue {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  Eight = '8',
  Nine = '9',
  Ten = '10',
  Eleven = '11',
  Twelve = '12',
}

interface MarkBase {
  type: string;
  value: string;
  comment?: string;
}

export interface NumericMark extends MarkBase {
  type: MarkType.Numeric;
  value: NumericMarkValue;
}

export interface VerbalMark extends MarkBase {
  type: MarkType.Verbal;
  value: VerbalMarkValue;
}

export interface LeveledMark extends MarkBase {
  type: MarkType.Leveled;
  value: LeveledMarkValue;
}

export function getMarkText(mark: UisMark): {
  full: string;
  value: string;
  postfix?: string;
} {
  switch (mark.type) {
    case MarkType.Numeric:
      return {
        value: mark.value,
        postfix: NumericMarkValuePostfix[mark.value],
        full: `${mark.value} ${NumericMarkValuePostfix[mark.value]}`,
      };
    case MarkType.Verbal:
      return {
        value: VerbalMarkValueText[mark.value],
        full: VerbalMarkValueText[mark.value],
      };
    case MarkType.Leveled:
      return {
        value: LeveledMarkValueText[mark.value],
        postfix: 'рівень',
        full: `${LeveledMarkValueText[mark.value]} рівень`,
      };
  }
}

export type UisMark = NumericMark | VerbalMark | LeveledMark;

export interface IspTableMonthInfo {
  month: number;
  year: number;
}

export interface IndividualStudyPlanItemAssignmentVariant extends UisEntity {
  id: string;
  classLevel: number;
  itemType: IndividualStudyPlanItemType;
  subject: UisSubject;
  tasks: IndividualStudyPlanItemTask[];
  name: string;
  rejected: boolean;
}
