import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  forwardRef,
  HostListener,
  inject,
  Input,
  signal,
  ViewChild,
} from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DragDropDirective } from '@uis-directives/drag-drop/drag-drop.directive';
import { MessageService } from '@uis-services/message/message.service';
import { bytesToDisplayString, noop } from '@uis-core/helpers/utils';
import { UisFileType, UserProfileFile } from '@uis-enums/file-types';
import {
  getFileTypeError,
  getMimeTypeExtensionString,
  UisFileTypeSettingsRegistry,
} from '@uis-common/inputs/file-inputs/file-type-settings';
import { isUisFile, UisFile } from '@uis-models/contract/uis-file';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { of, switchMap } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'uis-profile-picture-input',
  standalone: true,
  imports: [MatIconModule, MatProgressSpinnerModule, MatTooltipModule],
  templateUrl: './profile-picture-input.component.html',
  styleUrls: ['./profile-picture-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProfilePictureInputComponent),
      multi: true,
    },
  ],
  host: {
    '[tabIndex]': 'disabled() ? -1 : 0',
    '(uisIsDragging)': 'this.isDragging.set($event)',
    '(uisDrop)': 'onDrop($any($event))',
    '[class.uis-input-error]': 'hasUploadError()',
    '[class.uis-input-disabled]': 'disabled()',
    '[class.uis-input-focused]': 'focused()',
  },
  hostDirectives: [
    { directive: DragDropDirective, outputs: ['uisIsDragging', 'uisDrop'] },
  ],
})
export class ProfilePictureInputComponent implements ControlValueAccessor {
  private readonly message = inject(MessageService);
  private readonly destroyRef = inject(DestroyRef);

  @Input({ alias: 'limitationsInfoVisible' })
  set limitationsInfoVisibleInput(limitationsInfoVisible: boolean) {
    this.limitationsInfoVisible.set(limitationsInfoVisible);
  }

  public readonly limitationsInfoVisible = signal(false);

  public readonly disabled = signal(false);
  public readonly isDragging = signal(false);
  public readonly focused = signal(false);
  public readonly hovered = signal(false);

  protected readonly fileType = signal<UisFileType>(
    UserProfileFile.ProfilePicture,
  );
  protected readonly fileTypeSettings = computed(
    () => UisFileTypeSettingsRegistry[this.fileType()],
  );
  protected readonly htmlInputAcceptString = computed(() =>
    this.fileTypeSettings().allowedMimeTypes.join(', ').toLowerCase(),
  );
  protected readonly internalValue = signal<UisFile | null>(null);
  protected readonly internalValueDynamicState = toSignal<UisFile | null>(
    toObservable(this.internalValue).pipe(
      switchMap((file) => (file ? file.changes$ : of(file))),
    ),
    { initialValue: null as any },
  );
  protected readonly hasUploadError = computed(
    () => this.internalValueDynamicState()?.uploadingError,
  );
  public readonly fileLimitationsString = computed(() => {
    const allowedMimeTypes = this.fileTypeSettings().allowedMimeTypes;
    const allowedMimeTypesString = allowedMimeTypes.length
      ? allowedMimeTypes
          .map((mimeType) => getMimeTypeExtensionString(mimeType))
          .join(', ')
      : '';
    return `${bytesToDisplayString(
      this.fileTypeSettings().maxFileSize,
    )}, ${allowedMimeTypesString}`;
  });

  @ViewChild('htmlInput', { static: true })
  htmlFileInput!: ElementRef<HTMLInputElement>;

  @HostListener('focus', ['$event'])
  @HostListener('blur', ['$event'])
  focusHandler(event: FocusEvent) {
    this.focused.set(this.disabled() ? false : event.type === 'focus');
  }

  @HostListener('pointerenter', ['$event'])
  @HostListener('pointerleave', ['$event'])
  hoverHandler(event: PointerEvent) {
    this.hovered.set(event.type === 'pointerenter');
  }

  @HostListener('keydown', ['$event'])
  keyPressHandler(event: KeyboardEvent) {
    if (!this.focused()) {
      return;
    }

    if (event.key === 'Enter') {
      this.htmlFileInput.nativeElement.click();
    }
  }

  onChange = noop;
  onTouched = noop;

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled.set(isDisabled);
  }

  writeValue(value: UisFile | undefined | null) {
    this.internalValue.set(
      value && isUisFile(value) ? new UisFile(value, this.fileType()) : null,
    );
  }

  onDrop(files: File[]) {
    if (!this.disabled()) {
      this.onAddFiles(files);
    }
  }

  updateControlValue() {
    if (this.disabled()) {
      return;
    }
    this.onChange(
      this.internalValue()?.isUploaded ? this.internalValue() : null,
    );
    this.onTouched();
  }

  onAddFiles(files: File[]) {
    if (files.length > 1) {
      this.message.warn('Можна завантажити лише один файл');
      return;
    }

    if (files.length) {
      const newFile = files[0];
      if (!this.validateFile(newFile)) {
        return;
      }
      const validFile = new UisFile(newFile, this.fileType());
      this.internalValue.set(validFile);
      validFile
        .upload()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.updateControlValue());
    }
  }

  validateFile(file: File): File | undefined {
    const firstErrorMessage = getFileTypeError(
      [new UisFile(file, this.fileType())],
      this.fileType(),
    );

    if (firstErrorMessage) {
      this.message.warn(firstErrorMessage);
      return;
    }

    return file;
  }

  onDelete() {
    this.internalValue.set(null);
    this.updateControlValue();
  }

  onHtmlInputChange(event: Event) {
    if (!this.disabled()) {
      this.onAddFiles(Array.from((event.target as any)['files']));
    }
    (event.target as any).value = null;
  }
}
