import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';

@Component({
  selector: 'uis-badge',
  standalone: true,
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input({ alias: 'color' }) set colorInput(
    color: BadgeConfig['color'] | null | undefined,
  ) {
    this.color.set(color ?? 'info');
  }

  protected readonly color = signal<BadgeConfig['color']>('info');
}

export type BadgeConfig = {
  color: 'info' | 'success' | 'warn' | 'danger';
};
