import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AcademicYearsEP } from '@uis-enums/endpoints';
import { KendoDataQuery } from '@uis-core/classes/kendo-data-query';
import { AcademicYear } from '@uis-models/contract/academic-year';
import { KendoFilterOperator, KendoSortDir } from '@uis-enums/kendo';
import { catchError, map, of } from 'rxjs';
import { SearchResult } from '@uis-models/contract/search';

@Injectable({
  providedIn: 'root',
})
export class AcademicYearService {
  private readonly http = inject(HttpClient);

  public getAllAcademicYears(
    kendoQuery: KendoDataQuery<AcademicYear> = new KendoDataQuery<AcademicYear>(),
  ) {
    kendoQuery.pushSorts({
      field: 'yearFrom',
      dir: KendoSortDir.Asc,
    });
    return this.http
      .get<
        SearchResult<AcademicYear>
      >(AcademicYearsEP.All(), kendoQuery.asHttpOptions())
      .pipe(catchError(() => of(new SearchResult<AcademicYear>())));
  }

  public getActiveAcademicYear() {
    const kendoQuery = new KendoDataQuery<AcademicYear>();
    kendoQuery.pushFilters({
      field: 'isActive',
      operator: KendoFilterOperator.Eq,
      value: true,
    });
    return this.getAllAcademicYears(kendoQuery).pipe(map((res) => res.data[0]));
  }

  public updateAcademicYear(academicYear: AcademicYear) {
    return this.http.put(AcademicYearsEP.ById(academicYear.id), academicYear);
  }
}
