import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { NOT_FOUND_ROUTE_PATH } from '../../content/pages/not-found/not-found.routes';
import { NotFoundComponentInputs } from '../../content/pages/not-found/not-found.component';

export const wildcardPreserveRouteGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  return router.createUrlTree([NOT_FOUND_ROUTE_PATH], {
    queryParams: {
      [NotFoundComponentInputs.FailedRoute]: state.url,
    },
  });
};
