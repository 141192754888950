import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JoinApplicationEP } from '@uis-enums/endpoints';
import { catchError, map, of } from 'rxjs';
import {
  JoinApplication,
  JoinApplicationCreateRequest,
} from '@uis-models/contract/join-application';
import { KendoDataQuery } from '@uis-core/classes/kendo-data-query';
import { SearchResult } from '@uis-models/contract/search';
import { AcademicYearService } from '@uis-services/academic-year/academic-year.service';
import { Student } from '@uis-models/contract/user';

@Injectable({
  providedIn: 'root',
})
export class JoinApplicationService {
  private readonly http = inject(HttpClient);
  private readonly academicYearService = inject(AcademicYearService);

  public getJoinApplications(kendoQuery = new KendoDataQuery()) {
    return this.http
      .get<
        SearchResult<JoinApplication>
      >(JoinApplicationEP.All(), kendoQuery.asHttpOptions())
      .pipe(catchError(() => of(new SearchResult<JoinApplication>())));
  }

  getJoinApplication(id: string) {
    return this.http.get<JoinApplication>(JoinApplicationEP.ById(id));
  }

  getJoinApplicationYearOptions() {
    return this.academicYearService.getAllAcademicYears().pipe(
      map((res) => {
        const activeYear = res.data.find((year) => year.isActive);
        const activeYearIndex = res.data.indexOf(activeYear!);
        const nextYear = res.data[activeYearIndex + 1];

        return [activeYear, nextYear].filter((year) => year);
      }),
    );
  }

  createJoinApplication(student: Student) {
    return this.http.post<JoinApplication>(JoinApplicationEP.All(), {
      student,
    } as JoinApplicationCreateRequest);
  }

  updateJoinApplication(
    original: JoinApplication,
    changes: Partial<JoinApplication>,
  ) {
    return this.http.put(JoinApplicationEP.ById(original.id), {
      ...original,
      ...changes,
    });
  }

  confirmJoinApplication(joinApplication: JoinApplication) {
    return this.http.put(
      JoinApplicationEP.Send(joinApplication.id),
      joinApplication,
    );
  }

  requestCorrectionForJoinApplication(id: string, reason: string) {
    return this.http.put(JoinApplicationEP.Correction(id), { reason });
  }

  rejectJoinApplication(id: string, reason: string) {
    return this.http.put(JoinApplicationEP.Reject(id), { reason });
  }

  approveJoinApplication(id: string, classLevel: number) {
    return this.http.put(JoinApplicationEP.Approve(id), { classLevel });
  }
}
