import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const detailExpand = trigger('detailExpand', [
  state('false', style({ height: '0px', minHeight: '0', overflow: 'hidden' })),
  state('true', style({ height: '*', overflow: 'hidden' })),
  transition('true <=> false', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);
