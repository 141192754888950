import { Component, inject, Input } from '@angular/core';

import { ControlContainer } from '@angular/forms';
import { FormControlSubscriptComponent } from '@uis-common/inputs/infrastrucure/form-control-subscript/form-control-subscript.component';
import { firstUisValidationErrorMessage } from '@uis-core/validators/uis-validators-helpers';

@Component({
  selector: 'uis-control-error-container',
  standalone: true,
  imports: [FormControlSubscriptComponent],
  templateUrl: './control-error-container.component.html',
  styleUrl: './control-error-container.component.scss',
})
export class ControlErrorContainerComponent {
  control = inject(ControlContainer);
  @Input()
  hint: string | null | undefined;

  get errorText() {
    return this.control.control?.touched
      ? firstUisValidationErrorMessage(this.control.control)
      : '';
  }
}
