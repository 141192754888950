<div class="subscript__container">
  @if (error && errorVisible) {
    <span
      [@fadeInDownOnEnter]="true"
      class="subscript__text subscript__text_error"
      >{{ error }}</span
    >
  }
  @if (hint && !(error && errorVisible)) {
    <span [@fadeInDownOnEnter]="true" class="subscript__text">{{ hint }}</span>
  }
</div>
