import {
  assertInInjectionContext,
  computed,
  inject,
  Injectable,
  Signal,
} from '@angular/core';
import {
  PermissionTarget,
  PermissionTargetSpecifier,
  PermissionType,
  UisTrait,
} from '@uis-core/enums/permissions';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RoleDataEP } from '@uis-core/enums/endpoints';
import {
  buildPermission,
  PERMISSION_SEPARATOR,
  PERMISSIONS,
} from '@uis-services/role-data/permissions';
import {
  Permission,
  PermissionChecker,
  PermissionObject,
  RoleData,
  UisTraitChecker,
} from '@uis-services/role-data/role-data.types';
import { DeepMutable, DeepPartial } from '@uis-types/utility';
import { UserService } from '@uis-services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class RoleDataService {
  private readonly http = inject(HttpClient);
  private readonly userService = inject(UserService);

  /* Permission checker built from permission structure enums keys
   * to be able to check user permission with IDE suggestions like
   * this.permissionService.can.Read.User.Own(): boolean and
   * this.permissionService.can.Read.Permissions.All(): boolean
   * each end value is a Signal<boolean>
   *  */
  public readonly can: PermissionChecker = this.buildPermissionChecker();

  /* Trait checker built from trait enum keys
   * to be able to check user traits with IDE suggestions like
   * this.permissionService.hasTrait.CanBeClassCurator(): boolean
   * each end value is a Signal<boolean>
   *  */
  public readonly hasTrait: UisTraitChecker = this.buildTraitChecker();

  public readonly userRolesData$ = this.userService.me$.pipe(
    switchMap((user) => this.getUserRolesData(user?.id)),
    shareReplay(1),
  );

  public readonly userRolesData = toSignal(this.userRolesData$, {
    initialValue: new RoleData(),
  });

  public readonly userPermissions: Signal<Set<Permission>> = computed(
    () => this.userRolesData().permissions,
  );
  public readonly userTraits: Signal<Set<UisTrait>> = computed(
    () => this.userRolesData().traits,
  );

  private buildPermissionChecker() {
    assertInInjectionContext(this.buildPermissionChecker);
    let can = {} as DeepPartial<DeepMutable<PermissionChecker>>;
    (Object.keys(PermissionType) as (keyof typeof PermissionType)[]).forEach(
      (typeKey) => {
        can[typeKey] = {};
        (
          Object.keys(PermissionTarget) as (keyof typeof PermissionTarget)[]
        ).forEach((targetKey) => {
          can[typeKey]![targetKey] = {};
          (
            Object.keys(
              PermissionTargetSpecifier,
            ) as (keyof typeof PermissionTargetSpecifier)[]
          ).forEach((specifierKey) => {
            can[typeKey]![targetKey]![specifierKey] = computed(() =>
              this.userPermissions().has(
                buildPermission(
                  PermissionType[typeKey],
                  PermissionTarget[targetKey],
                  PermissionTargetSpecifier[specifierKey],
                ),
              ),
            );
          });
        });
      },
    );
    return can as PermissionChecker;
  }

  private buildTraitChecker(): UisTraitChecker {
    assertInInjectionContext(this.buildTraitChecker);
    let traitChecker: Partial<DeepMutable<UisTraitChecker>> = {};
    Object.entries(UisTrait).forEach(
      ([traitKey, traitValue]) =>
        (traitChecker[traitKey as keyof typeof UisTrait] = computed(() =>
          this.userTraits().has(traitValue),
        )),
    );

    return traitChecker as UisTraitChecker;
  }

  public getExistingPermissions() {
    return this.http.get<Permission[]>(RoleDataEP.Permissions());
  }

  public getExistingTraits(userPermissions?: Set<Permission>) {
    return userPermissions?.has(PERMISSIONS.Read.Trait.All)
      ? this.http.get<Permission[]>(RoleDataEP.Traits())
      : of([]);
  }

  public getUserRolesData(userId?: string): Observable<RoleData> {
    return userId
      ? this.http
          .get<RoleData>(RoleDataEP.User(userId))
          .pipe(map(this.initUserRoleData))
      : of(new RoleData());
  }

  private readonly initUserRoleData: (roleData: RoleData) => RoleData = (
    roleData: RoleData,
  ) => {
    const permissions = new Set(roleData.permissions);
    permissions.forEach((permission) => {
      if (this.containsSpecifier(permission, PermissionTargetSpecifier.All)) {
        permissions.add(this.stripSpecifier(permission));
      }
    });

    return {
      permissions,
      traits: new Set(roleData.traits),
    };
  };

  private permissionAsObject(permission: Permission): PermissionObject {
    const destructuredPermission = permission.split(PERMISSION_SEPARATOR);
    return {
      type: destructuredPermission[0] as PermissionType,
      target: destructuredPermission[1] as PermissionTarget,
      specifier: destructuredPermission[2] as PermissionTargetSpecifier,
    };
  }

  private permissionAsString(permissionObject: PermissionObject): Permission {
    return buildPermission(
      permissionObject.type,
      permissionObject.target,
      permissionObject.specifier,
    );
  }

  private containsSpecifier(
    permission: Permission,
    specifier?: PermissionTargetSpecifier,
  ): boolean {
    const permissionObject = this.permissionAsObject(permission);
    return specifier
      ? permissionObject.specifier === specifier
      : !!permissionObject.specifier;
  }

  private stripSpecifier(permission: Permission): Permission {
    if (this.containsSpecifier(permission)) {
      const permissionAsObject = this.permissionAsObject(permission);
      return buildPermission(
        permissionAsObject.type,
        permissionAsObject.target,
      );
    } else {
      return permission;
    }
  }

  public hasPermissions(
    permissionsToCheck?: Permission | Permission[] | readonly Permission[],
    permissionsToCheckFrom = this.userPermissions(),
  ): boolean {
    if (!permissionsToCheck) {
      return true;
    }

    const testPermissions: Permission[] = Array.isArray(permissionsToCheck)
      ? permissionsToCheck
      : ([permissionsToCheck] as Permission[]);

    if (!testPermissions.length) {
      return true;
    }

    return testPermissions.every((permission) =>
      permissionsToCheckFrom.has(permission),
    );
  }

  public hasTraits(
    traitsToCheck?: UisTrait | UisTrait[] | readonly UisTrait[],
    traitsToCheckFrom = this.userTraits(),
  ): boolean {
    if (!traitsToCheck) {
      return true;
    }

    const testTraits: UisTrait[] = Array.isArray(traitsToCheck)
      ? traitsToCheck
      : ([traitsToCheck] as UisTrait[]);

    if (!testTraits.length) {
      return true;
    }

    return testTraits.every((permission) => traitsToCheckFrom.has(permission));
  }
}
