<div class="file-icon">
  <img
    class="file-icon__base"
    src="/assets/icons/files/file-base.svg"
    alt="file"
  />
  <span class="file-icon__extension file-icon__extension_{{ colorClass() }}">{{
    text()
  }}</span>
</div>
