import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  forwardRef,
  inject,
  Input,
  signal,
  Signal,
} from '@angular/core';

import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {
  KendoDataQuery,
  KendoFilter,
} from '@uis-core/classes/kendo-data-query';
import { KendoFilterOperator } from '@uis-enums/kendo';
import { ENTER } from '@angular/cdk/keycodes';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { SearchResult } from '@uis-models/contract/search';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { noop } from '@uis-core/helpers/utils';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { combineLatest } from 'rxjs';
import { get, isEqual } from 'lodash-es';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { DisableControlDirective } from '@uis-directives/disable-control/disable-control.directive';

@Component({
  selector: 'uis-chip-list-autocomplete',
  standalone: true,
  imports: [
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    DisableControlDirective,
  ],
  templateUrl: './chip-list-autocomplete.component.html',
  styleUrls: ['./chip-list-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ChipListAutocompleteComponent),
    },
  ],
})
export class ChipListAutocompleteComponent implements ControlValueAccessor {
  @Input({ required: true, alias: 'searchCall' }) set searchCallInput(
    searchCall: SearchCall,
  ) {
    this.searchCall.set(searchCall);
  }

  @Input({ required: true, alias: 'searchBy' }) set searchByInput(key: string) {
    this.searchBy.set(key);
  }

  @Input({ alias: 'labelBy' }) set labelByInput(key: string) {
    this.labelBy.set(key);
  }

  @Input({ alias: 'equalBy' }) set equalByInput(key: string) {
    this.equalBy.set(key);
  }

  @Input({ alias: 'valueBy' }) set valueByInput(key: string) {
    this.valueBy.set(key);
  }

  @Input({ alias: 'additionalFilters' }) set additionalFiltersInput(
    filters: KendoFilter[],
  ) {
    this.additionalFilters.set(filters);
  }

  @Input({ alias: 'canDelete' }) set canDeleteInput(
    canDelete: CanChipBeDeletedFn,
  ) {
    this.canDelete.set(canDelete);
  }

  @Input({ alias: 'placeholder' }) set placeholderInput(
    placeholder: string | null | undefined,
  ) {
    this.placeholder.set(placeholder);
  }

  @Input({ alias: 'deleteChipTooltip' }) set deleteChipTooltipInput(
    deleteChipTooltip: string | null | undefined,
  ) {
    this.deleteChipTooltip.set(deleteChipTooltip ?? '');
  }

  // @Inputs
  private readonly searchCall = signal<SearchCall | null>(null);
  private readonly searchBy = signal<string | null>(null);
  private readonly labelBy = signal<string | null>(null);
  private readonly equalBy = signal<string | null>(null);
  private readonly valueBy = signal<string | null>(null);
  private readonly additionalFilters = signal<KendoFilter[]>([]);
  private readonly canDelete = signal<CanChipBeDeletedFn | null>(null);
  protected readonly placeholder = signal<string | null | undefined>(null);
  protected readonly deleteChipTooltip = signal<string>('');

  protected readonly nameQuery$ = new BehaviorSubject<string>('');
  protected readonly nameQuery = toSignal(
    this.nameQuery$.pipe(distinctUntilChanged(), debounceTime(300)),
    { initialValue: '' },
  );
  protected readonly kendoQuery = computed(() => {
    const searchBy = this.searchBy();
    if (!searchBy) {
      return null;
    }

    const textQuery = this.nameQuery();
    const additionalFilters = this.additionalFilters();
    const kendoQuery = new KendoDataQuery(0, 0);

    if (textQuery) {
      kendoQuery.pushFilters({
        field: searchBy,
        operator: KendoFilterOperator.Contains,
        value: textQuery,
      });
    }

    if (additionalFilters.length) {
      kendoQuery.pushFilters(additionalFilters);
    }

    return kendoQuery;
  });

  protected readonly searchResult = toSignal(
    combineLatest([
      toObservable(this.kendoQuery),
      toObservable(this.searchCall),
      toObservable(this.equalBy),
    ]).pipe(
      switchMap(([kendoQuery, searchCall, equalBy]) => {
        return searchCall && kendoQuery
          ? of(null).pipe(
              tap(() => this.isLoading.set(true)),
              switchMap(() =>
                searchCall(kendoQuery).pipe(
                  tap((res) => {
                    this.value.update((value) =>
                      value.map((valueItem) => {
                        const existingItem = res.data.find((searchItem) =>
                          equalBy
                            ? get(searchItem, equalBy) ===
                              get(valueItem, equalBy)
                            : isEqual(searchItem, valueItem),
                        );
                        return existingItem ?? valueItem;
                      }),
                    );
                  }),
                ),
              ),
              catchError(() => of(new SearchResult())),
              finalize(() => this.isLoading.set(false)),
            )
          : of(new SearchResult());
      }),
    ),
  );
  protected readonly options: Signal<UisChipListAutocompleteOption[]> =
    computed(() => {
      const searchResultData = this.searchResult()?.data;
      if (!searchResultData) {
        return [];
      }
      const valueBy = this.valueBy();
      const labelBy = this.labelBy();
      const canBeDeletedFn = this.canDelete();
      const value = this.value();
      return searchResultData.map((item) =>
        this.toOption(item, {
          labelBy,
          valueBy,
          disabled: valueBy
            ? !!value.find(
                (existingValue) =>
                  (existingValue as any)[valueBy] === item[valueBy],
              )
            : value.includes(item),
          canBeDeletedFn,
        }),
      );
    });
  protected readonly chips = computed(() => {
    const valueBy = this.valueBy();
    const labelBy = this.labelBy();
    const canBeDeletedFn = this.canDelete();
    const value = this.value();
    return value.map((item) =>
      this.toOption(item, { labelBy, valueBy, canBeDeletedFn }),
    );
  });

  protected readonly separatorKeysCodes: number[] = [ENTER];

  protected readonly value = signal<unknown[]>([]);
  protected readonly isDisabled = signal(false);
  protected readonly isLoading = signal(false);

  private onTouched = noop;
  private onChange = noop;

  // Needed since mat inputs strictly relies on actual
  // form control to be provided to be able to trigger error state
  protected readonly mockFormControl = new FormControl();

  // Source of error state
  protected readonly parentUisFormField = inject(FormFieldComponent, {
    optional: true,
  });

  // Checks the error state on each ngDoCheck run
  private readonly elementRef = inject(ElementRef<HTMLElement>);
  protected readonly errorStateMatcher: ErrorStateMatcher = {
    isErrorState: () =>
      !!this.parentUisFormField?.isErrorVisible() ||
      (this.elementRef.nativeElement.classList.contains('ng-invalid') &&
        this.elementRef.nativeElement.classList.contains('ng-touched')),
  };

  // Trigger ngDoCheck inside material input
  protected readonly cd = inject(ChangeDetectorRef);
  protected readonly checkErrorStateEffect = toSignal(
    (this.parentUisFormField?.isErrorVisible$ ?? of<any>(null)).pipe(
      tap(() => this.cd.markForCheck()),
    ),
  );

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled.set(isDisabled);
  }

  writeValue(value: any) {
    const arrayValue = Array.isArray(value) ? value : [value];
    this.value.set(arrayValue);
  }

  updateControlValue() {
    this.onChange(this.value());
  }

  private toOption(
    item: any,
    params: {
      labelBy: string | null;
      valueBy: string | null;
      disabled?: boolean;
      canBeDeletedFn?: CanChipBeDeletedFn | null;
    },
  ): UisChipListAutocompleteOption {
    if (!item) {
      return {
        value: item,
        label: item,
        disabled: true,
        canBeDeleted: false,
      };
    }
    return {
      value: params.valueBy ? get(item, params.valueBy) : item,
      label: params.labelBy ? get(item, params.labelBy) : item,
      disabled: !!params.disabled,
      canBeDeleted: params.canBeDeletedFn ? params.canBeDeletedFn(item) : true,
    };
  }

  addItem(item: unknown) {
    this.value.update((oldValue) => [...oldValue, item]);
    this.updateControlValue();
    this.onTouched();
  }

  deleteItem(option: UisChipListAutocompleteOption) {
    if (!option.canBeDeleted) {
      return;
    }
    this.value.update((oldValue) =>
      oldValue.filter((valueItem) => valueItem !== option.value),
    );
    this.updateControlValue();
    this.onTouched();
  }

  onAutocompleteOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.addItem(event.option.value);
  }

  onTextSearchChange(event: any) {
    this.nameQuery$.next(event.target.value);
  }

  onInputFocusOut() {
    this.onTouched();
  }
}

export type SearchCall = (query: KendoDataQuery) => Observable<SearchResult>;
export type CanChipBeDeletedFn<T = any> = (item: T) => boolean;

export interface UisChipListAutocompleteOption<T = unknown> {
  value: T;
  label: string;
  disabled?: boolean;
  canBeDeleted?: boolean;
}
