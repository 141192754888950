import {
  Directive,
  effect,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  signal,
  WritableSignal,
} from '@angular/core';
import { fromEvent, tap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { copyTextToBuffer } from '@uis-core/helpers/utils';
import { MessageService } from '@uis-services/message/message.service';

@Directive({
  selector: '[uisClickToCopy]',
  standalone: true,
})
export class ClickToCopyDirective implements OnDestroy {
  private readonly elementRef = inject(ElementRef<HTMLElement>);
  private readonly message = inject(MessageService);

  @Input({ alias: 'uisClickToCopy' }) set uisClickToCopyInput(
    content: string | undefined | null,
  ) {
    this.uisClickToCopy.set(content);
  }

  private readonly uisClickToCopy: WritableSignal<string | null | undefined> =
    signal(null);

  @Input({ alias: 'uisClickToCopySuccess' }) set uisClickToCopySuccessInput(
    content: string | undefined | null,
  ) {
    this.uisClickToCopySuccess.set(content);
  }

  private readonly uisClickToCopySuccess: WritableSignal<
    string | null | undefined
  > = signal(null);

  private readonly uisClickToCopyEffect = effect(() => {
    const valueToCopy = this.uisClickToCopy();
    this.elementRef.nativeElement.style.cursor = valueToCopy
      ? 'pointer'
      : 'auto';
  });
  private readonly elementClickSubscribe = toSignal(
    fromEvent(this.elementRef.nativeElement, 'click').pipe(
      tap(() => {
        const valueToCopy = this.uisClickToCopy();
        if (valueToCopy) {
          copyTextToBuffer(
            valueToCopy,
            this.message,
            this.uisClickToCopySuccess() ?? 'Скопійовано',
          );
        }
      }),
    ),
  );

  ngOnDestroy() {
    this.elementRef.nativeElement.style.cursor = 'auto';
  }
}
