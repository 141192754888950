import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {
  MessageService,
  MessageType,
} from '@uis-services/message/message.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements OnDestroy {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: MessageData,
    public messageService: MessageService,
    public elementRef: ElementRef,
  ) {
    // A way to pass value from JS to CSS
    (elementRef.nativeElement as HTMLElement).style.setProperty(
      '--duration',
      this.data.duration + 'ms',
    );
  }

  dismiss(event: MouseEvent): void {
    if (event.ctrlKey) {
      this.messageService.resetQueue();
    } else {
      this.messageService.currSnackbar$.value?.dismiss();
    }
  }

  ngOnDestroy() {
    if (
      this.data.queuePosition ===
      this.messageService.messageCallsQueueLength$.value
    ) {
      this.messageService.messageCallsQueueLength$.next(0);
      this.messageService.currSnackbar$.next(null);
    }
  }
}

export interface MessageData {
  title: string;
  message?: string;
  type: MessageType;
  queuePosition: number;
  duration: number;
}
