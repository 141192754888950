export enum UserSex {
  Male = 'male',
  Female = 'female',
}

export enum EmployeeWorkload {
  Moonlighting = 'moonlighting',
  Quarter = 'quarter',
  Half = 'half',
  ThreeQuarters = 'three-quarters',
  Full = 'full',
}

export const EmployeeWorkloadNameText: Record<EmployeeWorkload, string> = {
  [EmployeeWorkload.Moonlighting]: 'Часткова зайнятість',
  [EmployeeWorkload.Quarter]: '1/4 Ставки',
  [EmployeeWorkload.Half]: '1/2 Ставки',
  [EmployeeWorkload.ThreeQuarters]: '3/4 Ставки',
  [EmployeeWorkload.Full]: 'Повна ставка',
};
