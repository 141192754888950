export enum KendoFilterLogic {
  Or = 'or',
  And = 'and',
}

export enum KendoFilterOperator {
  Contains = 'contains',
  DoesNotContain = 'doesnotcontain',
  Eq = 'eq',
  Neq = 'neq',
  IsNull = 'isnull',
  IsNotNull = 'isnotnull',
  Gte = 'gte',
  Gt = 'gt',
  Lte = 'lte',
  Lt = 'lt',
  IsEmpty = 'isempty',
  IsNotEmpty = 'isnotempty',
  StartsWith = 'startswith',
  DoesNotStartWith = 'doesnotstartwith',
  EndsWith = 'endswith',
  DoesNotEndWith = 'doesnotendwith',
  In = 'in',
  NotIn = 'notin',
  InAsObject = 'inasobject',
  NotInAsObject = 'notinasobject',
  AnyEq = 'anyeq',
}

export enum KendoSortDir {
  Asc = 'asc',
  Desc = 'desc',
}
