import { Pipe, PipeTransform } from '@angular/core';
import { bytesToDisplayString } from '@uis-core/helpers/utils';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(value: number | null | undefined): string {
    return bytesToDisplayString(value ?? 0);
  }
}
