import { UisValidatorFn } from 'src/app/core/validators/uis-validator';
import {
  createCompositeUisValidator,
  createUisValidator,
  isValue,
  StructuredUisValidatorError,
} from 'src/app/core/validators/uis-validators-helpers';
import { AbstractControl, FormGroup } from '@angular/forms';
import { merge, Observable, Subscription, takeUntil } from 'rxjs';
import {
  UisRegExp,
  UisRegExpErrorTexts,
  UisRegExpRegistry,
} from '@uis-core/constants/uis-reg-exp';
import { DatePipe } from '@angular/common';
import { UisFileType } from '@uis-enums/file-types';
import { getFileTypeError } from '@uis-common/inputs/file-inputs/file-type-settings';
import { UisFile } from '@uis-models/contract/uis-file';
import { assertInInjectionContext, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export class UisValidators {
  static required(): UisValidatorFn {
    const isError = (control: AbstractControl) => !isValue(control.value);
    return createUisValidator('required', isError, "Обов'язкове поле");
  }

  static requiredIf(predicate: (rootControl: AbstractControl) => boolean) {
    return this.conditional(predicate, this.required());
  }

  static requiredIfControlCondition(
    controlPath: string,
    predicate: (rootControl: AbstractControl) => boolean,
    takeUntilObs?: Observable<any> | DestroyRef,
  ) {
    return UisValidators.concat(
      this.listenControlChanges(controlPath, takeUntilObs),
      this.conditional(predicate, this.required()),
    );
  }

  static requiredIfControlHasValue(
    controlPath: string,
    takeUntilObs?: Observable<any> | DestroyRef,
  ) {
    return UisValidators.concat(
      this.listenControlChanges(controlPath, takeUntilObs),
      this.conditional(
        (control) => isValue(control.root?.get(controlPath)?.value),
        this.required(),
      ),
    );
  }

  static atLeastOneControlValid(
    validator: UisValidatorFn,
    message: string,
    controlNames: string[] = [],
  ) {
    const isError = (group: AbstractControl) => {
      const groupAsFormGroup = group as FormGroup;

      if (!controlNames.length) {
        controlNames = Object.keys(groupAsFormGroup.controls);
      }

      return (
        groupAsFormGroup &&
        groupAsFormGroup.controls &&
        controlNames.every((k) => validator(groupAsFormGroup.controls[k]))
      );
    };

    return createUisValidator('atLeastOneControlValid', isError, message);
  }

  static listenControlChanges(
    controlPath: string,
    takeUntilObs?: Observable<any> | DestroyRef,
  ): UisValidatorFn {
    if (!takeUntilObs) {
      assertInInjectionContext(UisValidators.listenControlChanges);
      takeUntilObs = inject(DestroyRef);
    }

    let comparedControlListener: Subscription;
    let initialized = false;

    return (control: AbstractControl) => {
      if (initialized) {
        return null;
      }
      const parent = control.root;
      if (!parent) {
        return null;
      }
      const comparedControl = parent.get(controlPath);
      if (!comparedControlListener && comparedControl) {
        comparedControlListener = merge(
          comparedControl.valueChanges,
          comparedControl.statusChanges,
        )
          .pipe(
            takeUntilObs instanceof DestroyRef
              ? takeUntilDestroyed(takeUntilObs)
              : takeUntil(takeUntilObs!),
          )
          .subscribe(() => {
            control.updateValueAndValidity();
          });
        initialized = true;
      }
      if (initialized && !comparedControl) {
        console.error(`No control found by path ${controlPath}`);
      }
      return null;
    };
  }

  static conditional(
    predicate: (rootControl: AbstractControl) => boolean,
    validator: UisValidatorFn,
  ): UisValidatorFn {
    return (control) => {
      if (!control || !control.root) {
        return null;
      }

      const isPredicate = predicate(control.root);
      const hasValidator = control.hasValidator(validator);

      if (isPredicate) {
        if (!hasValidator) {
          control.addValidators(validator);
          control.updateValueAndValidity();
        }
      } else {
        if (hasValidator) {
          control.removeValidators(validator);
          control.updateValueAndValidity();
        }
      }
      return null;
    };
  }

  static requiredTrue(errorMessage?: string): UisValidatorFn {
    const isError = (control: AbstractControl) => !(control.value === true);
    return createUisValidator(
      'requiredTrue',
      isError,
      errorMessage ?? "Обов'язкове поле",
    );
  }

  static pattern(regex: UisRegExp, errorMessage?: string): UisValidatorFn {
    const isError = (control: AbstractControl) =>
      isValue(control.value) &&
      (typeof control.value !== 'string' ||
        !UisRegExpRegistry[regex].test(control.value));
    const defaultErrorMessage = 'Не правильний формат значення';

    return createUisValidator(
      'pattern',
      isError,
      errorMessage ?? UisRegExpErrorTexts[regex] ?? defaultErrorMessage,
      { expectedPatternName: regex, expectedPattern: UisRegExpRegistry[regex] },
    );
  }

  static email(): UisValidatorFn {
    return UisValidators.pattern(UisRegExp.Email);
  }

  static url(): UisValidatorFn {
    return UisValidators.pattern(UisRegExp.URL);
  }

  static password(): UisValidatorFn {
    return createCompositeUisValidator(
      'password',
      UisValidators.pattern(UisRegExp.ContainsLatinLowercase),
      UisValidators.pattern(UisRegExp.ContainsLatinUppercase),
      UisValidators.pattern(UisRegExp.ContainsNumber),
      UisValidators.pattern(UisRegExp.ContainsSpecial),
      UisValidators.pattern(UisRegExp.NotContainsCyrillic),
      UisValidators.pattern(UisRegExp.NotContainsWhitespace),
      UisValidators.minMaxLength(8, 100),
    );
  }

  static personNameEntry(): UisValidatorFn {
    return createCompositeUisValidator(
      'personNameEntry',
      UisValidators.pattern(UisRegExp.NotContainsDigits),
      UisValidators.pattern(UisRegExp.NotContainsLatin),
      UisValidators.pattern(UisRegExp.ContainsCyrillic),
      UisValidators.pattern(UisRegExp.CanContainSpecialCharactersForPersonName),
      UisValidators.pattern(UisRegExp.SpecialSymbolsShouldNotRepeat),
      UisValidators.pattern(UisRegExp.isTrimmed),
      UisValidators.minMaxLength(2, 64),
    );
  }

  static internationalPhoneNumber(): UisValidatorFn {
    return UisValidators.concat(
      UisValidators.pattern(UisRegExp.ContainsOnlyDigits),
      UisValidators.pattern(UisRegExp.isTrimmed),
      UisValidators.minMaxLength(7, 15),
    );
  }

  static minLength(minLength: number, errorMessage?: string): UisValidatorFn {
    const isError = (control: AbstractControl) =>
      isValue(control.value) &&
      (Array.isArray(control.value) || typeof control.value === 'string') &&
      control.value.length < minLength;

    const defaultErrorMessage = (control: AbstractControl) => {
      const value = control.value;

      if (Array.isArray(value)) {
        return `Мінімум ${minLength} елементів`;
      }

      if (typeof control.value === 'string') {
        return `Не менше ${minLength} символів`;
      }

      return 'Помилка';
    };

    return createUisValidator(
      'minLength',
      isError,
      errorMessage ?? defaultErrorMessage,
    );
  }

  static maxLength(maxLength: number, errorMessage?: string): UisValidatorFn {
    const isError = (control: AbstractControl) =>
      isValue(control.value) &&
      (Array.isArray(control.value) || typeof control.value === 'string') &&
      control.value.length > maxLength;

    const defaultErrorMessage = (control: AbstractControl) => {
      const value = control.value;

      if (Array.isArray(value)) {
        return `Максимум ${maxLength} елементів`;
      }

      if (typeof control.value === 'string') {
        return `Максимум ${maxLength} символів`;
      }

      return 'Помилка';
    };

    return createUisValidator(
      'maxLength',
      isError,
      errorMessage ?? defaultErrorMessage,
    );
  }

  static minMaxLength(min: number, max: number) {
    return UisValidators.concat(
      UisValidators.minLength(min),
      UisValidators.maxLength(max),
    );
  }

  static sameAs(
    controlPath: string,
    errorMessage: string,
    takeUntilObs?: Observable<any> | DestroyRef,
  ): UisValidatorFn {
    const isError = (
      thisControl: AbstractControl,
      comparedControl: AbstractControl,
    ) => thisControl.value !== comparedControl.value;
    return UisValidators.compare(
      controlPath,
      isError,
      errorMessage,
      takeUntilObs,
    );
  }

  static compare(
    controlPath: string,
    isError: (
      thisControl: AbstractControl,
      comparedControl: AbstractControl,
    ) => boolean,
    errorMessage: string,
    takeUntilObs?: Observable<any> | DestroyRef,
  ): UisValidatorFn {
    const _isError = (thisControl: AbstractControl) => {
      const comparedControl = thisControl.root?.get(controlPath);
      if (!comparedControl) {
        return true;
      }
      return !!comparedControl && isError(thisControl, comparedControl);
    };

    return UisValidators.concat(
      UisValidators.listenControlChanges(controlPath, takeUntilObs),
      createUisValidator('compare', _isError, errorMessage),
    );
  }

  static concat(...uisValidators: UisValidatorFn[]): UisValidatorFn {
    return (control) => {
      let error: StructuredUisValidatorError | null = null;
      for (const uisValidator of uisValidators) {
        error = uisValidator(control);
        if (error) {
          return error;
        }
      }
      return error;
    };
  }

  static earlierThan(
    date: Date,
    errorMessage?: string,
    datePipe?: DatePipe,
  ): UisValidatorFn {
    const isError = (control: AbstractControl) => {
      if (!isValue(control.value)) {
        return false;
      }

      return new Date(control.value).getTime() >= date.getTime();
    };

    const defaultErrorMessage = datePipe
      ? `Має бути раніше ніж ${datePipe.transform(date, 'dd/MM/yyyy')}`
      : 'Має бути раніше';

    return createUisValidator(
      'earlierThan',
      isError,
      errorMessage ?? defaultErrorMessage,
    );
  }

  static laterThan(
    date: Date,
    errorMessage?: string,
    datePipe?: DatePipe,
  ): UisValidatorFn {
    const isError = (control: AbstractControl) => {
      if (!isValue(control.value)) {
        return false;
      }

      return new Date(control.value).getTime() <= date.getTime();
    };

    const defaultErrorMessage = datePipe
      ? `Має бути пізніше ніж ${datePipe.transform(date, 'dd/MM/yyyy')}`
      : 'Має бути пізніше';

    return createUisValidator(
      'laterThan',
      isError,
      errorMessage ?? defaultErrorMessage,
    );
  }

  static dateInTheFuture(errorMessage?: string) {
    return UisValidators.laterThan(
      new Date(),
      errorMessage ?? 'Має бути у майбутньому',
    );
  }

  static dateInThePast(errorMessage?: string) {
    return UisValidators.earlierThan(
      new Date(),
      errorMessage ?? 'Має бути у минулому',
    );
  }

  static dateRange(
    laterThan: Date,
    earlierThan: Date,
    tooEarlyErrorMessage?: string,
    tooLateErrorMessage?: string,
    datePipe?: DatePipe,
  ) {
    return UisValidators.concat(
      UisValidators.laterThan(laterThan, tooEarlyErrorMessage, datePipe),
      UisValidators.earlierThan(earlierThan, tooLateErrorMessage, datePipe),
    );
  }

  static validateFileType(fileType: UisFileType) {
    const isError = (control: AbstractControl<UisFile[]>) => {
      return (
        isValue(control.value) && !!getFileTypeError(control.value, fileType)
      );
    };

    return createUisValidator(
      'validateFileType',
      isError,
      (control) => getFileTypeError(control.value, fileType),
      { fileType },
    );
  }
}
