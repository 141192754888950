<div
  class="file-input"
  [class.file-input_error]="hasUploadError()"
  [class.file-input_focused]="focused()"
  [class.file-input_disabled]="disabled()"
>
  <div class="file-input__main" (click)="htmlInput.click()">
    @if (isDragging()) {
      <div class="file-input__main__drop-overlay">
        @if (isDropAllowed()) {
          <span class="file-input__main__drop-overlay__text">Кидайте сюди</span>
        }
        @if (!isDropAllowed()) {
          <span
            class="file-input__main__drop-overlay__text file-input__main__drop-overlay__text_blocked"
            >Додано максимальну кількість файлів</span
          >
        }
      </div>
    }
    Перетягніть сюди файли або виберіть <br />
    <span class="file-input__main__highlight"
      ><mat-icon inline>upload_file</mat-icon> з вашого комп'ютера</span
    >
    <span class="file-input__main__limitations">
      {{ fileLimitationsString() }}
    </span>
  </div>
  @if (internalValue().length) {
    <ul class="file-input__file-list">
      @for (
        file of internalValue();
        track trackByFileName(index, file);
        let index = $index
      ) {
        <li class="file-input__file-list__item">
          @if (file.changes$ | async; as fileState) {
            <div
              class="file-input__file-list__item__info-container"
              (click)="onFileClick(fileState)"
            >
              <uis-file-icon
                class="file-input__file-list__item__icon"
                [file]="fileState"
              />
              <div class="file-input__file-list__item__text-container">
                <span class="file-input__file-list__item__name">{{
                  fileState.fileName
                }}</span>
                <span class="file-input__file-list__item__size">{{
                  bytesToDisplayString(fileState.fileSize)
                }}</span>
              </div>
            </div>
            @if (fileState.isUploading) {
              <mat-progress-spinner mode="indeterminate" diameter="16" />
            }
            @if (fileState.uploadingError) {
              <mat-icon
                matTooltip="Помилка завантаження файлу"
                class="file-input__file-list__item__error-icon material-symbols-outlined"
                >error
              </mat-icon>
            }
            <button
              mat-icon-button
              [disabled]="disabled()"
              (click)="onDelete(index)"
              matTooltip="Видалити"
              type="button"
            >
              <mat-icon
                inline
                class="file-input__file-list__item__delete-icon material-icons-outlined"
                >delete
              </mat-icon>
            </button>
          }
        </li>
      }
    </ul>
  }
  <div class="file-input__border-layer"></div>
</div>
<input
  hidden
  type="file"
  [disabled]="disabled()"
  [multiple]="fileTypeSettings().maxFiles !== 1"
  [accept]="htmlInputAcceptString()"
  (change)="onHtmlInputChange($event)"
  #htmlInput
/>
