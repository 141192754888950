import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchResult } from '@uis-models/contract/search';
import { map, Observable } from 'rxjs';
import { PaymentsEp } from '@uis-enums/endpoints';
import {
  LiqPayRequestData,
  LiqPayResponseData,
  LiqPayResponsePaymentData,
  Payment,
} from '@uis-models/contract/payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private readonly http = inject(HttpClient);

  getPaymentData(): Observable<Payment> {
    return this.http
      .get<SearchResult<Payment>>(PaymentsEp.paymentData())
      .pipe(map((response) => response.data[0]));
  }

  pay(payload: LiqPayRequestData): Observable<LiqPayResponsePaymentData> {
    return this.http
      .post<LiqPayResponseData>(PaymentsEp.pay(), payload)
      .pipe(map((response) => response.item2));
  }
}
