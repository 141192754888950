import { UisValidators } from 'src/app/core/validators/validators';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { UisValidatorFn } from '@uis-core/validators/uis-validator';
import { UisInputMaskValidationErrorMessage } from '@uis-core/validators/uis-input-mask';

export type UisValidatorsKey = keyof Omit<typeof UisValidators, 'prototype'>;
type UisValidatorErrorDetails = { [key: string]: any };

export type StructuredUisValidatorError = {
  [key in UisValidatorsKey]?: StructuredUisValidatorErrorContent;
};

export type StructuredUisValidatorErrorContent = {
  message: string;
  details?: UisValidatorErrorDetails;
};

export function createUisValidator(
  validatorName: UisValidatorsKey,
  isError: (control: AbstractControl) => boolean,
  message: string | ((control: AbstractControl) => string),
  errorDetails?:
    | UisValidatorErrorDetails
    | ((controls: AbstractControl) => UisValidatorErrorDetails),
): UisValidatorFn {
  return (control: AbstractControl) => {
    if (isError(control)) {
      const messageString =
        typeof message === 'function' ? message(control) : message;
      const errorDetailsObj =
        typeof errorDetails === 'function'
          ? errorDetails(control)
          : errorDetails;
      return getStructuredUisValidatorError(
        validatorName,
        messageString,
        errorDetailsObj,
      );
    }
    return null;
  };
}

export function createCompositeUisValidator(
  validatorName: UisValidatorsKey,
  ...uisValidators: UisValidatorFn[]
): UisValidatorFn {
  let firstValidationError: StructuredUisValidatorError | null = null;
  const isError = (control: AbstractControl) => {
    firstValidationError = null;
    for (const uisValidator of uisValidators) {
      firstValidationError = uisValidator(control);
      if (firstValidationError) {
        break;
      }
    }
    return !!firstValidationError;
  };

  const message = () => {
    const errorKey: keyof StructuredUisValidatorError = Object.keys(
      firstValidationError!,
    ).find(
      (key) => (firstValidationError as any)[key].message,
    )! as keyof StructuredUisValidatorError;
    return (firstValidationError as any)[errorKey].message;
  };

  return createUisValidator(validatorName, isError, message, () => ({
    failedValidator: firstValidationError,
  }));
}

function getStructuredUisValidatorError(
  validatorName: UisValidatorsKey,
  message: string,
  additionalErrorInfo?: UisValidatorErrorDetails,
): StructuredUisValidatorError {
  return {
    [validatorName]: {
      message: message ?? 'Помилка',
      details: additionalErrorInfo,
    },
  };
}

export function isValue(value: any): boolean {
  return !(value === null || value === undefined || value?.length === 0);
}

export function hasUisValidator(
  control: AbstractControl | undefined | null,
  validatorName: UisValidatorsKey,
): boolean {
  if (!control) {
    return false;
  }
  return !!control
    .validator?.((validatorName === 'required' ? {} : control) as any)
    ?.hasOwnProperty(validatorName);
}

export function firstUisValidationErrorMessage(
  formControl?: AbstractControl,
): string | undefined {
  if (formControl && formControl.errors) {
    const firstErrorKey = Object.keys(formControl.errors).at(0);
    const firstErrorIsMaskError = firstErrorKey === 'mask';
    const firstError = Object.values(formControl.errors).at(0);
    return firstErrorIsMaskError
      ? (UisInputMaskValidationErrorMessage as any)[firstError.requiredMask]
      : Object.values(formControl.errors).at(0).message;
  } else {
    return undefined;
  }
}

export function uisValidatorErrorMessageByKey(
  formControl?: AbstractControl,
  validatorKey?: UisValidatorsKey | 'mask',
  requiredDetail?: { [key: string]: any },
): string | undefined {
  if (formControl && formControl.errors && validatorKey) {
    const errorByKey: StructuredUisValidatorErrorContent =
      formControl.errors[validatorKey];
    const hasRequiredAdditionalData =
      requiredDetail && errorByKey.details
        ? Object.entries(requiredDetail).every(
            ([requiredKey, requiredValue]) =>
              (<any>errorByKey.details)[requiredKey] === requiredValue,
          )
        : false;

    if (!errorByKey || !hasRequiredAdditionalData) {
      return undefined;
    }

    return validatorKey === 'mask'
      ? (UisInputMaskValidationErrorMessage as any)[
          (<any>errorByKey).requiredMask
        ]
      : errorByKey.message;
  } else {
    return undefined;
  }
}

export interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
}

export interface FormGroupControls {
  [key: string]: AbstractControl;
}

export function getFormValidationErrors(
  controls: FormGroupControls,
): AllValidationErrors[] {
  let errors: AllValidationErrors[] = [];
  Object.keys(controls).forEach((key) => {
    const control = controls[key];
    if (control instanceof FormGroup) {
      errors = errors.concat(getFormValidationErrors(control.controls));
    }
    const controlErrors: ValidationErrors | null = controls[key].errors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach((keyError) => {
        errors.push({
          control_name: key,
          error_name: keyError,
          error_value: controlErrors[keyError],
        });
      });
    }
  });
  return errors;
}
