import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SearchResult } from '@uis-models/contract/search';
import { Observable } from 'rxjs';
import { RolesEP } from '@uis-enums/endpoints';
import { KendoDataQuery } from '@uis-core/classes/kendo-data-query';
import { KendoFilterOperator } from '@uis-enums/kendo';
import { UserRole } from '@uis-models/contract/user';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private readonly http = inject(HttpClient);

  getAllRoles(
    query: KendoDataQuery = new KendoDataQuery(),
  ): Observable<SearchResult<UserRole>> {
    return this.http.get<SearchResult<UserRole>>(RolesEP.All(), {
      params: new HttpParams({ fromObject: query.query as any }),
    });
  }

  getEmployeeRoles(
    query: KendoDataQuery<UserRole> = new KendoDataQuery<UserRole>(),
  ): Observable<SearchResult<UserRole>> {
    query.pushFilters({
      field: 'isStudent',
      operator: KendoFilterOperator.Eq,
      value: false,
    });
    return this.getAllRoles(query);
  }

  getStudentRoles(
    query: KendoDataQuery<UserRole> = new KendoDataQuery<UserRole>(),
  ): Observable<SearchResult<UserRole>> {
    query.pushFilters({
      field: 'isStudent',
      operator: KendoFilterOperator.Eq,
      value: true,
    });
    return this.getAllRoles(query);
  }
}
