import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  concatMap,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  MessageComponent,
  MessageData,
} from '@uis-common/message/message.component';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private matSnackbar = inject(MatSnackBar);

  public messageCallsQueueLength$ = new BehaviorSubject<number>(0);
  currSnackbar$ = new BehaviorSubject<MatSnackBarRef<MessageComponent> | null>(
    null,
  );
  private messageCallsQueue = new Subject<MessageData>();
  private defaultSnackbarLifeMS = 4000;
  private lastText?: string;
  private lastTitle?: string;
  private lastType?: string;
  private queueSubscription?: Subscription;
  private skipCurrent = new Subject<void>();

  constructor() {
    this.initQueueListener();
  }

  initQueueListener(): void {
    this.queueSubscription = this.messageCallsQueue
      .pipe(
        concatMap((data) => {
          const newSnackBar = this._open(data);
          this.currSnackbar$.next(newSnackBar);
          return newSnackBar.afterDismissed().pipe(takeUntil(this.skipCurrent));
        }),
      )
      .subscribe();
  }

  resetQueue(): void {
    this.queueSubscription?.unsubscribe();
    this.messageCallsQueueLength$.next(0);
    this.currSnackbar$.value?.dismiss();
    this.currSnackbar$.next(null);
    this.initQueueListener();
  }

  private _open(data: MessageData) {
    return this.matSnackbar.openFromComponent<MessageComponent, MessageData>(
      MessageComponent,
      {
        data,
        panelClass: ['uis-mat-snack-bar', `uis-mat-snack-bar-${data.type}`],
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: data.duration,
      },
    );
  }

  open(
    title: string,
    message?: string,
    type: MessageType = MessageType.Success,
    duration = this.defaultSnackbarLifeMS,
  ) {
    if (
      this.lastText === message &&
      this.lastTitle === title &&
      this.lastType === type &&
      this.currSnackbar$.value
    ) {
      return;
    }
    this.lastTitle = title;
    this.lastText = message;
    this.lastType = type;
    this.messageCallsQueueLength$.next(this.messageCallsQueueLength$.value + 1);
    this.messageCallsQueue.next({
      title,
      message,
      type,
      queuePosition: this.messageCallsQueueLength$.value,
      duration,
    });
  }

  success(
    title: string,
    message?: string,
    duration = this.defaultSnackbarLifeMS,
  ) {
    this.open(title, message, MessageType.Success, duration);
  }

  warn(title: string, message?: string, duration = this.defaultSnackbarLifeMS) {
    this.open(title, message, MessageType.Warn, duration);
  }

  error(
    title: string,
    message?: string,
    duration = this.defaultSnackbarLifeMS,
  ) {
    this.open(title, message, MessageType.Error, duration);
  }

  info(title: string, message?: string, duration = this.defaultSnackbarLifeMS) {
    this.open(title, message, MessageType.Info, duration);
  }

  invalidForm() {
    const invalidElements = Array.from(
      document.querySelectorAll('.ng-invalid'),
    );
    const firstNonFormElement = invalidElements.find(
      (element) => element.tagName !== 'form',
    );

    firstNonFormElement?.scrollIntoView({
      behavior: 'smooth',
    });

    this.warn("Коректно заповніть всі обов'язкові поля");
  }
}

export enum MessageType {
  Success = 'success',
  Warn = 'warn',
  Error = 'error',
  Info = 'info',
}
