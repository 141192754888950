import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Type,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  UisButtonStyleConfig,
} from 'src/app/content/common/button/button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UIS_DIALOG_DATA } from 'src/app/content/common/dialog-container/dialog-container.component';

@Component({
  selector: 'uis-default-dialog',
  standalone: true,
  imports: [CommonModule, ButtonComponent, MatDialogModule],
  templateUrl: './default-dialog.component.html',
  styleUrls: ['./default-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'sizeClass',
  },
})
export class DefaultDialogComponent {
  data = inject<UisDefaultDialogParams<any>>(UIS_DIALOG_DATA);
  primaryButtonDefaultStyle: UisButtonStyleConfig = { color: 'secondary' };
  secondaryButtonDefaultStyle: UisButtonStyleConfig = {
    color: 'gray',
    fill: 'content',
  };

  constructor() {
    this.data.primaryButton ??= 'confirm';
  }

  get sizeClass() {
    return `dialog_${this.data.size}`;
  }

  protected readonly confirm = confirm;
}

export interface UisDefaultDialogParams<T> {
  image?: string;
  title?: string;
  text?: string;
  rejectText?: string;
  rejectButtonStyle?: UisButtonStyleConfig;
  confirmText?: string;
  confirmButtonStyle?: UisButtonStyleConfig;
  hideDefaultActions?: boolean;
  component?: Type<any>;
  disableClose?: boolean;
  data?: T;
  size?: 'small' | 'medium' | 'large';
  primaryButton?: 'confirm' | 'reject';
}
