import { Directive, ElementRef, inject, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[uisTrim]',
  standalone: true,
  host: {
    '(blur)': 'trim()',
  },
})
export class TrimValueDirective {
  @Input() disableTrim = false;

  ngControl = inject(NgControl, { optional: true });
  elementRef = inject(ElementRef);

  trim() {
    const value = this.elementRef.nativeElement.value;
    const isCheckbox =
      this.elementRef.nativeElement instanceof HTMLInputElement &&
      this.elementRef.nativeElement.type === 'checkbox';

    if (isCheckbox || this.disableTrim || typeof value !== 'string') {
      return;
    }
    const trimmedValue = value.trim();
    if (this.ngControl) {
      this.ngControl!.control!.setValue(trimmedValue);
    } else {
      this.elementRef.nativeElement.value = trimmedValue;
    }
  }
}
