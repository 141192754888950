import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@uis-services/user/user.service';
import { ProfileRoutesConfig } from '@uis-private/profile/profile.routes';

export const visitorOnlyCanActivateGuard: CanActivateFn = () => {
  const userService = inject(UserService);
  const router = inject(Router);
  return userService.me()
    ? router.createUrlTree([ProfileRoutesConfig.absolutePath.profile()])
    : true;
};
