<p class="not-found__text">
  <span
    class="not-found__text__icon"
    [matTooltip]="failedRoute"
    [uisClickToCopy]="failedRoute"
    >{{ randomKaomoji }}</span
  >
  <span class="not-found__text__title"
    >404 <br />
    Тут нічого нема</span
  >
</p>
