import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { PublicRoute } from '@uis-public/public.routes';
import { MessageService } from '@uis-services/message/message.service';

export const requiredQueryParamsCanActivateGuard: (
  requiredQueryParams: string[],
  redirectTo?: string,
) => CanActivateFn = (
  requiredQueryParams: string[],
  redirectTo: string = PublicRoute.SignIn,
) => {
  return (route) => {
    const router = inject(Router);
    const message = inject(MessageService);

    const hasRequiredParams = requiredQueryParams.every((requiredParam) =>
      route.queryParamMap.get(requiredParam),
    );

    if (!hasRequiredParams) {
      message.warn('Посилання не коректне');
    }

    return hasRequiredParams ? true : router.createUrlTree([redirectTo]);
  };
};
