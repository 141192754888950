@if (employeeForm()) {
  <div [formGroup]="employeeForm()!">
    @if (
      !employeeForm()?.controls?.roles?.disabled ||
      !employeeForm()?.controls?.workload?.disabled ||
      can.Read.Subject.All()
    ) {
      <section class="user-form__section">
        <h3 class="user-form__section__title">
          <uis-content-divider>Налаштування працівника</uis-content-divider>
        </h3>
        <div class="uis-form-common__input-grid">
          @if (!employeeForm()?.controls?.roles?.disabled) {
            <uis-form-field label="Ролі">
              <uis-chip-list-autocomplete
                formControlName="roles"
                [searchCall]="getRolesChipListAutocompleteCall"
                searchBy="displayName"
                labelBy="displayName"
                [canDelete]="roleCanBeDeleted"
                [additionalFilters]="getRolesFilters"
                placeholder="Додати роль..."
                deleteChipTooltip="Видалити роль"
              ></uis-chip-list-autocomplete>
            </uis-form-field>
          }
          @if (!employeeForm()?.controls?.workload?.disabled) {
            <uis-form-field label="Навантаження">
              <mat-form-field>
                <mat-select
                  placeholder="Оберіть рівень навантаження"
                  formControlName="workload"
                >
                  @for (
                    workloadOption of workloadOptions;
                    track workloadOption
                  ) {
                    <mat-option [value]="workloadOption">
                      {{ EmployeeWorkloadNameText[workloadOption] }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </uis-form-field>
          }
          @if (!employeeForm()?.controls?.subjects?.disabled) {
            <uis-form-field label="Предмети">
              <uis-chip-list-autocomplete
                formControlName="subjects"
                [searchCall]="getSubjectsChipListAutocompleteCall"
                equalBy="id"
                searchBy="name"
                labelBy="name"
                placeholder="Додати предмет..."
                deleteChipTooltip="Видалити предмет"
              ></uis-chip-list-autocomplete>
            </uis-form-field>
          }
        </div>
      </section>
    }
    <section class="user-form__section">
      <h3 class="user-form__section__title">
        <uis-content-divider
          >Особиста інформація працівника
        </uis-content-divider>
      </h3>
      <div>
        <uis-user-general-info-form
          [generalInfoForm]="$any(employeeForm())"
        ></uis-user-general-info-form>
      </div>
    </section>
  </div>
}
