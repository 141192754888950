import { computed, inject, Injectable, Signal } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  NavigationExtras,
  Route,
  Router,
} from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { defer, filter, map } from 'rxjs';

export const BREADCRUMB_ROUTE_DATA_KEY = 'breadcrumb' as const;
export const BREADCRUMB_DISABLED_ROUTE_DATA_KEY =
  'breadcrumb-disabled' as const;

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private readonly router = inject(Router);

  public readonly breadcrumbs: Signal<Breadcrumb[]> = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        return this.getBreadcrumbs(this.router.routerState.snapshot.root);
      }),
    ),
    { initialValue: [] },
  );

  public readonly lastBreadcrumb: Signal<Breadcrumb | undefined> = computed(
    () => this.breadcrumbs().at(-1),
  );

  private getBreadcrumbs(rootRoute: ActivatedRouteSnapshot) {
    const breadcrumbs: Breadcrumb[] = [];
    let previousRoute: ActivatedRouteSnapshot | null = null;
    let currentRoute: ActivatedRouteSnapshot | null = rootRoute;
    while (currentRoute) {
      let breadcrumbLabel = this.getLabel(currentRoute, previousRoute);
      if (breadcrumbLabel) {
        breadcrumbs.push({
          label: breadcrumbLabel,
          url: this.getUrl(currentRoute),
          disabled: this.getBreadcrumbIsDisabledFromRouteConfig(
            currentRoute.routeConfig,
            currentRoute.data,
          ),
        });
      }
      previousRoute = currentRoute;
      currentRoute = currentRoute.firstChild;
    }
    return breadcrumbs;
  }

  /**
   * Breadcrumb label is constructed from route config
   * either explicitly from the data:{breadcrumb:...}
   * or implicitly from title: string, also accounting for
   * stripping inherited values to prevent label duplication
   */
  private getLabel(
    currentRoute: ActivatedRouteSnapshot,
    previousRoute: ActivatedRouteSnapshot | null,
  ): string | undefined {
    if (!currentRoute.data && !currentRoute.title) {
      return undefined;
    }

    const currentBreadcrumbLabel = this.getBreadcrumbLabelFromRouteData(
      currentRoute.data,
    );
    const previousBreadcrumbLabel = this.getBreadcrumbLabelFromRouteData(
      previousRoute?.data,
    );

    const isBreadcrumbLabelInherited =
      !!currentRoute.data[BREADCRUMB_ROUTE_DATA_KEY] &&
      !!previousRoute?.data[BREADCRUMB_ROUTE_DATA_KEY] &&
      currentBreadcrumbLabel === previousBreadcrumbLabel;

    const isTitleInherited =
      !!currentRoute.title &&
      !!previousRoute?.title &&
      currentRoute.title === previousRoute?.title;

    if (isBreadcrumbLabelInherited) {
      return isTitleInherited ? undefined : currentRoute.title;
    } else {
      return (
        currentBreadcrumbLabel ??
        (isTitleInherited ? undefined : currentRoute.title)
      );
    }
  }

  private getBreadcrumbIsDisabledFromRouteConfig(
    routeConfig: Route | null,
    resolvedData: Data,
  ): boolean {
    if (
      routeConfig &&
      routeConfig.data &&
      routeConfig.data[BREADCRUMB_DISABLED_ROUTE_DATA_KEY]
    ) {
      return typeof routeConfig.data[BREADCRUMB_DISABLED_ROUTE_DATA_KEY] ===
        'function'
        ? routeConfig.data[BREADCRUMB_DISABLED_ROUTE_DATA_KEY](resolvedData)
        : routeConfig.data[BREADCRUMB_DISABLED_ROUTE_DATA_KEY];
    } else {
      return false;
    }
  }

  private getBreadcrumbLabelFromRouteData(data?: Data): string | undefined {
    if (data && data[BREADCRUMB_ROUTE_DATA_KEY]) {
      return typeof data[BREADCRUMB_ROUTE_DATA_KEY] === 'function'
        ? data[BREADCRUMB_ROUTE_DATA_KEY](data)
        : data[BREADCRUMB_ROUTE_DATA_KEY];
    } else {
      return undefined;
    }
  }

  private getUrl(route: ActivatedRouteSnapshot): string {
    // Build current route url string from root
    let url = route.pathFromRoot.reduce<string>(
      (accumulatedPath, activatedRoute) =>
        accumulatedPath +
        (activatedRoute.routeConfig?.path
          ? `/${activatedRoute.routeConfig?.path}`
          : ''),
      '',
    );

    // Replace router params with actual values: user/:id => user/123
    url.split('/').forEach((routePath) => {
      if (routePath.startsWith(':')) {
        url = url.replace(routePath, route?.params[routePath.substring(1)]);
      }
    });

    return url;
  }

  goUp(
    params?: {
      fallbackUrl?: string;
    } & NavigationExtras,
  ) {
    const previousLocation = this.breadcrumbs().at(-2)?.url;
    return defer(() =>
      this.router.navigate(
        [previousLocation ?? params?.fallbackUrl ?? '/'],
        params,
      ),
    );
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
  disabled?: boolean;
}
