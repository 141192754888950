export enum SignUpSuccessQueryParams {
  Email = 'email',
}

export enum SignInQueryParams {
  Next = 'next',
  Email = 'email',
}

export enum ConfirmQueryParams {
  ConfirmationType = 'confirmationType',
  Token = 'token',
  Email = 'email',
}

export enum ChangePasswordQueryParams {
  Token = 'token',
  Email = 'email',
}
