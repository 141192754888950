import { inject } from '@angular/core';
import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { GlobalLoadingService } from '@uis-services/global-loading/global-loading.service';

export const globalLoadingInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
) => {
  const progressBarService = inject(GlobalLoadingService);
  return progressBarService.watchLoading(next(req));
};
