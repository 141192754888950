import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  Injector,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DefaultDialogComponent } from 'src/app/content/common/default-dialog/default-dialog.component';
import { UisDialogWrapperParams } from '@uis-services/dialog/dialog.service';

@Component({
  selector: 'uis-dialog-container',
  standalone: true,
  imports: [CommonModule, MatDialogModule, DefaultDialogComponent],
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContainerComponent {
  matDialogData = inject<UisDialogWrapperParams<any>>(MAT_DIALOG_DATA);
  injector = inject(Injector);
  childInjector = Injector.create({
    parent: this.injector,
    providers: [
      {
        provide: UIS_DIALOG_DATA,
        useValue: this.matDialogData.dialogData,
      },
    ],
  });
}

export const UIS_DIALOG_DATA = new InjectionToken<any>('uisDialogData');
