import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddressEP } from '@uis-enums/endpoints';
import { map, shareReplay, switchMap } from 'rxjs';
import { SearchResult } from '@uis-models/contract/search';
import { AddressResponseItem } from '@uis-models/contract/user-address';
import { isEmpty, isNil, omitBy } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private readonly http = inject(HttpClient);

  private readonly ukraineCountryInfo$ = this.http
    .get<SearchResult<AddressResponseItem>>(AddressEP.Countries(), {
      params: { name: 'Україна' },
    })
    .pipe(
      map((res) => res.data.at(0)!),
      shareReplay(1),
    );

  getCountries(params?: { name?: string }) {
    return this.http.get<SearchResult<AddressResponseItem>>(
      AddressEP.Countries(),
      { params: omitBy(params, (value) => isNil(value) || isEmpty(value)) },
    );
  }

  getUkrainianRegions(name?: string) {
    return this.ukraineCountryInfo$.pipe(
      switchMap((ukraineCountryInfo) => {
        const params = {
          name: name ?? '',
          countryId: ukraineCountryInfo.value,
        };
        return this.http.get<SearchResult<AddressResponseItem>>(
          AddressEP.Regions(),
          { params: omitBy(params, (value) => isNil(value) || isEmpty(value)) },
        );
      }),
    );
  }

  getUkrainianCities(params: { name: string; regionId?: string }) {
    return this.ukraineCountryInfo$.pipe(
      switchMap((ukraineCountryInfo) => {
        const completeParams = {
          ...params,
          countryId: ukraineCountryInfo.value,
        };
        return this.http.get<SearchResult<AddressResponseItem>>(
          AddressEP.Cities(),
          {
            params: omitBy(
              completeParams,
              (value) => isNil(value) || isEmpty(value),
            ),
          },
        );
      }),
    );
  }
}
