@for (item of items; track item.label) {
  <div class="info-item">
    <span class="info-item__label">{{ item.label }}:</span>
    @if (item.displayedValue) {
      @if (item.href) {
        <a [href]="item.href">
          <span class="info-item__data">{{ item.displayedValue }}</span>
        </a>
      } @else {
        <a [routerLink]="item.routerLink" [queryParams]="item.queryParams">
          <span class="info-item__data">{{ item.displayedValue }}</span>
        </a>
      }
    } @else {
      <span class="info-item__data info-item__data_empty">{{
        item.noData ?? 'Немає інформації'
      }}</span>
    }
  </div>
}
