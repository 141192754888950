export interface CanDeactivateComponent {
  canDeactivate: (event?: any) => boolean | string;
}

export function canDeactivateImplementation(
  canDeactivate: () => boolean | string,
): (event?: BeforeUnloadEvent) => boolean | string {
  return (event?: BeforeUnloadEvent) => {
    const deactivateAllowed = canDeactivate();
    if (event && !deactivateAllowed) {
      event.returnValue = true;
    }
    return deactivateAllowed;
  };
}
