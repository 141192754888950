import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UisFormGroup } from '@uis-core/forms/uis-form-group';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { DatepickerComponent } from '@uis-common/inputs/datepicker/datepicker.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ButtonComponent } from '@uis-common/button/button.component';
import { UIS_DIALOG_DATA } from '@uis-common/dialog-container/dialog-container.component';
import { MovementService } from '@uis-services/movement/movement.service';
import { AcademicYear } from '@uis-models/contract/academic-year';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { TrimValueDirective } from '@uis-directives/trim-value/trim-value.directive';
import {
  AttachRequest,
  DetachRequest,
  MovedStudent,
  MovementsTypes,
  StudentsMovementsDialogContext,
  TransferRequest,
} from '@uis-private/uis-classes/dialogs/movements.models';
import { UisValidators } from '@uis-core/validators/validators';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { JoinApplicationService } from '@uis-services/join-application/join-application.service';
import { User } from '@uis-models/contract/user';
import { catchError, EMPTY } from 'rxjs';
import { MessageService } from '@uis-services/message/message.service';

@Component({
  selector: 'uis-students-movements-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    FormFieldComponent,
    MatFormField,
    MatOption,
    MatSelect,
    DatepickerComponent,
    MatSlideToggle,
    ButtonComponent,
    MatRadioButton,
    MatRadioGroup,
    MatIcon,
    MatInput,
    MatPrefix,
    TrimValueDirective,
    MatDialogClose,
  ],
  templateUrl: './students-movements-dialog.component.html',
  styleUrl: '../../../../../common/dialog.common.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentsMovementsDialogComponent {
  protected readonly movementService = inject(MovementService);
  protected readonly joinApplicationService = inject(JoinApplicationService);
  protected readonly message = inject(MessageService);
  protected readonly dialogRef = inject(MatDialogRef);
  protected readonly destroyRef = inject(DestroyRef);

  protected readonly classLevelOptions = computed(() => {
    const classLevel = this.dialogData().classLevel;

    return Array.from({ length: 11 }, (_, index) => index + 1).filter(
      (value) => {
        return this.transferDialog()
          ? value === classLevel! + 1
          : value >= classLevel!;
      },
    );
  });

  protected readonly academicYearOptionsWith = (
    option: AcademicYear | null,
    selection: AcademicYear | null,
  ) => {
    return !!(option && selection && option.id === selection.id);
  };

  protected readonly dialogData = signal(
    inject<StudentsMovementsDialogContext>(UIS_DIALOG_DATA),
  ).asReadonly();

  protected readonly transferDialog = computed(
    () => this.dialogData().dialogType === MovementsTypes.Transfer,
  );

  protected readonly attachDialog = computed(
    () => this.dialogData().dialogType === MovementsTypes.Attachment,
  );

  protected bulkMovement = computed(
    () => this.dialogData().selectedUsers.length > 1,
  );

  protected readonly dialogTitle = computed(() => {
    const bulkMovement = this.bulkMovement();
    const transferDialog = this.transferDialog();
    const attachDialog = this.attachDialog();
    const hasGraduation = this.hasGraduation();

    if (transferDialog || attachDialog) {
      return `Вкажіть клас для ${transferDialog ? 'переведення' : 'залучення'}`;
    }

    return `${hasGraduation ? 'Випустити' : 'Вилучити'} ${bulkMovement ? 'вибраних учнів' : 'учня'}?`;
  });

  protected readonly movementSuccessMessage = computed(() => {
    if (this.transferDialog()) {
      return 'Переведно успішно';
    }

    if (this.attachDialog()) {
      return 'Залучено успішно';
    }

    return this.hasGraduation() ? 'Випущено успішно' : 'Вилучено успішно';
  });

  protected readonly hasGraduation = computed(
    () => this.dialogData().hasGraduation,
  );

  protected readonly hasPedagogicalDecisionToggle = computed(
    () => this.transferDialog() && this.dialogData().classLevel === 9,
  );

  protected readonly needPedagogicalDecision = computed(() => {
    const transferDialog = this.transferDialog();
    const classLevel = this.dialogData().classLevel;
    const hasGraduation = this.hasGraduation();
    const [nine, eleven] = [9, 11];

    return (
      (classLevel === nine && transferDialog) ||
      (classLevel === eleven && hasGraduation)
    );
  });

  protected readonly transfersForm = new UisFormGroup({
    classLevel: new FormControl(null, [UisValidators.required()]),
    date: new FormControl(null, [UisValidators.required()]),
    academicYearId: new FormControl(null, [UisValidators.required()]),
    ...(this.needPedagogicalDecision()
      ? { isPedagogicalDecisionNeeded: new FormControl(true) }
      : null),
  });

  protected readonly detachForm = new UisFormGroup({
    date: new FormControl(null, [UisValidators.required()]),
    ...(!this.hasGraduation()
      ? {
          isUkraine: new FormControl(null, [UisValidators.required()]),
          schoolName: new FormControl(null, [UisValidators.required()]),
          country: new FormControl<null | string>(null, [
            UisValidators.required(),
          ]),
          city: new FormControl(null, [UisValidators.required()]),
        }
      : null),
    ...(this.needPedagogicalDecision()
      ? { isPedagogicalDecisionNeeded: new FormControl(true) }
      : null),
  });

  protected readonly academicYearsOptions = toSignal(
    this.joinApplicationService.getJoinApplicationYearOptions(),
  );

  protected readonly form =
    this.transferDialog() || this.attachDialog()
      ? this.transfersForm
      : this.detachForm;

  protected readonly countryControlListener = effect(() => {
    this.detachForm.controls.isUkraine?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isUkraine) => {
        const countryControl = this.detachForm.controls.country;

        return isUkraine
          ? countryControl?.patchValue('Ukraine')
          : countryControl?.patchValue(null);
      });
  });

  onSubmit() {
    const { dialogType, selectedUsers } = this.dialogData();
    let request;

    if (dialogType === MovementsTypes.Transfer) {
      const transfersFormValue = this.transfersForm.getRawValue();

      const payload: TransferRequest = {
        classLevel: transfersFormValue.classLevel!,
        date: transfersFormValue.date!,
        academicYearId: transfersFormValue.academicYearId!,
        isPedagogicalDecisionNeeded:
          transfersFormValue.isPedagogicalDecisionNeeded!,
        studentIds: selectedUsers.map((user) => user.id),
      };

      request = this.movementService.createTransfers(payload);
    }

    if (dialogType === MovementsTypes.Detachment) {
      const detachFormValue = this.detachForm.getRawValue();
      let payload: DetachRequest = {
        date: detachFormValue.date!,
        studentIds: selectedUsers.map((user) => user.id),
        isPedagogicalDecisionNeeded:
          detachFormValue.isPedagogicalDecisionNeeded!,
      };

      if (!this.hasGraduation()) {
        payload = {
          ...payload,
          detachmentPlace: {
            isUkraine: detachFormValue.isUkraine!,
            schoolName: detachFormValue.schoolName!,
            country: detachFormValue.country!,
            city: detachFormValue.city!,
          },
        };
      }

      request = this.movementService.detachStudents(payload);
    }

    if (dialogType === MovementsTypes.Attachment) {
      const selectedUser = selectedUsers[0];
      const transfersFormValue = this.transfersForm.getRawValue();
      const payload: AttachRequest = {
        classLevel: transfersFormValue.classLevel!,
        date: transfersFormValue.date!,
        academicYearId: transfersFormValue.academicYearId!,
        studentId: this.isMovedStudent(selectedUser)
          ? selectedUser.studentId
          : '',
      };

      request = this.movementService.attachStudents(payload);
    }

    request!
      .pipe(
        catchError(() => {
          this.dialogRef.close('error');
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.message.success(this.movementSuccessMessage());
        this.dialogRef.close(true);
      });
  }

  protected readonly userName = computed(() => {
    const selectedUser = this.dialogData().selectedUsers[0];

    return this.isMovedStudent(selectedUser)
      ? selectedUser.studentName
      : selectedUser.fullName;
  });

  private isMovedStudent(user: User | MovedStudent): user is MovedStudent {
    return 'moveType' in user;
  }
}
