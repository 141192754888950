<div class="snackbar snackbar_{{ data.type }}">
  <div class="snackbar__type-block">
    <div class="snackbar__type-block__icon"></div>
    <span
      class="snackbar__queue-position"
      [class.snackbar__queue-position_visible]="
        (messageService.messageCallsQueueLength$ | async)! > 1
      "
      >{{
        data.queuePosition +
          ' з ' +
          (messageService.messageCallsQueueLength$ | async)
      }}</span
    >
  </div>
  <div class="snackbar__text-container">
    @if (data.title) {
      <span
        class="snackbar__title"
        [class.snackbar__title_with-margin]="data.message"
        >{{ data.title }}</span
      >
    }
    @if (data.message) {
      <p class="snackbar__message">{{ data.message }}</p>
    }
  </div>
  <button
    class="snackbar__dismiss"
    type="button"
    (click)="dismiss($event)"
  ></button>
</div>
