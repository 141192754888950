import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Input,
  Signal,
  signal,
} from '@angular/core';

import { CenteredPageComponent } from '@uis-common/page/centered-page/centered-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContentDividerComponent } from '@uis-common/content-divider/content-divider.component';
import { UserGeneralInfoFormComponent } from '@uis-private/components/user-forms/user-general-info-form/user-general-info-form.component';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { EmployeeWorkload, EmployeeWorkloadNameText } from '@uis-enums/user';
import { RolesService } from '@uis-services/roles/roles.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { KendoFilter } from '@uis-core/classes/kendo-data-query';
import { getEmployeeForm, UserRole } from '@uis-models/contract/user';
import { KendoFilterOperator, KendoSortDir } from '@uis-enums/kendo';
import {
  CanChipBeDeletedFn,
  ChipListAutocompleteComponent,
  SearchCall,
} from '@uis-common/inputs/chip-list-autocomplete/chip-list-autocomplete.component';
import { SubjectsService } from '@uis-services/subjects/subjects.service';
import { RoleDataService } from '@uis-services/role-data/role-data.service';
import { UisSubject } from '@uis-models/contract/subject';
import { UisTrait } from '@uis-enums/permissions';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { map, of, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'uis-employee-form',
  standalone: true,
  imports: [
    CenteredPageComponent,
    ReactiveFormsModule,
    ContentDividerComponent,
    UserGeneralInfoFormComponent,
    FormFieldComponent,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    ChipListAutocompleteComponent,
  ],
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss', '../user-forms-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeFormComponent {
  protected readonly workloadOptions = Object.values(EmployeeWorkload);
  protected readonly EmployeeWorkloadNameText = EmployeeWorkloadNameText;
  protected readonly can = inject(RoleDataService).can;
  private readonly rolesService = inject(RolesService);
  private readonly subjectsService = inject(SubjectsService);

  @Input({
    alias: 'employeeForm',
    required: true,
  })
  set employeeFormInput(employeeForm: ReturnType<typeof getEmployeeForm>) {
    this.employeeForm.set(employeeForm ?? null);
  }

  protected readonly employeeForm = signal<ReturnType<
    typeof getEmployeeForm
  > | null>(null);

  protected readonly selectedRoles: Signal<UserRole[]> = toSignal(
    toObservable(this.employeeForm).pipe(
      switchMap(
        (form) =>
          form?.controls.roles?.valueChanges.pipe(
            startWith(form?.controls.roles.value),
            map((userRoles) => userRoles ?? []),
          ) ?? of([] as UserRole[]),
      ),
    ),
    { initialValue: [] as UserRole[] },
  );
  protected readonly selectedRolesTraits = computed(() =>
    this.selectedRoles().reduce((set, role) => {
      role.traits?.forEach((trait) => set.add(trait));
      return set;
    }, new Set<UisTrait>()),
  );
  private readonly disableSubjectsOnTraitsChangeEffect = effect(
    () => {
      const subjectsControl = this.employeeForm()?.controls.subjects;
      if (!subjectsControl) {
        return;
      }
      const traits = this.selectedRolesTraits();
      if (traits.has(UisTrait.MustHaveSubjects)) {
        subjectsControl.enable();
      } else {
        subjectsControl.disable();
      }
    },
    { allowSignalWrites: true },
  );

  protected readonly getRolesFilters: KendoFilter[] = [
    {
      field: 'isMandatory' as keyof UserRole,
      operator: KendoFilterOperator.Eq,
      value: false,
    },
  ];

  protected getSubjectsChipListAutocompleteCall: SearchCall = (query) => {
    query.pushSorts({
      field: 'name' as keyof UisSubject,
      dir: KendoSortDir.Asc,
    });
    return this.subjectsService.getAllSubjects(query);
  };

  protected getRolesChipListAutocompleteCall: SearchCall = (query) => {
    query.pushSorts({
      field: 'displayName' as keyof UserRole,
      dir: KendoSortDir.Asc,
    });
    return this.rolesService.getEmployeeRoles(query);
  };

  protected roleCanBeDeleted: CanChipBeDeletedFn<UserRole> = (role: UserRole) =>
    !role.isMandatory;
  protected readonly UisTrait = UisTrait;
}
