import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';

import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { AddressService } from '@uis-services/address/address.service';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TrimValueDirective } from '@uis-directives/trim-value/trim-value.directive';
import { getUserForeignAddressFormControls } from '@uis-models/contract/user-address';
import { UisFormGroup } from '@uis-core/forms/uis-form-group';

@Component({
  selector: 'uis-foreign-address-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormFieldComponent,
    MatInputModule,
    MatAutocompleteModule,
    TrimValueDirective,
  ],
  templateUrl: './foreign-address-form.component.html',
  styleUrls: ['./foreign-address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForeignAddressFormComponent implements OnInit {
  private readonly addresses = inject(AddressService);
  private readonly destroyRef = inject(DestroyRef);

  @Input({ required: true }) foreignAddressForm!: UisFormGroup<
    ReturnType<typeof getUserForeignAddressFormControls>
  >;

  //to use in template ngIf to create individual scopes for same-named template variables
  protected readonly forScopeCreation = true;

  protected readonly countryOptionsNameQuery$ = new BehaviorSubject<string>('');
  protected readonly countryOptions = toSignal(
    this.countryOptionsNameQuery$.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((name) =>
        this.addresses.getCountries({
          name,
        }),
      ),
      map((res) => res.data),
    ),
    { initialValue: [] },
  );

  ngOnInit() {
    this.initCountryOptionsNameQueryClearListener();
  }

  initCountryOptionsNameQueryClearListener() {
    this.foreignAddressForm.controls.country.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.countryOptionsNameQuery$.next(''));
  }
}
