<form class="dialog" [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 matDialogTitle class="dialog__title">
    {{ dialogTitle() }}
  </h2>
  <p class="dialog__subtitle">
    @if (dialogData().selectedUsers; as selectedUsers) {
      {{
        bulkMovement() ? 'Кількість учнів ' + selectedUsers.length : userName()
      }}
    }
  </p>
  <mat-dialog-content class="dialog__content">
    @if (transferDialog() || attachDialog()) {
      <uis-form-field label="Клас">
        <mat-form-field>
          <mat-select formControlName="classLevel" placeholder="Оберіть клас">
            @for (classLevel of classLevelOptions(); track classLevel) {
              <mat-option [value]="classLevel">
                {{ classLevel }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </uis-form-field>

      <uis-form-field
        [label]="transferDialog() ? 'Дата переведення' : 'Дата залучення'"
      >
        <uis-datepicker formControlName="date" />
      </uis-form-field>

      <uis-form-field label="Навчальний рік">
        <mat-form-field>
          <mat-select
            placeholder="Оберіть навчальний рік в який хочете вступити"
            formControlName="academicYearId"
            [compareWith]="academicYearOptionsWith"
          >
            @for (
              academicYearOption of academicYearsOptions();
              track academicYearOption
            ) {
              <mat-option [value]="academicYearOption?.id">
                {{ academicYearOption?.displayName }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </uis-form-field>

      @if (hasPedagogicalDecisionToggle()) {
        <mat-slide-toggle formControlName="isPedagogicalDecisionNeeded"
          >Створити рішення педагогічної ради
        </mat-slide-toggle>
      }
    } @else {
      <uis-form-field
        [label]="hasGraduation() ? 'Дата випуску' : 'Дата вилучення'"
      >
        <uis-datepicker formControlName="date" />
      </uis-form-field>

      @if (!hasGraduation()) {
        <uis-form-field label="Куди вибуває">
          <mat-radio-group formControlName="isUkraine">
            <div class="user-form__radio-buttons-container">
              <mat-radio-button [value]="true"
                >{{ bulkMovement() ? 'Повернулися' : 'Повернувся' }} в
                Україну</mat-radio-button
              >
              <mat-radio-button [value]="false"
                >{{ bulkMovement() ? 'Продовжили' : 'Продовжив' }} навчання в
                місцевій школі</mat-radio-button
              >
            </div>
          </mat-radio-group>
        </uis-form-field>

        @if ($any(form).controls.isUkraine.value !== null) {
          <uis-form-field label="Назва НЗ">
            <mat-form-field>
              <input
                matInput
                uisTrim
                type="text"
                placeholder="Введіть тут"
                formControlName="schoolName"
              />
            </mat-form-field>
          </uis-form-field>

          @if (!$any(form).controls.isUkraine.value) {
            <uis-form-field label="Країна">
              <mat-form-field>
                <input
                  matInput
                  uisTrim
                  type="text"
                  placeholder="Введіть тут"
                  formControlName="country"
                />
              </mat-form-field>
            </uis-form-field>
          }

          <uis-form-field label="Місто">
            <mat-form-field>
              <input
                matInput
                uisTrim
                type="text"
                placeholder="Введіть тут"
                formControlName="city"
              />
            </mat-form-field>
          </uis-form-field>
        }
      }
    }
  </mat-dialog-content>
  <mat-dialog-actions class="dialog__actions">
    <uis-button type="submit" [disabled]="form.invalid"
      >Підтвердити
    </uis-button>
  </mat-dialog-actions>
</form>
