import { UisEntity } from '@uis-models/contract/uis-entity';
import {
  getUserForeignAddressFormControls,
  getUserUkrainianAddressFormControls,
  UserAddress,
} from '@uis-models/contract/user-address';
import { Email } from '@uis-core/types/strings';
import { EmployeeWorkload, UserSex } from '@uis-core/enums/user';
import { Parent } from '@uis-models/contract/parent';
import { UisSubject } from '@uis-models/contract/subject';
import { FormControl } from '@angular/forms';
import { UisValidators } from '@uis-core/validators/validators';
import { UisFormGroup } from '@uis-core/forms/uis-form-group';
import { UisFormArray } from '@uis-core/forms/uis-form-array';
import { UisFile } from '@uis-models/contract/uis-file';
import { UserProfileFile } from '@uis-enums/file-types';
import { UisTrait } from '@uis-enums/permissions';
import { UisRegExp } from '@uis-core/constants/uis-reg-exp';

export interface User extends UisEntity {
  profilePicture?: UisFile<UserProfileFile.ProfilePicture>;
  email: Email;
  isStudent: boolean;
  birthDay: Date;
  firstName: string;
  fullName: string;
  lastName: string;
  middleName?: string;
  phoneNumber?: string;
  skypeUsername?: string;
  sex: UserSex;
  isRegistrationComplete: boolean;
  roles: UserRole[];
  hasMandatoryRole: boolean;
  publicId: string;
  classLevel: number;
}

export class User {
  constructor(user: User) {
    Object.assign(this, user);
    this.hasMandatoryRole = this.roles.some((role) => role.isMandatory);
  }
}

export interface UserSearchModel extends User {
  traits: UisTrait[];
}

export interface EmployeeSearchModel extends Employee {
  traits: UisTrait[];
}

export interface StudentSearchModel extends Student {
  traits: UisTrait[];
}

export interface Student extends User {
  parents: Parent[];
  ukrainianAddress: UserAddress;
  foreignAddress: UserAddress;
}

export interface StudentCreateRequest extends Partial<Student> {
  skipJoinApplicationFlow: boolean;
  classLevel?: number;
  academicYear?: number;
}

export function isStudent(user?: User | null): user is Student {
  return !!user?.isStudent;
}

export interface Employee extends User {
  workload: EmployeeWorkload;
  subjects: UisSubject[];
}

export function isEmployee(user?: User | null): user is Employee {
  return !user?.isStudent;
}

export interface UserRole extends UisEntity {
  displayName: string;
  isCustom: boolean;
  isStudent: boolean;
  isMandatory: boolean;
  traits: UisTrait[];
}

export function getUserGeneralInfoFormControls(params?: {
  requiredPhoneNumber?: boolean;
  birthDayDisabled?: boolean;
  profilePictureDisabled?: boolean;
  emailDisabled?: boolean;
  skypeUsernameDisabled?: boolean;
  skypeUsernameRequired?: boolean;
}) {
  const defaultParams = {
    requiredPhoneNumber: true,
    birthDayDisabled: false,
    profilePictureDisabled: false,
    emailDisabled: false,
    skypeUsernameDisabled: false,
    skypeUsernameRequired: false,
  };

  const appliedParams = { ...defaultParams, ...params };

  return {
    profilePicture:
      new FormControl<UisFile<UserProfileFile.ProfilePicture> | null>(
        {
          disabled: appliedParams.profilePictureDisabled,
          value: null,
        },
        [UisValidators.validateFileType(UserProfileFile.ProfilePicture)],
      ),
    firstName: new FormControl('', [
      UisValidators.required(),
      UisValidators.personNameEntry(),
    ]),
    lastName: new FormControl('', [
      UisValidators.required(),
      UisValidators.personNameEntry(),
    ]),
    middleName: new FormControl('', [UisValidators.personNameEntry()]),
    phoneNumber: new FormControl('', [
      UisValidators.requiredIf(() => appliedParams.requiredPhoneNumber),
      UisValidators.internationalPhoneNumber(),
    ]),
    email: new FormControl(
      { value: '', disabled: appliedParams.emailDisabled },
      [UisValidators.email(), UisValidators.required()],
    ),
    sex: new FormControl(null, [UisValidators.required()]),
    birthDay: new FormControl(
      { value: null, disabled: appliedParams.birthDayDisabled },
      [UisValidators.required(), UisValidators.dateInThePast()],
    ),
    skypeUsername: new FormControl(
      { value: '', disabled: appliedParams.skypeUsernameDisabled },
      appliedParams.skypeUsernameRequired
        ? [
            UisValidators.required(),
            UisValidators.pattern(UisRegExp.SkypeUsername),
          ]
        : [UisValidators.pattern(UisRegExp.SkypeUsername)],
    ),
  };
}

export function getStudentForm(forAdmin = false) {
  const studentGeneralInfoConfig = {
    requiredPhoneNumber: false,
    emailDisabled: !forAdmin,
    profilePictureDisabled: false,
    birthDayDisabled: false,
  };

  const parentGeneralInfoConfig = {
    requiredPhoneNumber: true,
    emailDisabled: false,
    profilePictureDisabled: true,
    birthDayDisabled: true,
    skypeUsernameDisabled: true,
  };

  return new UisFormGroup({
    ...getUserGeneralInfoFormControls(studentGeneralInfoConfig),
    parents: new UisFormArray(
      [
        new UisFormGroup(
          getUserGeneralInfoFormControls(parentGeneralInfoConfig),
        ),
      ],
      () =>
        new UisFormGroup(
          getUserGeneralInfoFormControls(parentGeneralInfoConfig),
        ),
    ),
    ukrainianAddress: new UisFormGroup(getUserUkrainianAddressFormControls()),
    foreignAddress: new UisFormGroup(getUserForeignAddressFormControls()),
  });
}

export function getEmployeeForm(forAdmin = false) {
  return new UisFormGroup({
    ...getUserGeneralInfoFormControls({
      birthDayDisabled: false,
      requiredPhoneNumber: true,
      profilePictureDisabled: false,
      emailDisabled: !forAdmin,
      skypeUsernameDisabled: false,
      skypeUsernameRequired: true,
    }),
    workload: new FormControl({ value: null, disabled: !forAdmin }, [
      UisValidators.required(),
    ]),
    roles: new FormControl(
      { value: <UserRole[]>(<unknown>[]), disabled: !forAdmin },
      [UisValidators.minLength(1), UisValidators.required()],
    ),
    subjects: new FormControl([], [UisValidators.required()]),
  });
}

export enum UserType {
  Employee = 'employee',
  Student = 'student',
}
