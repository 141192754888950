@if (liqPayData(); as paymentData) {
  <form
    ngNoForm
    #form
    class="dialog"
    method="POST"
    accept-charset="utf-8"
    [action]="paymentData?.url"
  >
    <input type="hidden" name="data" [value]="paymentData?.data" />
    <input type="hidden" name="signature" [value]="paymentData?.signature" />

    <h2 matDialogTitle class="dialog__title">
      Бажаєте здійснити оплату за платежем?
    </h2>

    <mat-dialog-content class="dialog__content">
      <section class="dialog__payments-section">
        @for (section of dialogSections(); track section) {
          <section class="dialog__payments-section__subsection">
            <h4 class="dialog__payments-section__subsection__title">
              {{ section.title }}:
            </h4>
            <p class="dialog__payments-section__subsection__text">
              {{ section.text }}
            </p>
          </section>
        }
      </section>
    </mat-dialog-content>

    <mat-dialog-actions class="dialog__actions">
      <uis-button
        buttonColor="gray"
        buttonFill="outline"
        [mat-dialog-close]="null"
        >Скасувати
      </uis-button>
      <uis-button (click)="form.submit()">Підтвердити </uis-button>
    </mat-dialog-actions>
  </form>
}
