import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilitiesEP } from '@uis-enums/endpoints';
import { map, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SynchroCall } from '@uis-models/local/synchro-call';
import { environment } from '../../../../environments/environment';

export enum Season {
  Winter = 'winter',
  Spring = 'spring',
  Summer = 'summer',
  Autumn = 'autumn',
}

const SEASONS_ORDER = [
  Season.Winter,
  Season.Spring,
  Season.Summer,
  Season.Autumn,
];

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  private readonly http = inject(HttpClient);

  private serverTimeDifference = 0;

  synchronize() {
    return this.getServerTime(() => this.syncDateNow()).pipe(
      tap((res) => this.updateSyncDiff(res)),
      switchMap(() => this.getServerTime(() => this.syncDateNow())),
      tap((res) => this.updateSyncDiff(res)),
      tap((res) => {
        if (!environment.production) {
          const notSyncTime = Date.now();
          const diffBeforeSync = res.serverTime - notSyncTime;
          this.getCurrentSynchroDiff().subscribe((diff) => {
            console.info(
              `Before sync, server time is approx: ${
                diffBeforeSync > 0 ? '+' + diffBeforeSync : diffBeforeSync
              }ms, relative to client`,
            );
            console.info(
              `After sync, server time is approx: ${
                diff > 0 ? '+' + diff : diff
              }ms, relative to client`,
            );
          });
        }
      }),
    );
  }

  private getServerTime(
    getCurrentLocalTime: () => number,
  ): Observable<SynchroCall> {
    return of(getCurrentLocalTime()).pipe(
      switchMap((requestStartTime) =>
        this.http.get<string>(UtilitiesEP.Time()).pipe(
          map((res) => ({
            requestStartTime,
            serverTime: new Date(res).getTime(),
            responseEndTime: getCurrentLocalTime(),
          })),
        ),
      ),
    );
  }

  private updateSyncDiff(res: SynchroCall) {
    const callDuration = res.responseEndTime - res.requestStartTime;
    const approxTimeToServer = Math.round(callDuration / 2);
    const approxLocalTimeOnServerReceived =
      res.requestStartTime + approxTimeToServer;
    this.serverTimeDifference +=
      res.serverTime - approxLocalTimeOnServerReceived;
  }

  private getCurrentSynchroDiff() {
    return this.getServerTime(() => this.syncDateNow()).pipe(
      map((res) => {
        const callDuration = res.responseEndTime - res.requestStartTime;
        const approxTimeToServer = Math.round(callDuration / 2);
        return res.serverTime - (res.requestStartTime + approxTimeToServer);
      }),
    );
  }

  newSyncDate(
    input: ConstructorParameters<typeof Date>[0] | Date = new Date(),
  ) {
    return new Date(new Date(input).getTime() + this.serverTimeDifference);
  }

  syncDateNow() {
    return this.newSyncDate().getTime();
  }

  private getSeasonIndex(date: Date) {
    return Math.floor(((date.getMonth() + 1) / 12) * 4) % 4;
  }

  public readonly currentSeason = () =>
    SEASONS_ORDER[this.getSeasonIndex(this.newSyncDate())];
  public readonly isWinter = () => this.currentSeason() === Season.Winter;
  public readonly isSpring = () => this.currentSeason() === Season.Spring;
  public readonly isSummer = () => this.currentSeason() === Season.Summer;
  public readonly isAutumn = () => this.currentSeason() === Season.Autumn;
}
