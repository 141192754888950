<mat-form-field>
  <mat-chip-grid
    #chipGrid
    [uisDisableControl]="isDisabled()"
    [formControl]="mockFormControl"
    [errorStateMatcher]="errorStateMatcher"
  >
    @for (chip of chips(); track chip) {
      <mat-chip-row
        (removed)="deleteItem(chip)"
        [highlighted]="!!chip.canBeDeleted"
        [disabled]="!chip.canBeDeleted"
      >
        {{ chip.label }}
        @if (chip.canBeDeleted) {
          <button matChipRemove [matTooltip]="deleteChipTooltip()">
            <mat-icon>cancel</mat-icon>
          </button>
        }
      </mat-chip-row>
    }
  </mat-chip-grid>
  <input
    [placeholder]="placeholder() ?? ''"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (input)="onTextSearchChange($event)"
    (focusout)="onInputFocusOut()"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    requireSelection
    [autoActiveFirstOption]="!!nameQuery()"
    (optionSelected)="onAutocompleteOptionSelected($event)"
  >
    @for (option of options(); track option) {
      <mat-option [value]="option.value" [disabled]="!!option.disabled">
        {{ option.label }}
      </mat-option>
    }
    @if (!options().length) {
      <mat-option [value]="null" [disabled]="true">
        Нічого не знайдено
      </mat-option>
    }
  </mat-autocomplete>
  @if (isLoading()) {
    <mat-progress-spinner
      class="loader"
      mode="indeterminate"
      diameter="20"
      matSuffix
    ></mat-progress-spinner>
  }
</mat-form-field>
