import {
  KendoFilterLogic,
  KendoFilterOperator,
  KendoSortDir,
} from '@uis-enums/kendo';
import { HttpParams } from '@angular/common/http';

export class KendoDataQuery<T extends { [key: string]: any } = any> {
  skip?: number;
  take?: number;
  term?: string;
  sort?: Array<KendoSortDescriptor<T>>;
  onlyTotal = false;
  constSorts?: Array<KendoSortDescriptor<T>>;
  filter?: KendoFilterDescriptor<T>;
  constFilters?: KendoFilterDescriptor<T>[];

  private defaults: {
    skip: number;
    take: number;
    sort: KendoSortDescriptor[];
    filter: KendoFilterDescriptor<T>;
  } = {
    skip: 0,
    take: 0,
    sort: [],
    filter: {
      logic: KendoFilterLogic.And,
      filters: [],
    },
  };

  constructor(
    skip: number = 0,
    take: number = 0,
    sort: Array<KendoSortDescriptor> = [],
    filter: KendoFilterDescriptor<T> = {
      logic: KendoFilterLogic.And,
      filters: [],
    },
  ) {
    this.skip = skip;
    this.defaults.skip = skip;
    this.take = take;
    this.defaults.take = take;
    this.sort = sort;
    this.defaults.sort = sort;
    this.filter = filter;
    this.defaults.filter = filter;
  }

  /**
   * Return generated query as a property of a class. Ex: yourFilter.query
   * Notes: This object can be used as a parameters in a rest service.
   */
  get query(): RESTQuery {
    const query: any = {};

    if (this.onlyTotal) {
      query.take = 0;
    } else if (this.take) {
      query.take = this.take;
    }

    if (this.skip) {
      query.skip = this.skip;
    }
    if (this.term) {
      query.term = this.term;
    }

    query[`filter[logic]`] = this.filter?.logic
      ? this.filter.logic
      : KendoFilterLogic.And;

    const filters = [
      ...(this.filter?.filters ?? []),
      ...(this.constFilters ?? []),
    ] as Array<KendoFilter>;

    if (this.filter?.filters || this.constFilters) {
      const filterTemplate = 'filter[filters]';
      filters.forEach((res, index) => {
        query[`${filterTemplate}[${index}][field]`] = res.field;
        query[`${filterTemplate}[${index}][operator]`] = res.operator;
        query[`${filterTemplate}[${index}][value]`] =
          res.value instanceof Date
            ? new Date(res.value.setHours(23, 59, 59)).toJSON()
            : res.value;
      });
    }

    if (this.sort || this.constSorts) {
      const sortTemplate = 'sort';
      const sort = [...(this.constSorts ?? []), ...(this.sort ?? [])];
      sort.forEach((value, index) => {
        if (value.dir) {
          query[`${sortTemplate}[${index}][field]`] = value.field;
          query[`${sortTemplate}[${index}][dir]`] = value.dir;
        }
      });
    }
    return query;
  }

  asHttpOptions() {
    return {
      params: new HttpParams({ fromObject: this.query }),
    };
  }

  /**
   * The function that push sorts to class array, with one one feature -  automatically detect duplicates and delete them.
   */
  pushSorts(
    sorts: KendoSortDescriptor<T> | Array<KendoSortDescriptor<T>>,
  ): void {
    if (!this.sort) {
      this.sort = this.defaults.sort;
    }
    this.sort = this.sort
      .concat(sorts)
      .filter(
        (sort, index, self) =>
          index === self.findIndex((s) => s.field === sort.field),
      );
  }

  /**
   * The function that can take such parameters as:
   * - `string` - delete sort by field
   * - `Array<string>` - the same as above, but with multiples of field
   */
  popSorts(sorts: keyof T | Array<string>): void {
    if (typeof sorts === 'string') {
      this.sort = this.sort?.filter(
        (item) => item.field !== (sorts as keyof T),
      );
    } else {
      const sortsByFieldToRemove = sorts as Array<string>;
      this.sort = this.sort?.filter(
        (items) => !sortsByFieldToRemove.find((rm) => items.field === rm),
      );
    }
  }

  /**
   * Change filter logic.
   * Available options:
   * - `Or`
   * - `And`
   */
  changeFilterLogic(logic: KendoFilterLogic): void {
    if (!this.filter) {
      this.filter = { logic, filters: [] };
    }
    this.filter.logic = logic;
  }

  /**
   * The function that push filters to class array, with one one feature -  automatically detect duplicates field and operator combination and replace them.
   */
  pushFilters(
    filters: KendoFilter<T> | Array<KendoFilter<T>>,
    allowMultiple = false,
  ): void {
    let filtersToPush: Array<KendoFilter<T>> = [];
    if (Array.isArray(filters)) {
      filtersToPush = filters;
    } else {
      filters = filters as KendoFilter<T>;
      filtersToPush.push(filters);
    }
    filtersToPush.forEach((value) => {
      if (!this.filter) {
        this.filter = this.defaults.filter;
      }
      const index = this.filter.filters.findIndex(
        (item) =>
          item.field === value.field && item.operator === value.operator,
      );
      if (index === -1 || allowMultiple) {
        this.filter?.filters.push(value);
      } else {
        this.filter.filters[index] = value;
      }
    });
  }

  changeTake(take: number): void {
    this.take = take;
  }

  /**
   * The function that can take such parameters as:
   * - `string` - it will find all occurrences of that value with field, and delete all of them
   * - `Array<string>` - the same as above, but with multiples of field
   * - `KendoFilter` - delete only specific filter, so if there are 2 filters with the same field
   * and values but different operators than it will delete only that match by operator.
   * - `Array<KendoFilter>` - the same as above, but with multiples of Kendo Filters
   */
  popFilters(
    filters: string | Array<string> | KendoFilter | Array<KendoFilter>,
  ): void {
    if (!this.filter || !this.filter.filters) {
      return;
    }
    if (typeof filters === 'string') {
      this.filter.filters = this.filter.filters.filter(
        (item) => item.field !== (filters as string),
      );
    } else {
      if (
        Array.isArray(filters) &&
        // @ts-ignore // TODO: fix lint error
        filters.every((filter) => typeof filter === 'string')
      ) {
        const filtersByFieldToRemove = filters as Array<string>;
        this.filter.filters = this.filter.filters.filter(
          (items) => !filtersByFieldToRemove.find((rm) => items.field === rm),
        );
      } else {
        if (
          Array.isArray(filters) &&
          // @ts-ignore // TODO: fix lint error
          filters.every((filter: any) => typeof filter === 'object')
        ) {
          const filtersToRemove = filters as Array<KendoFilter>;
          this.filter.filters = this.filter.filters.filter(
            (items) =>
              !filtersToRemove.find(
                (rm) =>
                  rm.field === items.field &&
                  rm.operator === items.operator &&
                  rm.value === items.value,
              ),
          );
        } else {
          const filterToRemove = filters as KendoFilter;
          this.filter.filters = this.filter.filters.filter(
            (item) =>
              item.field !== filterToRemove.field &&
              item.operator !== filterToRemove.operator &&
              item.value !== filterToRemove.value,
          );
        }
      }
    }
  }

  /**
   * Clear KendoDataQuery to predefined defaults.
   */
  clear(): void {
    this.skip = 0;
    this.take = 0;
    this.sort = [];
    this.filter = {
      logic: KendoFilterLogic.And,
      filters: [],
    };
  }

  /**
   * Reset KendoDataQuery to your defaults that was took as values from constructor;
   */
  reset(): void {
    this.skip = this.defaults.skip;
    this.take = this.defaults.take;
    this.sort = this.defaults.sort;
    this.filter = this.defaults.filter;
  }
}

interface KendoSortDescriptor<T extends { [key: string]: any } = any> {
  /**
   * The data item field to which the filter operator is applied.
   */
  field: keyof T;
  /**
   * The sort direction. If no direction is set, the descriptor will be skipped during processing.
   *
   * The available values are:
   * - `asc`
   * - `desc`
   */
  dir?: KendoSortDir;
}

interface KendoFilterDescriptor<T extends { [key: string]: any } = any> {
  logic: KendoFilterLogic;
  filters: Array<KendoFilter<T>>;
}

export interface KendoFilter<T extends { [key: string]: any } = any> {
  field?: keyof T;
  operator: KendoFilterOperator;
  value?: any;
}

export interface KendoQuery {
  skip: number;
  take: number;
  sort: KendoSortDescriptor[];
  filter: KendoFilterDescriptor;
}

export interface RESTQuery {
  [key: string]: string;
}

export class Filter {
  [key: string]: string;

  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
