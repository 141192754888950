<form class="dialog" [formGroup]="ispItemForm" (ngSubmit)="onSubmit()">
  <h2 matDialogTitle class="dialog__title">
    {{ texts().title }}
  </h2>
  <mat-dialog-content class="dialog__content">
    @if (!dialogData().action) {
      <section class="dialog__general-info">
        <uis-info-table [items]="generalInfoItems()" />
      </section>
    }
    @if (
      dialogData().action === IspTaskDialogAction.Evaluate ||
      dialogData().action === IspTaskDialogAction.EditMark
    ) {
      <section [formGroup]="assessmentForm">
        <section formGroupName="mark">
          <uis-content-divider>
            <h3>Оцінка</h3>
          </uis-content-divider>
          <uis-form-field label="Оцінка">
            <mat-form-field>
              <mat-select formControlName="value" placeholder="Оберіть оцінку">
                @for (markOption of markOptions(); track markOption) {
                  <mat-option [value]="markOption.value">
                    {{ markOption.label }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </uis-form-field>
          <uis-form-field label="Коментар до оцінки">
            <mat-form-field>
              <textarea matInput formControlName="comment"></textarea>
            </mat-form-field>
          </uis-form-field>
        </section>
        <section>
          <uis-content-divider>
            <h3>Перевірене завдання</h3>
          </uis-content-divider>
          <uis-control-error-container formGroupName="reviewedAnswer">
            <uis-form-field label="Додати коментар">
              <mat-form-field>
                <textarea matInput formControlName="description"></textarea>
              </mat-form-field>
            </uis-form-field>
            <uis-form-field label="Додати перевірену роботу">
              <uis-file-input
                formControlName="attachments"
                [fileType]="IspTaskFile.ReviewedAnswer"
              />
            </uis-form-field>
          </uis-control-error-container>
        </section>
      </section>
    }
    @if (dialogData().action === IspTaskDialogAction.Reject) {
      <section>
        <uis-form-field label="Причина відхилення попередньої роботи">
          <mat-form-field>
            <textarea matInput formControlName="rejectReason"></textarea>
          </mat-form-field>
        </uis-form-field>
      </section>
    }
    @if (dialogData().action === IspTaskDialogAction.RequestCorrection) {
      <ng-container [formGroup]="correctionRequestForm">
        <section>
          <uis-form-field label="Опис необхідних правок">
            <mat-form-field>
              <textarea matInput formControlName="correctionReason"></textarea>
            </mat-form-field>
          </uis-form-field>
        </section>
      </ng-container>
    }
    @if (dialogData().action === IspTaskDialogAction.SubmitAnswer) {
      <section [formGroup]="submitAnswerForm.controls.answer">
        <uis-control-error-container>
          <uis-form-field label="Введіть текст/повідомлення">
            <mat-form-field>
              <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
          </uis-form-field>
          <uis-form-field label="Виконане завдання завантажте сюди">
            <uis-file-input
              formControlName="attachments"
              [fileType]="IspTaskFile.Answer"
            />
          </uis-form-field>
        </uis-control-error-container>
      </section>
    }
    @if (dialogData().action === IspTaskDialogAction.UpdateInterviewDate) {
      <ng-container [formGroup]="interviewTimeForm">
        <section>
          <uis-form-field label="Вкажіть новий час">
            <uis-datepicker
              mode="datetime"
              formControlName="date"
              [min]="calendarStart"
              [max]="calendarEnd"
            />
          </uis-form-field>
        </section>
      </ng-container>
    }
    @if (isTasks()) {
      @if (itemAssignmentVariantsLoading$ | async) {
        <div class="dialog__section-loading-container">
          <mat-progress-spinner mode="indeterminate" />
        </div>
      } @else {
        <section>
          @if (itemAssignmentVariants().length) {
            @if (createItemParams()) {
              <uis-info-box>
                При створенні роботи, якщо тип роботи має визначений набір
                варіантів, автоматично обирається випадковий варіант, ви можете
                змінити обраний варіант вручну або причзначити індивідуальне
                завдання обравши відповідний пункт при виборі варіанту.
              </uis-info-box>
            }
            <uis-form-field label="Варіант">
              <mat-form-field>
                <mat-select
                  formControlName="currentAssignmentVariantId"
                  placeholder="Індивідуальне завдання"
                >
                  <mat-option [value]="null">Індивідуальне завдання</mat-option>
                  @for (
                    assignmentVariant of itemAssignmentVariants();
                    track assignmentVariant.id
                  ) {
                    <mat-option
                      [disabled]="assignmentVariant.rejected"
                      [value]="assignmentVariant.id"
                    >
                      {{ assignmentVariant.name }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </uis-form-field>
          }
        </section>
        <section formArrayName="tasks">
          @for (
            task of $any($any(ispItemForm.controls).tasks.controls);
            track task;
            let taskIndex = $index
          ) {
            <div class="dialog__form-section" [formGroupName]="taskIndex">
              <uis-content-divider>
                <h3 class="dialog__form-section__title">
                  {{
                    IndividualStudyPlanItemTaskTypeText[
                      getAsTaskType(task.value.type)
                    ]
                  }}
                </h3>
              </uis-content-divider>
              <div class="dialog__form-section__content">
                @if (
                  task.value.type ===
                    IndividualStudyPlanItemTaskType.WrittenAssignment &&
                  (can.Update.IndividualStudyPlan.Own() ||
                    dialogData().action === IspTaskDialogAction.Reject)
                ) {
                  <uis-form-field label="Кінцева дата здачі завдання">
                    <uis-datepicker
                      [uisDisableControl]="
                        !createItemParams() &&
                        !dialogData().tasksCanBeEdited?.includes(
                          task.value.type
                        ) &&
                        dialogData().action !== IspTaskDialogAction.Reject
                      "
                      formControlName="date"
                      [min]="calendarStart"
                      [max]="calendarEnd"
                    />
                  </uis-form-field>
                } @else {
                  <uis-info-table
                    [items]="[
                      {
                        label:
                          task.value.type ===
                          IndividualStudyPlanItemTaskType.Interview
                            ? 'Час початку співбесіди'
                            : 'Кінцева дата здачі завдання',
                        displayedValue: (task.value.date | date) ?? ''
                      }
                    ]"
                  />
                }
                <uis-control-error-container formGroupName="assignment">
                  <uis-form-field label="Опис завдання">
                    <mat-form-field>
                      <textarea
                        [uisDisableControl]="
                          currentVariantId() ||
                          (!createItemParams() &&
                            !dialogData().tasksCanBeEdited?.includes(
                              task.value.type
                            ) &&
                            dialogData().action !== IspTaskDialogAction.Reject)
                        "
                        matInput
                        formControlName="description"
                      ></textarea>
                    </mat-form-field>
                  </uis-form-field>
                  <uis-form-field
                    label="Матеріали"
                    [tooltip]="
                      currentVariantId() ||
                      (!createItemParams() &&
                        !dialogData().tasksCanBeEdited?.includes(
                          task.value.type
                        ))
                        ? 'Ви можете переглянути завантажений файл натиснуваши на його назву навіть якщо поле завантаження не активне'
                        : ''
                    "
                  >
                    <uis-file-input
                      [uisDisableControl]="
                        currentVariantId() ||
                        (!createItemParams() &&
                          !dialogData().tasksCanBeEdited?.includes(
                            task.value.type
                          ) &&
                          dialogData().action !== IspTaskDialogAction.Reject)
                      "
                      formControlName="attachments"
                      [fileType]="IspTaskFile.Assignment"
                    />
                  </uis-form-field>
                </uis-control-error-container>
              </div>
            </div>
          }
        </section>
      }
    }
  </mat-dialog-content>
  <mat-dialog-actions class="dialog__actions">
    @if (!(itemAssignmentVariantsLoading$ | async)) {
      <uis-button
        size="small"
        buttonColor="gray"
        buttonFill="content"
        [mat-dialog-close]="null"
        >{{ texts().declineButton }}
      </uis-button>
      <uis-button size="small" type="submit"
        >{{ texts().submitButton }}
      </uis-button>
      @if (isDeleteButton()) {
        <uis-button
          size="extra-small"
          buttonColor="danger"
          buttonFill="outline"
          (click)="onDelete()"
          >Видалити роботу
        </uis-button>
      }
    }
  </mat-dialog-actions>
</form>
