import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { fadeInDownOnEnterAnimation } from 'angular-animations';

const SUBSCRIPT_ANIMATION_DURATION = 50;

@Component({
  selector: 'uis-form-control-subscript',
  standalone: true,
  imports: [],
  templateUrl: './form-control-subscript.component.html',
  styleUrl: './form-control-subscript.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInDownOnEnterAnimation({
      duration: SUBSCRIPT_ANIMATION_DURATION,
    }),
  ],
})
export class FormControlSubscriptComponent {
  @Input({ required: true }) error?: string | null | undefined;
  @Input({ required: true }) errorVisible?: boolean | null | undefined;
  @Input() hint: string | null | undefined;
}
