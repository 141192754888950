import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIS_DIALOG_DATA } from '@uis-common/dialog-container/dialog-container.component';
import { JoinApplication } from '@uis-models/contract/join-application';
import { UisFormGroup } from '@uis-core/forms/uis-form-group';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UisValidators } from '@uis-core/validators/validators';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ButtonComponent } from '@uis-common/button/button.component';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MessageService } from '@uis-services/message/message.service';
import { JoinApplicationService } from '@uis-services/join-application/join-application.service';
import { IspInfoBoxComponent } from '@uis-common/info-box/isp-info-box.component';

@Component({
  selector: 'uis-join-application-view-form-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    ButtonComponent,
    MatDialogClose,
    ReactiveFormsModule,
    FormFieldComponent,
    MatFormField,
    MatInput,
    MatOption,
    MatSelect,
    MatDialogTitle,
    IspInfoBoxComponent,
  ],
  templateUrl: './join-application-view-form-dialog.component.html',
  styleUrl: './join-application-view-form-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinApplicationViewFormDialogComponent {
  private readonly message = inject(MessageService);
  private readonly dialogRef = inject(MatDialogRef);
  private readonly joinApplicationService = inject(JoinApplicationService);
  protected readonly dialogData = signal(
    inject<JoinApplicationViewFormDialogContext>(UIS_DIALOG_DATA),
  );
  protected readonly classLevelOptions = Array.from(
    { length: 11 },
    (_, index) => index + 1,
  );
  protected readonly title = computed(() => {
    const action = this.dialogData().action;
    switch (action) {
      case JoinApplicationViewFromDialogAction.Reject:
        return 'Відхилення заявки';
      case JoinApplicationViewFromDialogAction.Correction:
        return 'Доопрацювання заявки';
      case JoinApplicationViewFromDialogAction.Approve:
        return 'Прийняття заявки';
    }
  });

  protected readonly rejectionForm = new UisFormGroup({
    reason: new FormControl(null, [
      UisValidators.required(),
      UisValidators.maxLength(2000),
    ]),
  });

  protected readonly correctionForm = new UisFormGroup({
    reason: new FormControl(null, [
      UisValidators.required(),
      UisValidators.maxLength(2000),
    ]),
  });

  protected readonly approveForm = new UisFormGroup({
    classLevel: new FormControl(
      this.dialogData().joinApplication.classLevel ?? null,
      UisValidators.required(),
    ),
  });

  onConfirm() {
    switch (this.dialogData().action) {
      case 'reject':
        if (!this.rejectionForm.validate()) {
          this.message.invalidForm();
          return;
        }
        this.joinApplicationService
          .rejectJoinApplication(
            this.dialogData().joinApplication.id,
            this.rejectionForm.value.reason!,
          )
          .subscribe(() => this.dialogRef.close(true));
        break;
      case 'correction':
        if (!this.correctionForm.validate()) {
          this.message.invalidForm();
          return;
        }
        this.joinApplicationService
          .requestCorrectionForJoinApplication(
            this.dialogData().joinApplication.id,
            this.correctionForm.value.reason!,
          )
          .subscribe(() => this.dialogRef.close(true));
        break;
      case 'approve':
        if (!this.approveForm.validate()) {
          this.message.invalidForm();
          return;
        }
        this.joinApplicationService
          .approveJoinApplication(
            this.dialogData().joinApplication.id,
            this.approveForm.value.classLevel!,
          )
          .subscribe(() => this.dialogRef.close(true));
    }
  }

  protected readonly JoinApplicationViewFromDialogAction =
    JoinApplicationViewFromDialogAction;
}

export interface JoinApplicationViewFormDialogContext {
  joinApplication: JoinApplication;
  action: JoinApplicationViewFromDialogAction;
}

export enum JoinApplicationViewFromDialogAction {
  Reject = 'reject',
  Correction = 'correction',
  Approve = 'approve',
}
