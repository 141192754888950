import { User } from '@uis-models/contract/user';

export enum MovementsTypes {
  Transfer = 'transfer',
  Detachment = 'detachment',
  Attachment = 'attachment',
}

export interface MovedStudent {
  date: string;
  detachmentPlace: DetachmentPlace;
  doneByUserId: string;
  id: string;
  isArchived: boolean;
  moveType: MovementsTypes;
  originClassLevel: number;
  studentId: string;
  studentName: string;
}

export interface StudentsMovementsDialogContext {
  dialogType: MovementsTypes;
  selectedUsers: User[] | MovedStudent[];
  classLevel: number;
  hasGraduation?: boolean;
}

export interface TransferRequest {
  studentIds: string[];
  date: string;
  academicYearId: string;
  isPedagogicalDecisionNeeded?: boolean;
  classLevel: number;
}

export interface AttachRequest {
  studentId: string;
  date: string;
  classLevel: number;
  academicYearId: string;
}

interface DetachmentPlace {
  isUkraine: boolean;
  schoolName: string;
  country: string;
  city: string;
}

export interface DetachRequest {
  studentIds: string[];
  date: string;
  detachmentPlace?: DetachmentPlace;
  isPedagogicalDecisionNeeded?: boolean;
}
