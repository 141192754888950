@if ((isOnline | async) !== true) {
  <div
    [@slideInDownOnEnter]="true"
    [@slideOutUpOnLeave]="true"
    class="connection-message connection-message_offline"
  >
    Увага, відсутнє підключення до інтернету! Перевірте стан вашої мережі
  </div>
}
@if (showConnectionRestoredMessage$ | async) {
  <div
    [@slideInDownOnEnter]="true"
    [@slideOutUpOnLeave]="true"
    class="connection-message connection-message_online"
  >
    Підключення відновлено!
  </div>
}
