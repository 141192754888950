import { Permission } from '@uis-services/role-data/role-data.types';

export type UisRouteConfig<RouteNamesEnum extends string> = {
  readonly permissions: Record<RouteNamesEnum, readonly Permission[]>;
  readonly routePathPart: Record<RouteNamesEnum, string>;
  readonly absolutePath: Record<RouteNamesEnum, AbsoluteRouteFn>;
};

export type AbsoluteRoute = `/${string}`;
export type AbsoluteRouteFn = (...a: any[]) => AbsoluteRoute;
type ReplaceReturnType<T extends (...a: any) => any, TNewReturn> = (
  ...a: Parameters<T>
) => TNewReturn;

export function absoluteRoute<T extends (...a: any) => string>(
  getPath: T,
): ReplaceReturnType<T, AbsoluteRoute> {
  return (...args: string[]) => {
    const path = getPath(...args);
    return (path.startsWith('/') ? path : `/${path}`) as AbsoluteRoute;
  };
}

export function getRouteInfo<T extends string>(
  config: UisRouteConfig<T>,
  routeName: T,
) {
  return {
    permissions: config.permissions[routeName],
    routePathPart: config.routePathPart[routeName],
    absolutePath: config.absolutePath[routeName],
  };
}

export type RouteQueryParams<TQueryParams = any> = {
  [key in keyof Partial<TQueryParams>]: string;
};
