import { DefinedRoute, DefinedRoutes } from 'src/app/core/types/defined-routes';
import { userOnlyCanActivateGuard } from '@uis-core/guards/user-only-can-activate.guard';
import { isRegistrationCompleteCanActivateGuard } from '@uis-core/guards/is-registration-complete.guard';
import { NOT_FOUND_ROUTE_PATH } from './content/pages/not-found/not-found.routes';
import { PublicRoute } from '@uis-public/public.routes';
import { wildcardPreserveRouteGuard } from '@uis-core/guards/wildcard-preserve-route.guard';

export const redirectOnEmptyRoute = (redirectTo: string) =>
  ({
    path: '',
    redirectTo: redirectTo,
    pathMatch: 'full',
  }) as DefinedRoute;

export const APP_ROUTES: DefinedRoutes = [
  redirectOnEmptyRoute(PublicRoute.SignIn),
  {
    path: '',
    canActivateChild: [
      userOnlyCanActivateGuard,
      isRegistrationCompleteCanActivateGuard,
    ],
    loadChildren: () =>
      import('src/app/content/pages/private/private.routes').then(
        (r) => r.PRIVATE_ROUTES,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('src/app/content/pages/public/public.routes').then(
        (r) => r.PUBLIC_ROUTES,
      ),
  },
  {
    path: '**',
    canActivate: [wildcardPreserveRouteGuard],
    children: [
      // Redirect is actually done by the guard,
      // but we still need this since angular requires it
      { path: '', pathMatch: 'prefix', redirectTo: NOT_FOUND_ROUTE_PATH },
    ],
  },
];
