import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  Input,
  signal,
} from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DisableControlDirective } from '@uis-directives/disable-control/disable-control.directive';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import { AddressService } from '@uis-services/address/address.service';
import { TrimValueDirective } from '@uis-directives/trim-value/trim-value.directive';
import { UisFormGroup } from '@uis-core/forms/uis-form-group';
import { getUserUkrainianAddressFormControls } from '@uis-models/contract/user-address';

@Component({
  selector: 'uis-ukrainian-address-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormFieldComponent,
    MatInputModule,
    MatAutocompleteModule,
    DisableControlDirective,
    TrimValueDirective,
  ],
  templateUrl: './ukrainian-address-form.component.html',
  styleUrls: ['./ukrainian-address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UkrainianAddressFormComponent {
  private readonly addresses = inject(AddressService);

  @Input({ alias: 'ukrainianAddressForm', required: true })
  set ukrainianAddressFormInput(
    form: UisFormGroup<ReturnType<typeof getUserUkrainianAddressFormControls>>,
  ) {
    this.ukrainianAddressForm.set(form);
  }

  protected readonly ukrainianAddressForm = signal<
    | UisFormGroup<ReturnType<typeof getUserUkrainianAddressFormControls>>
    | undefined
  >(undefined);

  //to use in template ngIf to create individual scopes for same-named template variables
  protected readonly forScopeCreation = true;

  private readonly ukrainianRegionValue = toSignal(
    toObservable(this.ukrainianAddressForm).pipe(
      switchMap((form) =>
        form
          ? (form.controls.region.valueChanges.pipe(
              startWith(form.controls.region.value),
            ) as unknown as Observable<string>)
          : of(null),
      ),
    ),
    { initialValue: null },
  );

  private readonly ukrainianSelectedRegionId$ = toObservable(
    this.ukrainianRegionValue,
  ).pipe(
    switchMap((regionName) =>
      regionName
        ? this.addresses
            .getUkrainianRegions(regionName)
            .pipe(map((res) => res.data.at(0)?.value ?? ''))
        : of(''),
    ),
  );

  private readonly ukrainianRegionChangeEffect = effect(
    () => {
      this.ukrainianRegionValue();
      this.ukrainianRegionOptionsNameQuery$.next('');
      if (!this.ukrainianRegionValue()) {
        this.ukrainianAddressForm()?.controls.city.setValue(null);
      }
    },
    { allowSignalWrites: true },
  );

  protected readonly ukrainianRegionOptionsNameQuery$ =
    new BehaviorSubject<string>('');
  protected readonly ukrainianRegionOptions = toSignal(
    this.ukrainianRegionOptionsNameQuery$.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((name) => this.addresses.getUkrainianRegions(name)),
      map((res) => res.data),
    ),
    { initialValue: [] },
  );

  protected onUkrainianRegionOptionSelected() {
    this.ukrainianCityOptionsNameQuery$.next('');
    this.ukrainianAddressForm()?.controls.city.setValue(null);
  }

  protected readonly ukrainianCityOptionsNameQuery$ =
    new BehaviorSubject<string>('');
  protected readonly ukrainianCityOptions = toSignal(
    combineLatest({
      name: this.ukrainianCityOptionsNameQuery$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
      ),
      regionId: this.ukrainianSelectedRegionId$,
    }).pipe(
      switchMap((query) =>
        query.regionId
          ? this.addresses
              .getUkrainianCities({
                regionId: query.regionId,
                name: query.name,
              })
              .pipe(map((res) => res.data))
          : of([]),
      ),
    ),
    { initialValue: [] },
  );

  private readonly ukrainianCityValue = toSignal(
    toObservable(this.ukrainianAddressForm).pipe(
      switchMap((form) =>
        form
          ? form.controls.city.valueChanges.pipe(
              startWith(form.controls.city.value),
            )
          : of(null),
      ),
    ),
    { initialValue: null },
  );

  private readonly ukrainianCityChangeEffect = effect(
    () => {
      this.ukrainianCityValue();
      this.ukrainianCityOptionsNameQuery$.next('');
    },
    { allowSignalWrites: true },
  );
}
