import { absoluteRoute, UisRouteConfig } from '@uis-types/uis-route-config';
import { PERMISSIONS } from '@uis-services/role-data/permissions';
import { UsersFormRouterInputs } from '@uis-private/users/users-form/users-form.component';

// This is stored in a separate file since importing .routes file with components and guards
// can cause circular imports issues, while navigation links in components should be build using
// .absolutePath option from this structure

export enum UsersRouteName {
  List = 'list',
  User = 'user',
  CreateEmployee = 'create-employee',
  CreateStudent = 'create-student',
  EditUser = 'editUser',
}

export const UsersRoutesConfig: UisRouteConfig<UsersRouteName> = {
  permissions: {
    [UsersRouteName.List]: [
      PERMISSIONS.Read.UserSearch.All,
      PERMISSIONS.Read.Role.All,
    ],
    [UsersRouteName.User]: [PERMISSIONS.Read.User.All],
    [UsersRouteName.CreateEmployee]: [PERMISSIONS.Create.User.All],
    [UsersRouteName.CreateStudent]: [PERMISSIONS.Create.User.All],
    [UsersRouteName.EditUser]: [PERMISSIONS.Update.User.All],
  },
  routePathPart: {
    [UsersRouteName.List]: 'users',
    [UsersRouteName.CreateEmployee]: 'create-employee',
    [UsersRouteName.CreateStudent]: 'create-student',
    [UsersRouteName.User]: `:${UsersFormRouterInputs.UserId}`,
    [UsersRouteName.EditUser]: 'edit',
  },
  absolutePath: {
    [UsersRouteName.List]: absoluteRoute(
      () => `${UsersRoutesConfig.routePathPart.list}`,
    ),
    [UsersRouteName.User]: absoluteRoute(
      (userId: string) => `${UsersRoutesConfig.absolutePath.list()}/${userId}`,
    ),
    [UsersRouteName.CreateEmployee]: absoluteRoute(
      () =>
        `${UsersRoutesConfig.absolutePath.list()}/${
          UsersRoutesConfig.routePathPart['create-employee']
        }`,
    ),
    [UsersRouteName.CreateStudent]: absoluteRoute(
      () =>
        `${UsersRoutesConfig.absolutePath.list()}/${
          UsersRoutesConfig.routePathPart['create-student']
        }`,
    ),
    [UsersRouteName.EditUser]: absoluteRoute(
      (userId: string) =>
        `${UsersRoutesConfig.absolutePath.user(userId)}/${
          UsersRoutesConfig.routePathPart.editUser
        }`,
    ),
  },
} as const;
