import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  skip,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  Employee,
  isEmployee,
  isStudent,
  Student,
  StudentCreateRequest,
  User,
  UserRole,
  UserSearchModel,
} from '@uis-models/contract/user';
import { AuthService } from '@uis-services/auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UsersEP } from '@uis-core/enums/endpoints';
import { toSignal } from '@angular/core/rxjs-interop';
import { SearchResult } from '@uis-models/contract/search';
import { KendoDataQuery } from '@uis-core/classes/kendo-data-query';
import { KendoFilterOperator } from '@uis-enums/kendo';
import { UisTrait } from '@uis-enums/permissions';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly http = inject(HttpClient);
  private readonly auth = inject(AuthService);

  /*The implementation is not strictly signal based since during app initialization,
   * until app is not bootstrapped, computed signals are not updated and just contains
   * undefined value, but we need it at initialization time therefore we need to use observables
   * since they behave as expected*/
  public readonly me$ = this.auth.userId$.pipe(
    switchMap((id) =>
      this.reloadCurrentUser$.pipe(switchMap(() => this.getUserById(id))),
    ),
    shareReplay(1),
  );

  private readonly reloadCurrentUser$ = new BehaviorSubject(null);

  reloadCurrentUser() {
    return this.auth.reloadUserInfo().pipe(
      tap(() => {
        this.reloadCurrentUser$.next(null);
      }),
      switchMap(() => this.me$.pipe(skip(1), take(1))),
    );
  }

  public readonly me = toSignal(this.me$);
  public readonly meAsStudent = toSignal(this.me$.pipe(filter(isStudent)));
  public readonly meAsEmployee = toSignal(this.me$.pipe(filter(isEmployee)));

  getUserById(id?: string | null): Observable<User | undefined> {
    return id
      ? this.http
          .get<User>(UsersEP.ById(id))
          .pipe(map((user) => new User(user)))
      : of(undefined);
  }

  getStudentById(id?: string | null): Observable<Student | null> {
    return this.getUserById(id) as Observable<Student | null>;
  }

  getEmployeeById(id?: string | null): Observable<Student | null> {
    return this.getUserById(id) as Observable<Student | null>;
  }

  getAllUsers<T extends User = User>(
    kendoQuery: KendoDataQuery = new KendoDataQuery(0, 0),
  ): Observable<SearchResult<T>> {
    return this.http
      .get<SearchResult<User>>(UsersEP.All(), {
        params: new HttpParams({ fromObject: kendoQuery.query }),
      })
      .pipe(
        map((res) => ({
          total: res.total,
          data: res.data.map((item) => new User(item) as T),
        })),
      );
  }

  getEmployees(
    kendoQuery: KendoDataQuery = new KendoDataQuery(0, 0),
  ): Observable<SearchResult<Employee>> {
    kendoQuery.pushFilters({
      field: 'isStudent' as keyof User,
      operator: KendoFilterOperator.Eq,
      value: false,
    });
    return this.getAllUsers(kendoQuery);
  }

  getEmployeeByName(
    name: string,
    kendoQuery: KendoDataQuery = new KendoDataQuery(0, 0),
  ): Observable<SearchResult<Employee>> {
    const filters = [
      {
        field: 'isStudent' as keyof User,
        operator: KendoFilterOperator.Eq,
        value: false,
      },
      {
        field: 'fullName' as keyof User,
        operator: KendoFilterOperator.Contains,
        value: name,
      },
    ];

    kendoQuery.pushFilters(filters);
    return this.getAllUsers(kendoQuery);
  }

  getTeacherClasses(id: string) {
    return this.http.get<SearchResult<{ classLevel: number }>>(
      UsersEP.TeacherClasses(id),
    );
  }

  getStudents(kendoQuery: KendoDataQuery = new KendoDataQuery(0, 0)) {
    kendoQuery.pushFilters({
      field: 'isStudent' as keyof User,
      operator: KendoFilterOperator.Eq,
      value: true,
    });
    return this.getAllUsers(kendoQuery);
  }

  getUsersByTraits(
    traits: UisTrait | readonly UisTrait[],
    kendoQuery: KendoDataQuery = new KendoDataQuery<UserSearchModel>(),
  ) {
    const traitsArray = Array.isArray(traits) ? traits : [traits];

    if (traitsArray.length) {
      kendoQuery.pushFilters({
        field: 'traits',
        operator: KendoFilterOperator.In,
        value: traitsArray,
      });
    }

    return this.getAllUsers(kendoQuery);
  }

  createEmployee(createEmployeeRequest: Partial<Employee>) {
    return this.http.post<Employee>(
      UsersEP.CreateEmployee(),
      createEmployeeRequest,
    );
  }

  createStudent(createStudentRequest: StudentCreateRequest) {
    return this.http.post<Employee>(
      UsersEP.CreateStudent(),
      createStudentRequest,
    );
  }

  updateStudent(original: Student, changes: Partial<Student>) {
    return this.http.put<Student>(UsersEP.Student(original.id), {
      ...original,
      ...changes,
    });
  }

  updateEmployee(original: Employee, changes: Partial<Employee>) {
    return this.http.put<Employee>(UsersEP.Employee(original.id), {
      ...original,
      ...changes,
    });
  }

  updateSelfStudent(user: Student): Observable<Student> {
    return this.updateStudent(this.meAsStudent() ?? ({} as Student), user);
  }

  updateSelfEmployee(user: Employee): Observable<Employee> {
    return this.updateEmployee(this.meAsEmployee() ?? ({} as Employee), user);
  }

  updateEmployeeRoles(id: string, newRoles: UserRole[]) {
    return this.http.put<Employee>(UsersEP.EmployeeRoles(id), newRoles);
  }

  deleteUser(id: string) {
    return this.http.delete(UsersEP.ById(id));
  }
}
