import {
  AfterViewInit,
  DestroyRef,
  Directive,
  inject,
  Input,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[uisDisableControl]',
  standalone: true,
})
export class DisableControlDirective implements AfterViewInit {
  private readonly ngControl = inject(NgControl);
  private readonly destroyRef = inject(DestroyRef);
  currentState?: boolean;
  viewInit = false;

  @Input() set uisDisableControl(condition: boolean) {
    this.currentState = condition;
    this.applyState(this.currentState);
  }

  @Input() kitKeepDisabledStatus = true;

  ngAfterViewInit() {
    this.ngControl.control?.statusChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.kitKeepDisabledStatus) {
          this.reapplyState();
        }
      });
    this.viewInit = true;
    if (this.currentState !== undefined) {
      this.applyState(this.currentState);
    }
  }

  applyState(disable?: boolean) {
    if (!this.viewInit || typeof disable === 'undefined') {
      return;
    }
    if (disable && this.ngControl.control?.disabled) {
      return;
    }
    if (!disable && this.ngControl.control?.enabled) {
      return;
    }

    if (disable) {
      this.ngControl.control!.disable();
    } else {
      this.ngControl.control!.enable();
    }
  }

  reapplyState() {
    this.applyState(this.currentState);
  }
}
