import {
  Permission,
  PermissionsStructure,
} from '@uis-services/role-data/role-data.types';
import {
  PermissionTarget,
  PermissionTargetSpecifier,
  PermissionType,
} from '@uis-core/enums/permissions';
import { DeepMutable, DeepPartial } from '@uis-types/utility';

export const PERMISSION_SEPARATOR = ':';

/* Permission builder object built from permission structure enums
 * to be able to get permission string with IDE suggestions like
 * PERMISSIONS.Read.User.Own === 'read:user' and
 * PERMISSIONS.Read.User.All === 'read:user:all'*/
export const PERMISSIONS: PermissionsStructure = (() => {
  function buildPermissionStructure() {
    let permissions = {} as DeepPartial<DeepMutable<PermissionsStructure>>;
    (Object.keys(PermissionType) as (keyof typeof PermissionType)[]).forEach(
      (typeKey) => {
        permissions[typeKey] = {};
        (
          Object.keys(PermissionTarget) as (keyof typeof PermissionTarget)[]
        ).forEach((targetKey) => {
          permissions[typeKey]![targetKey] = {};
          (
            Object.keys(
              PermissionTargetSpecifier,
            ) as (keyof typeof PermissionTargetSpecifier)[]
          ).forEach((specifierKey) => {
            permissions[typeKey]![targetKey]![specifierKey] = buildPermission(
              PermissionType[typeKey],
              PermissionTarget[targetKey],
              PermissionTargetSpecifier[specifierKey],
            );
          });
        });
      },
    );

    return permissions as PermissionsStructure;
  }

  return buildPermissionStructure();
})();

export const POSSIBLE_PERMISSIONS_ARRAY: Permission[] = (() => {
  function buildAllPermissions() {
    const permissions: Permission[] = [];
    Object.values(PermissionType).forEach((type) => {
      Object.values(PermissionTarget).forEach((target) => {
        Object.values(PermissionTargetSpecifier).forEach((specifier) => {
          permissions.push(buildPermission(type, target, specifier));
        });
      });
    });

    return permissions;
  }

  return buildAllPermissions();
})();

export function buildPermission(
  type: PermissionType,
  target: PermissionTarget,
  specifier?: PermissionTargetSpecifier,
): Permission {
  let permission: Permission = `${type}${PERMISSION_SEPARATOR}${target}`;
  if (specifier) {
    permission += `${PERMISSION_SEPARATOR}${specifier}`;
  }
  return permission as Permission;
}
