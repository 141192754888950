import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SearchResult } from '@uis-models/contract/search';
import { UisSubject } from '@uis-models/contract/subject';
import { map, Observable, of } from 'rxjs';
import { SubjectsEP } from '@uis-enums/endpoints';
import { KendoDataQuery } from '@uis-core/classes/kendo-data-query';
import { SearchCall } from '@uis-common/inputs/chip-list-autocomplete/chip-list-autocomplete.component';
import { KendoFilterOperator, KendoSortDir } from '@uis-enums/kendo';

@Injectable({
  providedIn: 'root',
})
export class SubjectsService {
  private readonly http = inject(HttpClient);

  public getAllSubjects(
    query: KendoDataQuery = new KendoDataQuery(),
  ): Observable<SearchResult<UisSubject>> {
    return this.http.get<SearchResult<UisSubject>>(SubjectsEP.All(), {
      params: new HttpParams({ fromObject: query.query }),
    });
  }

  public readonly getSubjectsChipListAutocompleteCall: SearchCall = (query) => {
    query.pushSorts({
      field: 'name' as keyof UisSubject,
      dir: KendoSortDir.Asc,
    });
    return this.getAllSubjects(query);
  };

  public getSubjectById(subjectId: string | null | undefined) {
    if (!subjectId) {
      return of(null);
    }

    const kendoQuery = new KendoDataQuery();
    kendoQuery.pushFilters({
      field: 'id',
      operator: KendoFilterOperator.Eq,
      value: subjectId,
    });
    return this.getAllSubjects(kendoQuery).pipe(
      map((res) => res.data[0] ?? null),
    );
  }
}
