import { inject, Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogContainerComponent } from '@uis-common/dialog-container/dialog-container.component';
import {
  DefaultDialogComponent,
  UisDefaultDialogParams,
} from '@uis-common/default-dialog/default-dialog.component';
import { IspItemFormDialogComponent } from '@uis-private/individual-study-plan/dialogs/isp-item-form-dialog/isp-item-form-dialog.component';
import { IspTaskViewDialogComponent } from '@uis-private/individual-study-plan/dialogs/isp-task-view-dialog/isp-task-view-dialog.component';
import { IspDialogContext } from '@uis-private/individual-study-plan/dialogs/isp-dialog.types';
import { defer, take } from 'rxjs';
import {
  JoinApplicationViewFormDialogComponent,
  JoinApplicationViewFormDialogContext,
} from '@uis-private/join-application/view/components/join-application-view-form-dialog/join-application-view-form-dialog.component';
import { StudentsMovementsDialogComponent } from '@uis-private/uis-classes/dialogs/students-movements/students-movements-dialog.component';
import { StudentsMovementsDialogContext } from '@uis-private/uis-classes/dialogs/movements.models';
import { PaymentsDialogComponent } from '@uis-private/common/profile-view/dialogs/payments-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private readonly matDialogService = inject(MatDialog);

  public openDialogs = this.matDialogService.openDialogs;

  public closeAll() {
    return defer(() => {
      this.matDialogService.closeAll();
      return this.matDialogService.afterAllClosed.pipe(take(1));
    });
  }

  public open<T>(params: UisDialogWrapperParams<T>) {
    return this.matDialogService.open(DialogContainerComponent, {
      data: params,
      disableClose: params.disableClose,
      autoFocus: '#primaryButton button',
    });
  }

  public openDefault<T>(params: UisDefaultDialogParams<T>) {
    return this.open({
      component: DefaultDialogComponent,
      dialogData: params,
      disableClose: params.disableClose,
    }).afterClosed();
  }

  public unsavedChanges(text?: string) {
    return this.openDefault({
      title: 'Ви маєте незбережені зміни!',
      text,
      confirmText: 'Залишитись',
      rejectText: 'Покинути сторінку',
      size: 'small',
      disableClose: true,
    });
  }

  public confirm<T>(params: UisDefaultDialogParams<T>) {
    const defaultParams: UisDefaultDialogParams<T> = {
      rejectText: 'Ні',
      confirmText: 'Так',
    };
    return this.openDefault({ ...defaultParams, ...params });
  }

  public openIspItemFormDialog(context: IspDialogContext) {
    return this.open({
      component: IspItemFormDialogComponent,
      dialogData: context,
    }).afterClosed();
  }

  public openIspTakViewDialog(params: IspDialogContext) {
    return this.open({
      component: IspTaskViewDialogComponent,
      dialogData: params,
    }).afterClosed();
  }

  public openJoinApplicationViewFormDialog(
    params: JoinApplicationViewFormDialogContext,
  ) {
    return this.open({
      component: JoinApplicationViewFormDialogComponent,
      dialogData: params,
    }).afterClosed();
  }

  public openMovementsDialog(context: StudentsMovementsDialogContext) {
    return this.open({
      component: StudentsMovementsDialogComponent,
      dialogData: context,
    }).afterClosed();
  }

  public openPaymentsDialog() {
    return this.open({
      component: PaymentsDialogComponent,
    }).afterClosed();
  }
}

export interface UisDialogWrapperParams<T> {
  component: Type<any>;
  dialogData?: T;
  disableClose?: boolean;
}
