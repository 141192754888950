@if (label) {
  <label class="form-field__label">
    <span class="form-field__label__title-container">
      <span class="form-field__label__text">{{ label }}</span>
      @if (required()) {
        <span
          class="form-field__label__required-marker"
          matTooltip="Обов'язкове поле"
          matTooltipPosition="above"
          >*</span
        >
      }
    </span>
    @if (tooltip) {
      <mat-icon
        inline
        [matTooltip]="tooltip"
        matTooltipPosition="above"
        class="form-field__label__info material-icons-outlined"
        >info
      </mat-icon>
    }
  </label>
}
<ng-content></ng-content>
<uis-form-control-subscript
  [error]="firstValidationErrorMessage()"
  [errorVisible]="isErrorVisible$ | async"
  [hint]="hint"
/>
