import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import {
  concat,
  delay,
  filter,
  map,
  merge,
  of,
  skip,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import {
  slideInDownOnEnterAnimation,
  slideOutUpOnLeaveAnimation,
} from 'angular-animations';

@Component({
  selector: 'uis-online-status',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './online-status.component.html',
  styleUrls: ['./online-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slideInDownOnEnterAnimation({ duration: 100 }),
    slideOutUpOnLeaveAnimation({ duration: 100 }),
  ],
})
export class OnlineStatusComponent {
  onlineStatusService = inject(OnlineStatusService);

  isOnline = this.onlineStatusService.status.pipe(
    startWith(this.onlineStatusService.getStatus()),
    map((status) => status === OnlineStatusType.ONLINE),
  );

  showConnectionRestoredMessage$ = this.isOnline.pipe(
    skip(1),
    filter((status) => status),
    switchMap(() =>
      concat(
        of(true),
        merge(
          this.isOnline.pipe(filter((isOnline) => !isOnline)),
          of(false).pipe(delay(3000)),
        ).pipe(take(1)),
      ),
    ),
  );
}
