@if (files().length) {
  @for (file of files(); track file.id) {
    @if (file.changes$ | async; as fileState) {
      <div
        class="file-list__item"
        (click)="onFileClick(fileState)"
        title="{{ fileState.fileName }}"
      >
        <uis-file-icon [file]="fileState" />
        <div class="file-list__item__info">
          <span class="file-list__item__info__title">{{
            fileState.fileName
          }}</span>
          <div class="file-list__item__info__main">
            <span class="file-list__item__info__main__size">{{
              fileState.fileSize | fileSize
            }}</span>
            <span class="file-list__item__info__main__date">{{
              fileState.createdAt | date: 'dd.MM.YYYY HH:mm'
            }}</span>
          </div>
        </div>
      </div>
    }
  }
} @else {
  <span class="file-list__no-items">Немає файлів</span>
}
