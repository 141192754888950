import { DefinedRoute } from '@uis-types/defined-routes';

export const NOT_FOUND_ROUTE_PATH = '404';

export const NOT_FOUND_ROUTE: DefinedRoute = {
  path: NOT_FOUND_ROUTE_PATH,
  title: 'Тут нічого нема :(',
  loadComponent: () =>
    import('../not-found/not-found.component').then((c) => c.NotFoundComponent),
};
