import { DefinedRoute } from '@uis-types/defined-routes';
import { permissionsCanMatchGuard } from '@uis-core/guards/permissions-can-match.guard';
import { inject } from '@angular/core';
import { UserService } from '@uis-services/user/user.service';
import { BREADCRUMB_ROUTE_DATA_KEY } from '@uis-services/breadcrumb/breadcrumb.service';
import { Data } from '@angular/router';
import { canDeactivateComponentGuard } from '@uis-core/guards/can-deactivate';
import { PERMISSIONS } from '@uis-services/role-data/permissions';
import { absoluteRoute, UisRouteConfig } from '@uis-types/uis-route-config';
import { ProfileViewRouterInputs } from '@uis-private/common/profile-view/infrastructure';
import { IndividualStudyPlanService } from '@uis-services/individual-study-plan/individual-study-plan.service';

export enum ProfileRouteName {
  Profile = 'profile',
  Edit = 'edit',
}

export const ProfileRoutesConfig: UisRouteConfig<ProfileRouteName> = {
  permissions: {
    [ProfileRouteName.Profile]: [PERMISSIONS.Read.User.Own],
    [ProfileRouteName.Edit]: [
      PERMISSIONS.Read.User.Own,
      PERMISSIONS.Update.User.Own,
    ],
  },
  routePathPart: {
    [ProfileRouteName.Profile]: 'profile',
    [ProfileRouteName.Edit]: `edit`,
  },
  absolutePath: {
    [ProfileRouteName.Profile]: absoluteRoute(
      () => `${ProfileRoutesConfig.routePathPart.profile}`,
    ),
    [ProfileRouteName.Edit]: absoluteRoute(
      () =>
        `${ProfileRoutesConfig.routePathPart.profile}/${ProfileRoutesConfig.routePathPart.edit}`,
    ),
  },
} as const;

export const PROFILE_ROUTES: DefinedRoute = {
  path: ProfileRoutesConfig.routePathPart.profile,
  canMatch: [
    permissionsCanMatchGuard(
      ProfileRoutesConfig.permissions[ProfileRouteName.Profile],
    ),
  ],
  title: 'Мій профіль',
  resolve: {
    userName: () => inject(UserService).me()?.fullName,
  },
  runGuardsAndResolvers: 'always',
  data: {
    [BREADCRUMB_ROUTE_DATA_KEY]: (data: Data) => data['userName'],
  },
  children: [
    {
      path: '',
      loadComponent: () =>
        import('@uis-private/common/profile-view/profile-view.component').then(
          (c) => c.ProfileViewComponent,
        ),
      resolve: {
        [ProfileViewRouterInputs.User]: () => inject(UserService).me(),
        [ProfileViewRouterInputs.IndividualStudyPlan]: () =>
          inject(IndividualStudyPlanService).myIndividualStudyPlan(),
      },
    },
    {
      path: ProfileRoutesConfig.routePathPart.edit,
      canMatch: [
        permissionsCanMatchGuard(
          ProfileRoutesConfig.permissions[ProfileRouteName.Edit],
        ),
      ],
      loadComponent: () =>
        import('@uis-private/profile/profile-edit/profile-edit.component').then(
          (c) => c.ProfileEditComponent,
        ),
      title: 'Редагування',
      canDeactivate: [canDeactivateComponentGuard],
    },
  ],
};
