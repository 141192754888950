import { FormControl } from '@angular/forms';
import { UisValidators } from '@uis-core/validators/validators';
import { UisRegExp } from '@uis-core/constants/uis-reg-exp';

export interface UserAddress {
  country?: string;
  region?: string;
  city?: string;
  street?: string;
  building?: string;
  apartment?: string;
}

export interface AddressResponseItem {
  name: string;
  value: string;
}

export function getUserUkrainianAddressFormControls() {
  return {
    region: new FormControl(null, [UisValidators.required()]),
    city: new FormControl(null, [UisValidators.required()]),
    street: new FormControl(null, [
      UisValidators.required(),
      UisValidators.minMaxLength(2, 64),
    ]),
    building: new FormControl(null, [
      UisValidators.required(),
      UisValidators.minMaxLength(1, 16),
    ]),
    apartment: new FormControl(null, [UisValidators.minMaxLength(1, 16)]),
  };
}

export function getUserForeignAddressFormControls() {
  return {
    country: new FormControl(null, [UisValidators.required()]),
    city: new FormControl(null, [
      UisValidators.required(),
      UisValidators.pattern(UisRegExp.NotContainsLatin),
      UisValidators.minMaxLength(2, 64),
    ]),
  };
}
