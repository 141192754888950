import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'uis-info-box',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './isp-info-box.component.html',
  styleUrl: './isp-info-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IspInfoBoxComponent {
  title = input('Зверніть увагу!');
}
