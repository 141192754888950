import { UisFormGroup } from './uis-form-group';
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  ValidatorFn,
} from '@angular/forms';

export class UisFormArray extends FormArray {
  protected _baseControl?: UisFormGroup<any>;
  protected _getBaseControl?: () => UisFormGroup<any>;

  constructor(
    controls: AbstractControl[],
    baseControl: UisFormGroup<any> | (() => UisFormGroup<any>),
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(controls, validatorOrOpts, asyncValidator);
    if (typeof baseControl === 'function') {
      this._getBaseControl = baseControl;
    } else {
      this._baseControl = baseControl;
    }
  }

  addControl(
    index?: number,
    options?: {
      emitEvent?: boolean;
    },
  ): void {
    typeof index === 'number'
      ? this.insert(
          index!,
          this._baseControl
            ? this._baseControl.clone()
            : this._getBaseControl!(),
          options,
        )
      : this.push(
          this._baseControl
            ? this._baseControl.clone()
            : this._getBaseControl!(),
          options,
        );
  }

  validate(): boolean {
    this.controls.forEach((control) => {
      (control as UisFormGroup<any>).validate();
    });
    return this.controls.some((x) => x.valid);
  }
}
