export enum PermissionType {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export const PermissionTypeDisplayName: {
  [key in PermissionType]: string;
} = {
  [PermissionType.Create]: 'Створювати',
  [PermissionType.Read]: 'Бачити',
  [PermissionType.Update]: 'Змінювати',
  [PermissionType.Delete]: 'Видаляти',
} as const;

export enum PermissionTarget {
  RoleData = 'role-data',
  Permission = 'permission',
  Trait = 'trait',
  User = 'user',
  UserSearch = 'user-search',
  Subject = 'subject',
  Role = 'role',
  Password = 'password',
  Attachment = 'attachment',
  Class = 'class',
  ClassCuratorChangelog = 'class-curator-changelog',
  JoinApplication = 'join-application',
  JoinApplicationStatus = 'join-application-status',
  EvaluationSchedule = 'evaluation-schedule',
  EvaluationScheduleTask = 'evaluation-schedule-task',
  EvaluationScheduleTaskAnswer = 'evaluation-schedule-task-answer',
  EducationalCenter = 'educational-center',
  IndependentWork = 'independent-work',
  Address = 'address',
  IndividualStudyPlan = 'individual-study-plan',
  IndividualStudyPlanItem = 'individual-study-plan-item',
  IndividualStudyPlanItemAssignment = 'individual-study-plan-item-assignment',
  IndividualStudyPlanItemAnswerTask = 'individual-study-plan-item-answer-task',
  IndividualStudyPlanItemReject = 'individual-study-plan-item-reject',
  IndividualStudyPlanItemStartTask = 'individual-study-plan-item-start-task',
  IndividualStudyPlanItemRequestTaskCorrection = 'individual-study-plan-item-request-task-correction',
  IndividualStudyPlanItemAssessTask = 'individual-study-plan-item-request-assess-task',
  IndividualStudyPlanItemInterviewDate = 'individual-study-plan-item-interview-date',
  IndividualStudyPlanItemFinishInterview = 'individual-study-plan-item-finish-interview',
  AssignmentVariant = 'assignment-variant',
  MyGrades = 'my-grades',
}

export const PermissionTargetDisplayName: {
  [key in PermissionTarget]: string;
} = {
  [PermissionTarget.RoleData]: 'Деталі ролей',
  [PermissionTarget.Permission]: 'Дозволи',
  [PermissionTarget.Trait]: 'Характеристики',
  [PermissionTarget.User]: 'Всі Профілі',
  [PermissionTarget.UserSearch]: 'Пошук користувачів',
  [PermissionTarget.Subject]: 'Предмети',
  [PermissionTarget.Role]: 'Ролі',
  [PermissionTarget.Password]: 'Пароль',
  [PermissionTarget.Attachment]: 'Додатки',
  [PermissionTarget.Class]: 'Класи',
  [PermissionTarget.ClassCuratorChangelog]: 'Журнал змін куратора класу',
  [PermissionTarget.JoinApplication]: 'Заявки на вступ',
  [PermissionTarget.JoinApplicationStatus]: 'Статус заявок на вступ',
  [PermissionTarget.EvaluationSchedule]: 'Графіки оцінювання',
  [PermissionTarget.EvaluationScheduleTask]: 'Завдання графіків оцінювання',
  [PermissionTarget.EvaluationScheduleTaskAnswer]:
    'Відповіді завдань графіків оцінювання',
  [PermissionTarget.EducationalCenter]: 'Навчальні центри',
  [PermissionTarget.IndependentWork]: 'Самостійні роботи',
  [PermissionTarget.Address]: 'Адреси',
  [PermissionTarget.IndividualStudyPlan]: 'Індивідуальні навчальні плани',
  [PermissionTarget.IndividualStudyPlanItem]:
    'Роботи в індивідуальних навчальних планах',
  [PermissionTarget.IndividualStudyPlanItemAssignment]:
    'Завдання в роботах в індивідуальних навчальних планах',
  [PermissionTarget.IndividualStudyPlanItemAnswerTask]:
    'Відповідь на завдання в індивідуальних навчальних планах',
  [PermissionTarget.IndividualStudyPlanItemReject]:
    'Відхилення роботи в індивідуальних навчальних планах',
  [PermissionTarget.IndividualStudyPlanItemStartTask]:
    'Виконання завдання індивідуальних навчальних планах',
  [PermissionTarget.IndividualStudyPlanItemRequestTaskCorrection]:
    'Запит на коригування завдання в індивідуальних навчальних планах',
  [PermissionTarget.IndividualStudyPlanItemAssessTask]:
    'Оцінювання завдання в індивідуальних навчальних планах',
  [PermissionTarget.IndividualStudyPlanItemInterviewDate]:
    'Дата проведення співбесіди в роботі в індивідуальних навчальних планах',
  [PermissionTarget.IndividualStudyPlanItemFinishInterview]:
    'Завершення співбесіди',
  [PermissionTarget.AssignmentVariant]: 'Варіант завдання',
  [PermissionTarget.MyGrades]: 'Мої оцінки',
} as const;

export enum PermissionTargetSpecifier {
  All = 'all',
  Own = '',
}

export const PermissionTargetSpecifierDisplayName: {
  [key in PermissionTargetSpecifier]: string;
} = {
  [PermissionTargetSpecifier.All]: 'Всі',
  [PermissionTargetSpecifier.Own]: 'Власні',
} as const;

export enum UisTrait {
  CanBeClassCurator = 'can-be-class-curator',
  MustHaveSubjects = 'must-have-subjects',
}

export const UisTraitDisplayName: {
  [key in UisTrait]: string;
} = {
  [UisTrait.CanBeClassCurator]: 'Може бути куратором класу',
  [UisTrait.MustHaveSubjects]: `Має мати предмети`,
};
