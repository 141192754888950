export enum JoinApplicationFile {
  AuthorizedPersonsStatement = 'authorized-persons-statement',
  IdentityDocument = 'identity-document',
  ResidenceDocument = 'residence-document',
  StudentPersonalFile = 'student-personal-file',
  BasicEducationCertificate = 'basic-education-certificate',
  FirstSemFinalMarks = 'first-sem-final-marks',
  AcademicReference = 'academic-reference',
}

export enum UserProfileFile {
  ProfilePicture = 'user-profile-picture',
}

export enum IspTaskFile {
  Assignment = 'individual-study-plan-item-task-assignment',
  IndependentWorkResource = 'independent-work-resource',
  Answer = 'individual-study-plan-item-task-assignment-answer',
  ReviewedAnswer = 'individual-study-plan-item-task-assignment-reviewed-answer',
}

export type UisFileType =
  | 'any'
  | JoinApplicationFile
  | UserProfileFile
  | IspTaskFile;
