import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'uis-button',
  standalone: true,
  imports: [CommonModule, RouterLink, MatMenuModule, MatIconModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() disabled?: boolean | null | undefined = false;
  @Input() size: 'medium' | 'big' | 'small' | 'extra-small' | 'slim' = 'medium';
  @Input() buttonColor:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'danger'
    | 'warn'
    | 'gray' = 'secondary';
  @Input() buttonFill: 'content' | 'outline' | 'full' = 'full';
  @Input() buttonStyle?: UisButtonStyleConfig;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() link?: string;
  @Input() menu?: UisButtonMenuOption[];
  @Input() newTab = false;
  @Output() menuItemSelected = new EventEmitter();

  onMenuItemClick(menuItem: UisButtonMenuOption) {
    this.menuItemSelected.emit(menuItem);
  }
}

export type UisButtonMenuOption<T = unknown> = {
  label: string;
  matIcon?: string;
  link?: string;
  value?: T;
  disabled?: boolean;
};

export interface UisButtonStyleConfig {
  color?: ButtonComponent['buttonColor'];
  fill?: ButtonComponent['buttonFill'];
  size?: ButtonComponent['size'];
}
