import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  shareReplay,
  skip,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  IndividualStudyPlan,
  IndividualStudyPlanItem,
  IndividualStudyPlanItemAssessTaskRequest,
  IndividualStudyPlanItemAssignmentVariant,
  IndividualStudyPlanItemRejectRequest,
  IndividualStudyPlanItemTaskCorrectionRequest,
  IndividualStudyPlanItemTaskSubmitAnswerRequest,
  IndividualStudyPlanItemTaskType,
  UpdateInterviewTimeForIndividualStudyPlanItem,
} from '@uis-models/contract/individual-study-plan';
import {
  IndividualStudyPlanAssignmentVariantsEP,
  IndividualStudyPlanItemsEP,
  IndividualStudyPlansEP,
} from '@uis-enums/endpoints';
import { SearchResult } from '@uis-models/contract/search';
import {
  KendoDataQuery,
  KendoFilter,
} from '@uis-core/classes/kendo-data-query';
import { KendoFilterOperator } from '@uis-enums/kendo';
import { UserService } from '@uis-services/user/user.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { RoleDataService } from '@uis-services/role-data/role-data.service';

@Injectable({
  providedIn: 'root',
})
export class IndividualStudyPlanService {
  private readonly http = inject(HttpClient);
  private readonly userService = inject(UserService);
  private readonly can = inject(RoleDataService).can;
  private readonly roleData = inject(RoleDataService);

  private reloadMyIndividualStudyPlan$ = new BehaviorSubject(null);

  public myIndividualStudyPlan$ = this.roleData.userRolesData$.pipe(
    switchMap(() =>
      this.userService.meAsStudent() && this.can.Read.IndividualStudyPlan.Own()
        ? this.reloadMyIndividualStudyPlan$.pipe(
            switchMap(() =>
              this.getIndividualStudyPlanByUserId(
                this.userService.meAsStudent()!.id,
              ),
            ),
          )
        : of(null),
    ),
    shareReplay(1),
  );

  public readonly myIndividualStudyPlan = toSignal(this.myIndividualStudyPlan$);

  reloadMyIndividualStudyPlan() {
    return of(null).pipe(
      tap(() => this.reloadMyIndividualStudyPlan$.next(null)),
      switchMap(() => this.myIndividualStudyPlan$.pipe(skip(1), take(1))),
    );
  }

  public getAllIndividualStudyPlans(
    kendoQuery: KendoDataQuery = new KendoDataQuery(),
  ) {
    return this.http
      .get<
        SearchResult<IndividualStudyPlan>
      >(IndividualStudyPlansEP.All(), kendoQuery.asHttpOptions())
      .pipe(catchError(() => of(new SearchResult())));
  }

  public getIndividualStudyPlanById(
    ispId?: string | null,
  ): Observable<IndividualStudyPlan | null> {
    if (!ispId) {
      return of(null);
    }

    return this.http
      .get<IndividualStudyPlan>(IndividualStudyPlansEP.ById(ispId))
      .pipe(catchError(() => of(null)));
  }

  public getIndividualStudyPlanByUserId(
    userId?: string | null,
  ): Observable<IndividualStudyPlan | null> {
    if (!userId) {
      return of(null);
    }
    const query = new KendoDataQuery();
    query.pushFilters({
      field: 'student.id',
      operator: KendoFilterOperator.Eq,
      value: userId,
    });
    return this.getAllIndividualStudyPlans(query)
      .pipe(
        map((res) =>
          res.data.length ? res.data.find((isp) => isp.isActive) : null,
        ),
      )
      .pipe(catchError(() => of(null)));
  }

  public getIndividualStudyPlansByUserId(
    userId?: string | null,
  ): Observable<IndividualStudyPlan[] | null> {
    if (!userId) {
      return of(null);
    }
    const query = new KendoDataQuery();
    query.pushFilters({
      field: 'student.id',
      operator: KendoFilterOperator.Eq,
      value: userId,
    });
    return this.getAllIndividualStudyPlans(query)
      .pipe(map((res) => (res.data.length ? res.data : null)))
      .pipe(catchError(() => of(null)));
  }

  public getIndividualStudyPlanItem(id?: string | null) {
    if (!id) {
      return of(null);
    }

    return this.http
      .get<IndividualStudyPlanItem>(IndividualStudyPlanItemsEP.ById(id))
      .pipe(catchError(() => of(null)));
  }

  public getAllIndividualStudyPlanItems(
    kendoQuery: KendoDataQuery = new KendoDataQuery<IndividualStudyPlanItem>(),
  ) {
    return this.http
      .get<
        SearchResult<IndividualStudyPlanItem>
      >(IndividualStudyPlanItemsEP.All(), kendoQuery.asHttpOptions())
      .pipe(catchError(() => of(new SearchResult<IndividualStudyPlanItem>())));
  }

  public getIndividualStudyPlanItemsByIspId(ispId?: string | null) {
    const kendoQuery = new KendoDataQuery<IndividualStudyPlanItem>();
    if (!ispId) {
      return of(new SearchResult<IndividualStudyPlanItem>());
    }
    kendoQuery.pushFilters({
      field: 'individualStudyPlanId',
      operator: KendoFilterOperator.Eq,
      value: ispId,
    });
    return this.getAllIndividualStudyPlanItems(kendoQuery);
  }

  public updateIndividualStudyPlan(isp: IndividualStudyPlan) {
    return this.http.put<IndividualStudyPlan>(
      IndividualStudyPlansEP.ById(isp.id),
      isp,
    );
  }

  public setIndividualStudyPlanStudentAccess(
    isp: IndividualStudyPlan,
    isGranted: boolean,
  ) {
    return this.http.put(IndividualStudyPlansEP.StudentAccess(isp.id), {
      isGranted,
    });
  }

  public addIndividualStudyPlanItem(ispItem: IndividualStudyPlanItem) {
    return this.http.post(IndividualStudyPlanItemsEP.All(), ispItem);
  }

  public updateIndividualStudyPlanItem(ispItem: IndividualStudyPlanItem) {
    return this.http.put<IndividualStudyPlanItem>(
      IndividualStudyPlanItemsEP.ById(ispItem.id),
      ispItem,
    );
  }

  public deleteIndividualStudyPlanItem(id: string) {
    return this.http.delete(IndividualStudyPlanItemsEP.ById(id));
  }

  public startIndividualStudyPlanItemTask(
    itemId: string,
    taskType: IndividualStudyPlanItemTaskType,
  ) {
    return this.http.put<IndividualStudyPlanItem>(
      IndividualStudyPlanItemsEP.Start(itemId),
      { type: taskType },
    );
  }

  public finishInterview(itemId: string) {
    return this.http.put<IndividualStudyPlanItem>(
      IndividualStudyPlanItemsEP.FinishInterview(itemId),
      {},
    );
  }

  public rejectIndividualStudyPlanItem(
    itemId: string,
    request: IndividualStudyPlanItemRejectRequest,
  ) {
    return this.http.put<IndividualStudyPlanItem>(
      IndividualStudyPlanItemsEP.Reject(itemId),
      request,
    );
  }

  public submitIndividualStudyPlanItemTaskAnswer(
    itemId: string,
    request: IndividualStudyPlanItemTaskSubmitAnswerRequest,
  ) {
    return this.http.put<IndividualStudyPlanItem>(
      IndividualStudyPlanItemsEP.SubmitTaskAnswer(itemId),
      request,
    );
  }

  public assessIndividualStudyPlanItemTask(
    itemId: string,
    request: IndividualStudyPlanItemAssessTaskRequest,
  ) {
    return this.http.put<IndividualStudyPlanItem>(
      IndividualStudyPlanItemsEP.AssessTask(itemId),
      request,
    );
  }

  public requestCorrectionForIndividualStudyPlanItemTask(
    itemId: string,
    request: IndividualStudyPlanItemTaskCorrectionRequest,
  ) {
    return this.http.put<IndividualStudyPlanItem>(
      IndividualStudyPlanItemsEP.CorrectionNeeded(itemId),
      request,
    );
  }

  public updateInterviewDateForIndividualStudyPlanItem(
    itemId: string,
    request: UpdateInterviewTimeForIndividualStudyPlanItem,
  ) {
    return this.http.put<IndividualStudyPlanItem>(
      IndividualStudyPlanItemsEP.InterviewDate(itemId),
      request,
    );
  }

  public getDefaultAssignmentVariants(
    kendoQuery: KendoDataQuery = new KendoDataQuery(),
  ) {
    return this.http
      .get<
        SearchResult<IndividualStudyPlanItemAssignmentVariant>
      >(IndividualStudyPlanAssignmentVariantsEP.All(), kendoQuery.asHttpOptions())
      .pipe(
        catchError(() =>
          of(new SearchResult<IndividualStudyPlanItemAssignmentVariant>()),
        ),
      );
  }

  public getDefaultAssignmentVariantsForItem(
    classLevel: number,
    item: IndividualStudyPlanItem,
    kendoQuery: KendoDataQuery = new KendoDataQuery(),
  ) {
    const filters: KendoFilter[] = [
      {
        field: 'classLevel',
        operator: KendoFilterOperator.Eq,
        value: classLevel,
      },
      {
        field: 'subjectId',
        operator: KendoFilterOperator.Eq,
        value: item.subjectData.subject?.id,
      },
      {
        field: 'itemType',
        operator: KendoFilterOperator.Eq,
        value: item.type,
      },
    ];

    kendoQuery.pushFilters(filters);

    return this.getDefaultAssignmentVariants(kendoQuery).pipe(
      map((res) => {
        res.data = res.data.map(
          (variant) =>
            ({
              ...variant,
              rejected: item.rejectedAssignmentVariantsIds?.includes(
                variant.id,
              ),
            }) as IndividualStudyPlanItemAssignmentVariant,
        );
        return res;
      }),
    );
  }

  public addAssignmentVariantItem(
    ispItem: Partial<IndividualStudyPlanItemAssignmentVariant>,
  ) {
    return this.http.post<IndividualStudyPlanItemAssignmentVariant>(
      IndividualStudyPlanAssignmentVariantsEP.All(),
      ispItem,
    );
  }

  public updateAssignmentVariantItem(
    itemId: string,
    ispItem: Partial<IndividualStudyPlanItemAssignmentVariant>,
  ) {
    return this.http.put<IndividualStudyPlanItemAssignmentVariant>(
      IndividualStudyPlanAssignmentVariantsEP.ById(itemId),
      ispItem,
    );
  }

  public deleteAssignmentVariantItem(id: string) {
    return this.http.delete(IndividualStudyPlanAssignmentVariantsEP.ById(id));
  }
}
