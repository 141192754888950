import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { WasApplied } from '@uis-core/interceptors/mutation-interceptor/mutation';
import { isUisFile, IUisFile, UisFile } from '@uis-models/contract/uis-file';
import { from } from 'rxjs';
import { MessageService } from '@uis-services/message/message.service';
import { HttpClient } from '@angular/common/http';
import { cloneDeep, cloneDeepWith } from 'lodash-es';

export class UisUtils {
  private static _ISO8601RegExp = new RegExp(
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/,
  );

  public static mutateObjectFields(
    obj: any,
    mapFunc: (value: any, key: string | number, currObject: object) => any,
    predicate: (
      value: any,
      key: string | number,
      currObject: object,
    ) => boolean = () => true,
    recursive: boolean = true,
    currObject: object = obj,
  ): void {
    if (obj === null || obj === undefined || typeof obj !== 'object') {
      return obj;
    }

    for (const key of Object.keys(obj)) {
      const value = obj[key];
      if (predicate(value, key, currObject)) {
        obj[key] = mapFunc(obj[key], key, currObject);
      } else if (recursive && typeof value === 'object') {
        this.mutateObjectFields(value, mapFunc, predicate, true, obj[key]);
      }
    }
  }

  public static dateToString(value: Date): string {
    return value.toISOString();
  }

  public static stringToDate(value: string): Date {
    return new Date(value);
  }

  public static isDateString = (value: any) => {
    return typeof value === 'string'
      ? UisUtils._ISO8601RegExp.test(value)
      : false;
  };

  public static initializeDates(object: object): WasApplied {
    let isMutated = false;
    UisUtils.mutateObjectFields(
      object,
      (value) => {
        isMutated = true;
        return UisUtils.stringToDate(value);
      },
      UisUtils.isDateString,
    );
    return isMutated;
  }

  public static stringifyDates(object: object): WasApplied {
    let isMutated = false;
    UisUtils.mutateObjectFields(
      object,
      (value) => {
        isMutated = true;
        return UisUtils.dateToString(value);
      },
      (value) => value instanceof Date,
    );
    return isMutated;
  }

  public static uisFilesToContract(object: object): WasApplied {
    let isMutated = false;
    UisUtils.mutateObjectFields(
      object,
      (value: UisFile) => {
        isMutated = true;
        return value.asContract;
      },
      (value) => value instanceof UisFile,
    );
    return isMutated;
  }

  public static internalCloneDeep(object: object): WasApplied {
    let isMutated = false;
    UisUtils.mutateObjectFields(
      object,
      (value: object) => {
        isMutated = true;
        return cloneDeep(value);
      },
      (value) => typeof value === 'object',
      false,
    );
    return isMutated;
  }

  public static initUisFiles(
    httpClientFactory: () => HttpClient,
  ): (object: object) => WasApplied {
    return (object: object) => {
      let isMutated = false;
      UisUtils.mutateObjectFields(
        object,
        (value: IUisFile) => {
          isMutated = true;
          return new UisFile(value, value.type, httpClientFactory());
        },
        isUisFile,
      );
      return isMutated;
    };
  }
}

export function romanize(num?: number | string) {
  if (typeof num === 'undefined') {
    return;
  }
  if (isNaN(+num)) {
    return NaN;
  }
  let digits = String(+num).split(''),
    key = [
      '',
      'C',
      'CC',
      'CCC',
      'CD',
      'D',
      'DC',
      'DCC',
      'DCCC',
      'CM',
      '',
      'X',
      'XX',
      'XXX',
      'XL',
      'L',
      'LX',
      'LXX',
      'LXXX',
      'XC',
      '',
      'I',
      'II',
      'III',
      'IV',
      'V',
      'VI',
      'VII',
      'VIII',
      'IX',
    ],
    roman = '',
    i = 3;
  while (i--) {
    roman = (key[+digits!.pop()! + i * 10] || '') + roman;
  }
  return Array(+digits.join('') + 1).join('M') + roman;
}

export function bytesToDisplayString(bytes: number) {
  return bytes >= 1024 * 1024
    ? bytesToMb(bytes) + 'МіБ'
    : bytesToKb(bytes) + 'КіБ';
}

export function bytesToMb(bytes: number): number {
  return +(bytes / 1024 / 1024).toFixed(2);
}

export function bytesToKb(bytes: number): number {
  return +(bytes / 1024).toFixed(2);
}

export function mbToBytes(mb: number): number {
  return Math.floor(mb * 1024 * 1024);
}

export function kbToBytes(kb: number): number {
  return Math.floor(kb * 1024);
}

export function trimHTML(html: string = '') {
  let htmlTrimmedContent = html.trim();
  const trimmedTokens = ['<br>', '<br/>', '<p>&nbsp;</p>'];
  while (trimmedTokens.some((token) => htmlTrimmedContent.endsWith(token))) {
    trimmedTokens.forEach((token) => {
      if (htmlTrimmedContent.endsWith(token)) {
        htmlTrimmedContent = htmlTrimmedContent.substring(
          0,
          htmlTrimmedContent.lastIndexOf(token),
        );
      }
    });
  }
  while (trimmedTokens.some((token) => htmlTrimmedContent.startsWith(token))) {
    trimmedTokens.forEach((token) => {
      if (htmlTrimmedContent.startsWith(token)) {
        htmlTrimmedContent = htmlTrimmedContent.substring(
          htmlTrimmedContent.indexOf(token) + token.length,
        );
      }
    });
  }
  return htmlTrimmedContent.trim();
}

export function updateValueAndValidityWithDescendants(
  control: AbstractControl,
): void {
  if (
    (control instanceof FormGroup || control instanceof FormArray) &&
    control.controls
  ) {
    control.updateValueAndValidity();
    Object.values(control.controls).forEach((control) =>
      updateValueAndValidityWithDescendants(control),
    );
  } else {
    control.updateValueAndValidity();
  }
}

export const noop = (..._args: any[]) => {};

export function copyTextToBuffer(
  text: string,
  messageService?: MessageService,
  successMessage?: string,
): void {
  from(navigator.clipboard.writeText(text)).subscribe(() => {
    const defaultSuccessMessage = 'Скопійовано в буфер обміну';
    if (messageService) {
      messageService.success(
        successMessage ?? defaultSuccessMessage,
        undefined,
        1000,
      );
    } else {
      alert(successMessage ?? defaultSuccessMessage);
    }
  });
}

export function nullishToNumber(value: any): number | undefined {
  return isNaN(+value) ? undefined : +value;
}

export function getRandomInteger(min: number, max: number) {
  // Generate a random number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getRandomElement<T>(array: T[] | readonly T[]) {
  return array.at(getRandomInteger(0, array.length ? array.length - 1 : 0));
}

export function getDomainFromUrl(url: string) {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  const hostname = a.hostname;
  a.remove();
  return hostname;
}

export function getControlRelativeRoot(
  referenceControl: AbstractControl,
  path: string,
): { relativeRoot: FormGroup | FormArray | null; relativePath: string } {
  const backNavigationToken = '../';
  const navigateBackTimes = (
    path.match(new RegExp(backNavigationToken, 'g')) || []
  ).length;
  path = path.replace(new RegExp(backNavigationToken, 'g'), '');

  let relativeRoot: AbstractControl = referenceControl;
  if (navigateBackTimes && referenceControl.parent) {
    for (let i = 0; i < navigateBackTimes; i++) {
      relativeRoot = relativeRoot.parent!;
    }
  } else {
    relativeRoot = referenceControl.root as FormArray | FormGroup;
  }

  return {
    relativeRoot: relativeRoot as FormGroup | FormArray | null,
    relativePath: path,
  };
}

export function getAllControlsByCustomPath(
  referenceControl: AbstractControl,
  path: string,
): AbstractControl[] {
  const iteratorToken = '$forEach';
  const result: AbstractControl[] = [];
  const hasIterator = path.includes(iteratorToken);
  const { relativeRoot, relativePath } = getControlRelativeRoot(
    referenceControl,
    path,
  );

  if (!relativeRoot) {
    return result;
  }

  if (!hasIterator) {
    result.push(relativeRoot.get(relativePath)!);
    return result;
  }
  const pathTokens = relativePath.split('.');
  const paths: string[] = [];
  let iterablePath = pathTokens
    .slice(
      0,
      pathTokens.findIndex((token) => token === iteratorToken),
    )
    .join('.');

  (referenceControl.root?.get(iterablePath) as FormArray)?.controls.forEach(
    (token, index) => {
      const splicedTokens = [...pathTokens];
      splicedTokens.splice(
        splicedTokens.findIndex((token) => token === iteratorToken),
        1,
        `${index}`,
      );
      paths.push(splicedTokens.join('.'));
    },
  );
  paths.forEach((newPath) => {
    getAllControlsByCustomPath(referenceControl, newPath)?.forEach(
      (resultControl) => result.push(resultControl),
    );
  });
  return result;
}

export function downloadFileByUrl(url: string, name: string) {
  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.setAttribute('href', url);
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function cloneDeepWithUisFiles<T>(value: T): T {
  return cloneDeepWith(value, (value) =>
    value instanceof UisFile ? value.asContract : value,
  );
}

export function parseMillisecondsIntoReadableTime(milliseconds: number) {
  const hours = milliseconds / (1000 * 60 * 60);
  const absoluteHours = Math.floor(hours);
  const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

  const minutes = (hours - absoluteHours) * 60;
  const absoluteMinutes = Math.floor(minutes);
  const m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

  const seconds = (minutes - absoluteMinutes) * 60;
  const absoluteSeconds = Math.floor(seconds);
  const s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

  return `${h}:${m}:${s}`;
}
