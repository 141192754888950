import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  HostListener,
  inject,
  Input,
  Signal,
  signal,
} from '@angular/core';

import {
  ActivatedRouteSnapshot,
  Data,
  ResolveData,
  Router,
} from '@angular/router';
import { RouterInputs } from '@uis-types/objects';
import {
  Employee,
  Student,
  StudentCreateRequest,
  getEmployeeForm,
  getStudentForm,
  isEmployee,
  isStudent,
  User,
  UserRole,
  UserType,
} from '@uis-models/contract/user';
import {
  CanDeactivateComponent,
  canDeactivateImplementation,
} from '@uis-core/interfaces/can-deactivate-component';
import { UserService } from '@uis-services/user/user.service';
import { BREADCRUMB_ROUTE_DATA_KEY } from '@uis-services/breadcrumb/breadcrumb.service';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ButtonComponent } from '@uis-common/button/button.component';
import { CenteredPageComponent } from '@uis-common/page/centered-page/centered-page.component';
import { EmployeeFormComponent } from '@uis-private/components/user-forms/employee-form/employee-form.component';
import { StudentFormComponent } from '@uis-private/components/user-forms/student-form/student-form.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from '@uis-services/message/message.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { UisValidators } from '@uis-core/validators/validators';
import { ProfileViewRouterInputs } from '@uis-private/common/profile-view/infrastructure';
import { IndividualStudyPlanService } from '@uis-services/individual-study-plan/individual-study-plan.service';
import { UsersRoutesConfig } from '@uis-private/users/users.routes.config';
import { AcademicYear } from '@uis-models/contract/academic-year';
import { JoinApplicationEditRouterInputs } from '@uis-private/join-application/edit/join-application-edit.component';

export enum UsersFormRouterInputs {
  User = 'user',
  UserId = 'userId',
  CreatedUserType = 'createdUserType',
}

export const UsersFormEditResolveData: ResolveData = {
  [UsersFormRouterInputs.User]: (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    return inject(UserService)
      .getUserById(route.paramMap.get(UsersFormRouterInputs.UserId))
      .pipe(
        tap({
          error: () =>
            router.navigate([UsersRoutesConfig.absolutePath.list()]).then(),
          next: (user) => {
            if (!user) {
              router.navigate([UsersRoutesConfig.absolutePath.list()]).then();
            }
          },
        }),
      );
  },
  [ProfileViewRouterInputs.IndividualStudyPlan]: (
    route: ActivatedRouteSnapshot,
  ) =>
    inject(IndividualStudyPlanService).getIndividualStudyPlanByUserId(
      route.paramMap.get(UsersFormRouterInputs.UserId),
    ),
};

export const UsersFormEditRouteData: Data = {
  [BREADCRUMB_ROUTE_DATA_KEY]: (data: Data) =>
    data[UsersFormRouterInputs.User]?.fullName,
};

@Component({
  selector: 'uis-users-form',
  standalone: true,
  imports: [
    ButtonComponent,
    CenteredPageComponent,
    EmployeeFormComponent,
    StudentFormComponent,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormFieldComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
  ],
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersFormComponent
  implements RouterInputs<UsersFormRouterInputs>, CanDeactivateComponent
{
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly userService = inject(UserService);
  private readonly message = inject(MessageService);
  private readonly router = inject(Router);

  protected readonly employeeForm = getEmployeeForm(true);
  protected readonly studentForm = getStudentForm(true);

  protected readonly classLevelOptions = Array.from(
    { length: 11 },
    (_, index) => index + 1,
  );

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate = canDeactivateImplementation(() => !this.profileForm().dirty);

  @Input({ alias: UsersFormRouterInputs.UserId }) userId: string | undefined;

  @Input({ alias: UsersFormRouterInputs.CreatedUserType }) set createdUserType(
    userType: UserType | undefined,
  ) {
    this.cratedUserTypeValue.set(userType);
  }

  @Input({ alias: UsersFormRouterInputs.User }) set user(user: User) {
    this.userValue.set(user);
  }

  @Input({
    alias: JoinApplicationEditRouterInputs.AcademicYearOptions,
    required: true,
  })
  set academicYearOptions(academicYearOptions: AcademicYear[]) {
    this.academicYearOptionsValue.set(academicYearOptions ?? []);
  }

  public readonly academicYearOptionsValue = signal<AcademicYear[]>([]);

  protected readonly academicYearOptionsWith = (
    option: AcademicYear | null,
    selection: AcademicYear | null,
  ) => {
    return !!(option && selection && option.id === selection.id);
  };

  //Inputs
  protected readonly cratedUserTypeValue = signal<UserType | undefined>(
    undefined,
  );
  protected readonly userValue = signal<User | null>(null);

  protected readonly loadedStudent: Signal<Student | null> = computed(() => {
    const user = this.userValue();
    return isStudent(user) ? user : null;
  });
  protected readonly loadedEmployee: Signal<Employee | null> = computed(() => {
    const user = this.userValue();
    return isEmployee(user) ? user : null;
  });
  protected readonly profileForm = computed(() => {
    //TODO: Temp solution to block input on edit until email change user story is implemented
    if (!this.isCreateMode()) {
      this.studentForm.controls.email.disable();
      this.employeeForm.controls.email.disable();
    } else {
      this.studentForm.controls.email.enable();
      this.employeeForm.controls.email.enable();
    }

    if (this.loadedStudent()) {
      return this.studentForm;
    }
    if (this.isCreateStudentMode()) {
      const skipJoinApplicationFlowKey: keyof StudentCreateRequest =
        'skipJoinApplicationFlow';
      const classLevelKey: keyof StudentCreateRequest = 'classLevel';
      const academicYearKey: keyof StudentCreateRequest = 'academicYear';

      if (!this.studentForm.contains(skipJoinApplicationFlowKey)) {
        this.studentForm.addControl(
          skipJoinApplicationFlowKey,
          new FormControl(false),
        );
      }
      if (!this.studentForm.contains(classLevelKey)) {
        this.studentForm.addControl(
          classLevelKey,
          new FormControl(null, [
            UisValidators.requiredIfControlCondition(
              skipJoinApplicationFlowKey,
              (rootControl) =>
                rootControl.get(skipJoinApplicationFlowKey)?.value === true,
              this.destroyRef,
            ),
          ]),
        );
      }
      if (!this.studentForm.contains(academicYearKey)) {
        this.studentForm.addControl(
          academicYearKey,
          new FormControl(null, [
            UisValidators.requiredIfControlCondition(
              skipJoinApplicationFlowKey,
              (rootControl) =>
                rootControl.get(skipJoinApplicationFlowKey)?.value === true,
              this.destroyRef,
            ),
          ]),
        );
      }

      return this.studentForm;
    }
    if (this.isCreateEmployeeMode() || this.loadedEmployee()) {
      return this.employeeForm;
    }
    return this.employeeForm;
  });
  protected readonly isCreateMode = computed(() => !this.userValue());
  protected readonly isCreateEmployeeMode = computed(
    () =>
      this.isCreateMode() && this.cratedUserTypeValue() === UserType.Employee,
  );
  protected readonly isCreateStudentMode = computed(
    () =>
      this.isCreateMode() && this.cratedUserTypeValue() === UserType.Student,
  );
  protected readonly submitButtonText = computed(() => {
    const isCreateMode = this.isCreateMode();
    const isCreateStudentMode = this.isCreateStudentMode();
    const isCreateEmployeeMode = this.isCreateEmployeeMode();

    if (isCreateMode && isCreateStudentMode) {
      return 'Створити учня';
    }
    if (isCreateMode && isCreateEmployeeMode) {
      return 'Створити працівника';
    }
    return 'Зберегти';
  });
  protected readonly pageTitleText = computed(() => {
    const isCreateMode = this.isCreateMode();
    const isCreateStudentMode = this.isCreateStudentMode();
    const isCreateEmployeeMode = this.isCreateEmployeeMode();

    if (isCreateMode && isCreateStudentMode) {
      return 'Створення профілю екстерна';
    }
    if (isCreateMode && isCreateEmployeeMode) {
      return 'Створення профілю працівника';
    }

    return `Редагування профілю ${
      this.loadedEmployee() ? 'працівника' : 'екстерна'
    }`;
  });

  protected readonly profileFormPatchEffect = effect(
    () => {
      const profileForm = this.profileForm();
      const user = this.userValue();
      const loadedStudent = this.loadedStudent();

      if (!profileForm || !user) {
        return;
      }
      profileForm.patchValue(user as any);
      if (loadedStudent && !loadedStudent.parents.length) {
        this.studentForm.controls.parents.addControl();
      }
    },
    { allowSignalWrites: true },
  );

  onSubmit() {
    if (!this.profileForm().validate()) {
      this.message.invalidForm();
      return;
    }

    const formValue = this.profileForm().value;
    const formValueAsUser = formValue as unknown as User;

    if (
      this.profileForm() === this.employeeForm &&
      this.employeeForm.controls.subjects.disabled
    ) {
      (formValue as unknown as Employee).subjects = [];
    }

    const isStudent = this.loadedStudent();
    const isCreateMode = this.isCreateMode();
    const isCreateEmployeeMode = this.isCreateEmployeeMode();
    const { roles } = this.employeeForm.value;

    let call: Observable<any> = of(null);
    if (isCreateMode) {
      if (isCreateEmployeeMode) {
        call = this.userService.createEmployee(formValueAsUser as Employee);
      } else {
        const formValueAsStudentCreateRequest =
          formValueAsUser as StudentCreateRequest;
        if (!formValueAsStudentCreateRequest.skipJoinApplicationFlow) {
          formValueAsStudentCreateRequest.classLevel = undefined;
          formValueAsStudentCreateRequest.academicYear = undefined;
        }
        call = this.userService.createStudent(formValueAsStudentCreateRequest);
      }
    } else {
      if (this.employeeForm.controls.roles?.dirty) {
        call = this.userService.updateEmployeeRoles(
          this.userValue()!.id,
          roles as UserRole[],
        );
      }
      if (isStudent) {
        call = call.pipe(
          switchMap(() =>
            this.userService.updateStudent(
              (this.userValue() as Student)!,
              formValueAsUser as Student,
            ),
          ),
        );
      } else {
        call = call.pipe(
          switchMap(() =>
            this.userService.updateEmployee(
              (this.userValue() as Employee)!,
              formValueAsUser as Employee,
            ),
          ),
        );
      }
    }

    call
      .pipe(
        tap(() => this.profileForm().markAsPristine()),
        switchMap((res) =>
          this.userValue()?.id === this.userService.me()?.id
            ? this.userService.reloadCurrentUser()
            : of(res),
        ),
        tap(() =>
          this.message.success(
            isCreateMode ? 'Користувача створено' : 'Дані профілю оновлено',
          ),
        ),
        switchMap(() =>
          this.router.navigate([UsersRoutesConfig.absolutePath.list()]),
        ),
      )
      .subscribe();
  }
}
