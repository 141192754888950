export interface SearchResult<T = any> {
  data: T[];
  total: number;
}

export class SearchResult<T = any> implements SearchResult<T> {
  data: T[] = [];
  total: number = 0;

  constructor(data?: T[], total?: number) {
    this.data = data ?? this.data;
    this.total = total ?? this.data.length;
  }
}
