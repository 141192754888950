import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { LogoComponent } from 'src/app/content/common/logo/logo.component';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService } from '@uis-services/breadcrumb/breadcrumb.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GlobalLoadingService } from '@uis-services/global-loading/global-loading.service';
import { AsyncPipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OnlineStatusComponent } from 'src/app/content/common/online-status/online-status.component';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
} from 'angular-animations';
import { environment } from '../environments/environment';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    LogoComponent,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    OnlineStatusComponent,
    AsyncPipe,
  ],
  selector: 'uis-front-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInOnEnterAnimation({
      anchor: 'rootEnterAnimation',
      duration: 750,
    }),
    fadeInOnEnterAnimation({ anchor: 'progressBarEnter', duration: 100 }),
    fadeOutOnLeaveAnimation({ anchor: 'progressBarLeave', duration: 100 }),
  ],
  host: {
    '[@rootEnterAnimation]': 'true',
    '[attr.data-env]': 'currEnv()',
  },
})
export class AppComponent {
  protected readonly isGlobalLoading = inject(GlobalLoadingService).isLoading;
  private readonly breadcrumbsService = inject(BreadcrumbService);
  private readonly title = inject(Title);

  // Update title based on the last breadcrumb label which is build for every route
  private readonly titleUpdateEffect = effect(() => {
    const breadcrumbLabels = this.breadcrumbsService
      .breadcrumbs()
      .map((bc) => bc.label)
      .reverse();
    this.title.setTitle(
      breadcrumbLabels.length
        ? `${breadcrumbLabels.join(' | ')} | МУШ`
        : 'Міжнародна Українська Школа | МУШ',
    );
  });

  protected readonly currEnv = computed(() => {
    const isDev = environment.development;
    const isStage = environment.staging;
    const isProd = environment.production;

    if (isDev) {
      return 'dev';
    }

    if (isStage) {
      return 'stage';
    }

    if (isProd) {
      return 'prod';
    }

    return 'dev';
  });
}
