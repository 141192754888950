import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  input,
  Output,
  signal,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { UisInputMask } from '@uis-core/validators/uis-input-mask';
import { noop } from '@uis-core/helpers/utils';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { of, tap } from 'rxjs';
import { DisableControlDirective } from '@uis-directives/disable-control/disable-control.directive';
import { MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'uis-datepicker',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskDirective,
    ReactiveFormsModule,
    DisableControlDirective,
    MatOption,
    MatSelect,
    MatIcon,
  ],
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    DatePipe,
  ],
})
export class DatepickerComponent implements ControlValueAccessor {
  protected readonly UisInputMask = UisInputMask;
  startView = input<MatDatepicker<Date>['startView']>('month');
  startAt = input<MatDatepicker<Date>['startAt']>(null);
  min = input<MatDatepickerInput<Date>['min']>(null);
  max = input<MatDatepickerInput<Date>['max']>(null);
  mode = input<'date' | 'time' | 'datetime'>('date');
  controls = computed(() =>
    this.mode() === 'datetime' ? (['date', 'time'] as const) : [this.mode()],
  );
  datePipe = inject(DatePipe);

  @Input({
    alias: 'value',
    transform: (value: Date | string | undefined | null) => {
      if (!value) {
        return null;
      }

      if (value instanceof Date) {
        return value;
      }

      const tryDate = new Date(value);
      const isInvalid = tryDate.toString().toLowerCase() === 'invalid date';

      if (isInvalid) {
        return null;
      }

      return tryDate;
    },
  })
  set valueInput(value: Date | null) {
    this.writeValue(value);
  }

  @Output()
  dateChange = new EventEmitter<Date | null>();

  private isValueChangedFromWrite = false;
  protected readonly timeOptionsStepMinutes = 15;
  protected readonly msInDay = 86_400_000;
  protected readonly timeOptionsSteps =
    this.msInDay / (this.timeOptionsStepMinutes * 60 * 1000);
  protected readonly timeOptions = Array.from(
    { length: this.timeOptionsSteps },
    (_, index) => {
      const value =
        index * 60 * 1000 * this.timeOptionsStepMinutes + 4 * 60 * 60 * 1000;
      const tmpDate = new Date(0);
      tmpDate.setTime(value);
      let label = this.datePipe.transform(tmpDate, 'HH:mm');
      return { value, label };
    },
  );

  readonly isDisabled = signal<boolean>(false);

  private onTouched = noop;
  private onChange = noop;

  private initialized = false;
  private readonly onValueChangeEffect = effect(
    () => {
      const currentValue = this.totalValue();
      if (!this.initialized) {
        this.initialized = true;
        return;
      }
      if (this.isValueChangedFromWrite) {
        this.isValueChangedFromWrite = false;
        return;
      }
      this.onTouched();
      this.dateChange.emit(currentValue);
      this.onChange(currentValue);
    },
    // Not sure why it is needed here since there is no explicit signal write
    // But Angular requires it
    { allowSignalWrites: true },
  );

  // Needed since mat inputs strictly relies on actual
  // form control to be provided to be able to trigger error state
  protected readonly mockFormControl = new FormControl();

  // Source of error state
  protected readonly parentUisFormField = inject(FormFieldComponent, {
    optional: true,
  });

  // Checks the error state on each ngDoCheck run
  private readonly elementRef = inject(ElementRef<HTMLElement>);
  protected readonly errorStateMatcher: ErrorStateMatcher = {
    isErrorState: () =>
      !!this.parentUisFormField?.isErrorVisible() ||
      (this.elementRef.nativeElement.classList.contains('ng-invalid') &&
        this.elementRef.nativeElement.classList.contains('ng-touched')),
  };

  // Trigger ngDoCheck inside material input
  protected readonly cd = inject(ChangeDetectorRef);
  protected readonly checkErrorStateEffect = toSignal(
    (this.parentUisFormField?.isErrorVisible$ ?? of<any>(null)).pipe(
      tap(() => this.cd.markForCheck()),
    ),
  );

  writeValue(value: Date | null): void {
    if (value !== this.totalValue()) {
      //Needed for mask directive to initialize its empty value first
      setTimeout(() => {
        this.isValueChangedFromWrite = true;
        this.dateValue.set(value);
        this.timeValue.set(value);
      });
    }
  }

  protected readonly dateValue = signal<Date | null>(null);
  protected readonly timeValue = signal<Date | null>(null);
  protected readonly totalValue = computed<Date | null>(() => {
    const dateValue = this.dateValue();
    const timeValue = this.timeValue();

    if (!dateValue && !timeValue) {
      return null;
    }

    let newTotalValue = new Date();

    if (dateValue) {
      newTotalValue = new Date(dateValue);
    }

    if (timeValue) {
      newTotalValue.setHours(
        timeValue.getHours(),
        timeValue.getMinutes(),
        0,
        0,
      );
    }

    return newTotalValue;
  });

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled.set(isDisabled);
  }

  setDateStringValueAsDate(value: string) {
    if (!value) {
      this.dateValue.set(null);
      return;
    }

    // new Date() accepts date in format 'mm/dd/yyyy', and we get 'dd/mm/yyyy' from input
    // that's why we need to switch places for month and day
    const splitDate = value.split('/');
    let date = new Date(`${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`);
    const isInvalid = date.toString().toLowerCase() === 'invalid date';
    this.dateValue.set(isInvalid ? null : date);
  }

  onMaskInputBlur() {
    this.onTouched();
  }

  protected readonly timePickerDisplayedValue = computed(() => {
    const value = this.totalValue();
    if (!value) {
      return null;
    }

    return this.datePipe.transform(value, 'HH:mm');
  });

  onDatePickerChange(date: Date | null) {
    this.dateValue.set(date);
  }

  onTimePickerChange(change: any) {
    const existingValue = this.totalValue();
    const newDate = existingValue ? new Date(existingValue) : new Date();

    if (!change.target.value) {
      this.timeValue.set(null);
    }

    const timePickerRawSplitValue = change.target.value.split(':');
    const hours = timePickerRawSplitValue[0];
    const minutes = timePickerRawSplitValue[1];
    newDate.setHours(hours, minutes);
    this.timeValue.set(newDate);
  }

  onClear() {
    this.dateValue.set(null);
    this.timeValue.set(null);
  }
}
