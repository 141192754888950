<div class="content-wrapper">
  @if (errorText && control.control?.touched) {
    <div class="error-border-layer"></div>
  }
  <div class="content">
    <ng-content />
  </div>
</div>
<uis-form-control-subscript
  [error]="errorText"
  [errorVisible]="control.control?.touched"
  [hint]="hint"
/>
