@if (controls().includes('date')) {
  <mat-form-field>
    <input
      matInput
      type="text"
      mask="{{ UisInputMask.DatePickerInput }}"
      placeholder="дд/мм/рррр"
      [value]="totalValue() ? (totalValue()! | date: 'dd/MM/yyyy')! : ''"
      (blur)="onMaskInputBlur()"
      (change)="setDateStringValueAsDate($any($event.target).value)"
      [uisDisableControl]="isDisabled()"
      [formControl]="mockFormControl"
      [errorStateMatcher]="errorStateMatcher"
    />
    <input
      type="hidden"
      [value]="totalValue()"
      (dateChange)="onDatePickerChange($event.value)"
      [min]="min()"
      [max]="max()"
      [matDatepicker]="picker"
      [disabled]="isDisabled()"
    />
    @if (totalValue()) {
      <mat-icon
        matIconPrefix
        class="material-icons-outlined"
        (click)="onClear()"
        >delete
      </mat-icon>
    }
    <mat-datepicker-toggle
      matIconSuffix
      [for]="picker"
      (blur)="onMaskInputBlur()"
    ></mat-datepicker-toggle>
    <mat-datepicker
      #picker
      [startView]="startView()"
      [startAt]="this.totalValue() ?? this.startAt()"
    ></mat-datepicker>
  </mat-form-field>
}
@if (controls().includes('time')) {
  <mat-form-field>
    <input
      matInput
      type="time"
      #timePicker
      [value]="timePickerDisplayedValue()"
      (change)="onTimePickerChange($event)"
      [formControl]="mockFormControl"
      [errorStateMatcher]="errorStateMatcher"
    />
    <mat-icon matSuffix (click)="timePicker.showPicker()">schedule</mat-icon>
  </mat-form-field>
}
