import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'uis-quote-block',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './quote-block.component.html',
  styleUrl: './quote-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteBlockComponent {
  text = input.required();
}
