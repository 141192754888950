import { CanDeactivateFn } from '@angular/router';
import { CanDeactivateComponent } from '@uis-core/interfaces/can-deactivate-component';
import { inject } from '@angular/core';
import { DialogService } from '@uis-services/dialog/dialog.service';
import { defer, map } from 'rxjs';

export const canDeactivateComponentGuard: CanDeactivateFn<
  CanDeactivateComponent
> = (component) => {
  const dialog = inject(DialogService);
  const canDeactivateResult = component.canDeactivate();

  const canDeactivate =
    canDeactivateResult && typeof canDeactivateResult === 'string'
      ? false
      : !!canDeactivateResult;
  const canDeactivateMessage =
    typeof canDeactivateResult === 'string' ? canDeactivateResult : undefined;

  return (
    canDeactivate ||
    defer(() =>
      dialog.unsavedChanges(canDeactivateMessage).pipe(map((res) => !res)),
    )
  );
};
