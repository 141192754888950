import { Directive, ElementRef, HostListener, inject } from '@angular/core';

@Directive({
  selector: 'input[uisDigitsOnly], textarea[uisDigitsOnly]',
  standalone: true,
})
export class DigitsOnlyDirective {
  private readonly el = inject(ElementRef);

  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    const initialValue: string = this.el.nativeElement.value ?? '';
    this.el.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
