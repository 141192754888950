@if (foreignAddressForm) {
  <div class="uis-form-common__input-grid" [formGroup]="foreignAddressForm">
    @if (forScopeCreation) {
      <uis-form-field label="Країна">
        <mat-form-field>
          <input
            type="text"
            placeholder="Виберіть зі списку"
            matInput
            (input)="countryOptionsNameQuery$.next($any($event.target).value)"
            formControlName="country"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            autoActiveFirstOption
            requireSelection
            #auto="matAutocomplete"
          >
            @for (option of countryOptions(); track option) {
              <mat-option [value]="option.name">
                {{ option.name }}
              </mat-option>
            }
            @if (!countryOptions().length) {
              <mat-option disabled [value]="null">
                Не знайдено країн
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </uis-form-field>
    }
    <uis-form-field label="Населений пункт">
      <mat-form-field>
        <input
          matInput
          uisTrim
          type="text"
          formControlName="city"
          placeholder="Введіть назву населеного пункту"
        />
      </mat-form-field>
    </uis-form-field>
  </div>
}
