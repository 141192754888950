@if (ukrainianAddressForm()) {
  <div
    class="uis-form-common__input-grid"
    [formGroup]="ukrainianAddressForm()!"
  >
    @if (forScopeCreation) {
      <uis-form-field label="Область">
        <mat-form-field>
          <input
            type="text"
            placeholder="Виберіть зі списку"
            matInput
            (input)="
              ukrainianRegionOptionsNameQuery$.next($any($event.target).value)
            "
            formControlName="region"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            requireSelection
            autoActiveFirstOption
            (optionSelected)="onUkrainianRegionOptionSelected()"
            #auto="matAutocomplete"
          >
            @for (option of ukrainianRegionOptions(); track option) {
              <mat-option [value]="option.name">
                {{ option.name }}
              </mat-option>
            }
            @if (!ukrainianRegionOptions().length) {
              <mat-option disabled [value]="null">
                Не знайдено областей
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </uis-form-field>
    }
    @if (forScopeCreation) {
      <uis-form-field label="Населений пункт">
        <mat-form-field>
          <input
            type="text"
            placeholder="{{
              ukrainianAddressForm()?.controls?.region?.value
                ? 'Виберіть зі списку'
                : 'Спочатку виберіть область'
            }}"
            matInput
            #input
            [uisDisableControl]="
              !ukrainianAddressForm()?.controls?.region?.value && !input.value
            "
            (input)="
              ukrainianCityOptionsNameQuery$.next($any($event.target).value)
            "
            formControlName="city"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            autoActiveFirstOption
            requireSelection
            #auto="matAutocomplete"
          >
            @for (option of ukrainianCityOptions(); track option) {
              <mat-option [value]="option.name">
                {{ option.name }}
              </mat-option>
            }
            @if (!ukrainianCityOptions().length) {
              <mat-option disabled [value]="null">
                Не знайдено міст
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </uis-form-field>
    }
    <uis-form-field label="Вулиця">
      <mat-form-field>
        <input
          matInput
          uisTrim
          type="text"
          formControlName="street"
          placeholder="Введіть назву вулиці"
        />
      </mat-form-field>
    </uis-form-field>
    <uis-form-field label="Будинок">
      <mat-form-field>
        <input
          matInput
          uisTrim
          type="text"
          formControlName="building"
          placeholder="Введіть номер будинку"
        />
      </mat-form-field>
    </uis-form-field>
    <uis-form-field label="Квартира">
      <mat-form-field>
        <input
          matInput
          uisTrim
          type="text"
          formControlName="apartment"
          placeholder="Введіть номер квартири"
        />
      </mat-form-field>
    </uis-form-field>
  </div>
}
