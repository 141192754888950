<div class="picture-input">
  <div class="picture-input__background-layer">
    <mat-icon class="material-symbols-outlined" inline>add_a_photo</mat-icon>
  </div>
  @if (internalValueDynamicState()?.url) {
    <img
      class="picture-input__image-preview"
      [src]="internalValueDynamicState()?.url"
      alt="image preview"
    />
  }
  <div
    class="picture-input__overlay"
    [class.picture-input__overlay_visible]="isDragging()"
  >
    @if (!internalValueDynamicState() || isDragging()) {
      <div
        matTooltip="Завантажити фото"
        class="picture-input__overlay__item picture-input__overlay__item_add"
        (click)="htmlInput.click()"
      >
        <mat-icon>add</mat-icon>
      </div>
    }
    @if (internalValueDynamicState() && !isDragging()) {
      <div
        matTooltip="Видалити фото"
        class="picture-input__overlay__item picture-input__overlay__item_delete"
        (click)="onDelete()"
      >
        <mat-icon>delete</mat-icon>
      </div>
    }
  </div>
</div>
<div class="picture-input-border-layer"></div>
<div class="picture-input-info-container pointer-transparent">
  @if (limitationsInfoVisible() && fileLimitationsString()) {
    <mat-icon
      inline
      [matTooltip]="fileLimitationsString()"
      matTooltipPosition="above"
      class="picture-input-info-container__limitations material-icons-outlined"
      >info
    </mat-icon>
  }
  <div class="picture-input-info-container__status-indicator-container">
    @if (internalValueDynamicState()?.isUploading) {
      <mat-progress-spinner
        matTooltip="Файл завантажується"
        diameter="15"
        mode="indeterminate"
      />
    }
    @if (internalValueDynamicState()?.uploadingError) {
      <mat-icon
        inline
        matTooltip="Помилка завантаження файлу"
        class="picture-input-info-container__status-indicator_error material-symbols-outlined"
        >error
      </mat-icon>
    }
  </div>
</div>
<input
  hidden
  type="file"
  [disabled]="disabled()"
  [accept]="htmlInputAcceptString()"
  (change)="onHtmlInputChange($event)"
  #htmlInput
/>
