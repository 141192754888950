import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UserSex } from '@uis-enums/user';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { MatInputModule } from '@angular/material/input';
import { TrimValueDirective } from '@uis-directives/trim-value/trim-value.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { DatepickerComponent } from '@uis-common/inputs/datepicker/datepicker.component';
import { DigitsOnlyDirective } from '@uis-directives/digits-only/digits-only.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { getUserGeneralInfoFormControls } from '@uis-models/contract/user';
import { UisFormGroup } from '@uis-core/forms/uis-form-group';
import { ProfilePictureInputComponent } from '@uis-common/inputs/file-inputs/profile-picture-input/profile-picture-input.component';

@Component({
  selector: 'uis-user-general-info-form',
  standalone: true,
  imports: [
    FormFieldComponent,
    MatInputModule,
    TrimValueDirective,
    ReactiveFormsModule,
    DatepickerComponent,
    DigitsOnlyDirective,
    MatIconModule,
    MatRadioModule,
    ProfilePictureInputComponent,
  ],
  templateUrl: './user-general-info-form.component.html',
  styleUrls: [
    './user-general-info-form.component.scss',
    '../user-forms-common.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGeneralInfoFormComponent {
  protected readonly birthdayCalendarInitialDate = new Date('01/01/2000');
  protected readonly birthdayCalendarMaxDate = new Date();

  @Input({ required: true }) generalInfoForm!: UisFormGroup<
    ReturnType<typeof getUserGeneralInfoFormControls>
  >;
  protected readonly UserSex = UserSex;
}
